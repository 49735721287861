import { Redirect, Route } from "react-router-dom";
import ForgetPassword from "../components/Common/Login/forgetPassword";
import Login from "../components/Common/Login/login";
import ResetPassword from "../components/Common/Login/ResetPassword";

export const PublicRoute = () => {
    return (
      <>
        <Route exact path="/login" component={Login}></Route>
         <Route exact path="/forget-password" component={ForgetPassword}></Route>
         <Route exact path="/reset-password" component={ResetPassword}></Route>
         <Redirect to="/login"></Redirect>
      </>
    );
  };