import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LANGUAGE } from "../../helper/constants";
import { ReactComponent as HamburgerSVG } from "../Common/icons/hamburger.svg";
import LoginDropdown from "../Common/LoginDropdown";
import Sidebar from "../Common/Sidebar";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Languages } from "./../Common/languages";

const BaseLayout = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelGroupId");
  const { i18n } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();
  const langId = useSelector((state) => state?.constants?.languages);
  const localLang = useSelector((state)=>state?.localLang?.language_id) 
  useEffect(()=>{
    if(localLang){
      const langSymbol = langId.filter((lang)=>lang?.language_id === localLang)[0]
     
      i18n.changeLanguage(LANGUAGE[langSymbol?.language?.key])
    }
  },[localLang,langId])
  useEffect(() => {
    //   getting available lang for hotel
    if (hotelId != 0) {
      dispatch(actions.getLanguages(hotelId));
    }
    // getting common currencies
    dispatch(actions.getCurrencies());
  }, []);

  const onLanguageSelect = (content) => {
    // updating languages
    i18n.changeLanguage(content?.language?.key);
  };

  const getHeader = () => {
    return (
      <>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <div className="mobile-sidebar set-absolute">
              {!sidebar && (
                <div
                  onClick={() => {
                    setSidebar(!sidebar);
                  }}
                >
                  {" "}
                  <HamburgerSVG />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col md={5} sm={5} xs={5} className="language-setup">
            <div className="set-absolute pt-3">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <Languages onChange={onLanguageSelect} defaultValue={localLang} />{" "}
                    </td>
                    <td>
                      <span>
                        <LoginDropdown />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col
            className="noPadding pt-5"
            md={location.pathname === "/" ? 12 : 11}
          >
            {children}
          </Col>
        </Row>
        <br />
        <br />
      </>
    );
  };

  return (
    <Container fluid className="baseLayout-container">
      <Row>
        <Col md={1} className="sidebar-wraper">
          <Sidebar onChangeSideBar={setSidebar} sidebar={sidebar} />
        </Col>
        <Col
          md={11}
          className={`
            ${
              sidebar ? "mainpage__transform" : "mainpage"
            } background-setup mb-3`}
        >
          {getHeader()}
        </Col>
      </Row>
    </Container>
  );
};
export default BaseLayout;
