import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import { Utils } from "../../utils/utils";

const storageService = new StorageService();

export const Tabs = () => {
    const { t } = useTranslation();
    const hiddenIconAsPerRole = (path) => {
        const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
        return allowedPagesAccess;
    }
    const NAV_BAR = [
        { 'name': 'Basic information', 'route': PRIVATE_ROUTES.hotel_configuration },
        { 'name': 'Floor list', 'route': PRIVATE_ROUTES.floor_list },
        { 'name': 'Room categories', 'route': PRIVATE_ROUTES.room_category_list },
        { 'name': `${t("Modules.Room Pricing.Room category pricing")}`, 'route': PRIVATE_ROUTES.room_pricing_list },
        { 'name': 'Room list', 'route': PRIVATE_ROUTES.room_list },
        { 'name': 'Deals and Offers', 'route': PRIVATE_ROUTES.deals_and_offer }
    ]
    return (
        <React.Fragment>
            <Nav className="hotel-nav content-no-color" variant="pills" defaultActiveKey="/hotel-configuration">
                {NAV_BAR?.map((nav, index) => (
                    <div key={`nav-index-${index}`}>
                        {hiddenIconAsPerRole(nav.route) ? (<Nav.Item className="hotel-nav-item" >
                            <NavLink activeClassName="hotel-tab" className="m-3" to={nav.route} >{nav.name}</NavLink>
                        </Nav.Item>) : (<></>)}
                    </div>
                ))
                }
                <Nav.Item className="hotel-nav-item">
                    <NavLink activeClassName="hotel-tab" className="m-3" to="/hotel-translation" >Language settings and translations</NavLink>
                </Nav.Item>
                <Nav.Item className="hotel-nav-item">
                    <NavLink activeClassName="hotel-tab" className="m-3" to="/email-settings" >Email Settings</NavLink>
                </Nav.Item>
                <Nav.Item className="hotel-nav-item">
                    <NavLink activeClassName="hotel-tab" className="m-3" to="/configuration" >Go Live</NavLink>
                </Nav.Item>
            </Nav>
        </React.Fragment>
    )
}