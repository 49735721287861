import React from 'react'
import { STATUS_MESSAGE } from "./../../helper/constants" 

export const StatusButton = (props)=>{

    return(
        <React.Fragment>
            
            {(props.pending=== STATUS_MESSAGE.pending)&&
                <span className="dot-pending"></span>
            }
            {(props.pending===STATUS_MESSAGE.location_pending)&&<span className="dot-location-pending"></span>}
            {(props.pending===STATUS_MESSAGE.completed)&&<span className="dot-completed"></span>}
        </React.Fragment>
    )
}

export default StatusButton