export const FETCH_HOTEL_LANG_SETTINGS_LIST = "FETCH_HOTEL_LANG_SETTINGS_LIST";
export const FETCH_HOTEL_LANG_SETTINGS_LIST_SUCCESS =
  "FETCH_HOTEL_LANG_SETTINGS_LIST_SUCCESS";
export const FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED =
  "FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED";

export const EDIT_HOTEL_LANG_SETTINGS = "EDIT_HOTEL_LANG_SETTINGS";
export const EDIT_HOTEL_LANG_SETTINGS_SUCCESS =
  "EDIT_HOTEL_LANG_SETTINGS_SUCCESS";
export const EDIT_HOTEL_LANG_SETTINGS_FAILED =
  "EDIT_HOTEL_LANG_SETTINGS_FAILED";

export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILED = "GET_LANGUAGE_FAILED";

export const getHotelLanguageSettings = (hotelId) => {
  return {
    type: FETCH_HOTEL_LANG_SETTINGS_LIST,
    hotelId,
  };
};

export const storeHotelLanguageSettings = (payload) => {
  return {
    type: EDIT_HOTEL_LANG_SETTINGS,
    payload,
  };
};

export const getLanguage = () => {
  return {
    type: GET_LANGUAGE,
  };
};