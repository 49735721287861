import actions from "../actions/actions";

const initialState = {
    result:[],
    hotelListforHotelGroup:[],
    exportedFile:false,
    msg:false,
    isLoading:false,
    fileReadyForDownload:false
}

export const HotelBookingReducer = (state=initialState, action)=>{
    switch (action.type) {

        case actions.GET_HOTEL_BOOKING: 
        case actions.GET_EXPORT_FILE:{
            return {
                ...state,
                isLoading:true,
                fileReadyForDownload:false
            }
        }

        case actions.GET_HOTEL_BOOKING_SUCCESS:{
            
            return {
                ...state,
                isLoading:false,
                result:action?.result,
                msg: action?.msg,
                fileReadyForDownload:false
            }
        }
        case actions.GET_HOTEL_BOOKING_FAILED:{
            return {
                ...state,
                isLoading:false,
                result:[],
                msg:action?.msg,
                fileReadyForDownload:false
            }
        }

        case actions.GET_EXPORT_FILE_SUCCESS:{
            return{
                ...state,
                isLoading:false,
                exportedFile:action?.result,
                msg:action?.msg,
                fileReadyForDownload:true
            }
        }

        case actions.GET_EXPORT_FILE_SUCCESS:{
            return{
                ...state,
                isLoading:false,
                exportedFile:false,
                msg:action?.msg,
                fileReadyForDownload:false
            }
        }
        case actions.RESET_REPORT_TAB:{
            return{
                ...state,
                result:[],
                exportedFile:false,
                msg:false,
                isLoading:false,
                fileReadyForDownload:false
            }
        }

        case actions.GET_HOTEL_LIST_HOTELGROUP_SUCCESS:{
            return{
              ...state,
              hotelListforHotelGroup:action?.result,
              msg:action?.msg
            }
          }
      
          case actions.GET_HOTEL_LIST_HOTELGROUP_FAILED:{
            return{
              ...state,
              hotelListforHotelGroup:[],
              msg:action?.msg
      
            }
          }
    
        default:{
            return state
        }
            
    }
}