export const GET_TEST_BOOKING = "GET_TEST_BOOKING";
export const GET_TEST_BOOKING_SUCCESS = "GET_TEST_BOOKING_SUCCESS";
export const GET_TEST_BOOKING_FAILED = "GET_TEST_BOOKING_FAILED";



export const getBookingStatus = (payload) => {
    return {
      type: GET_TEST_BOOKING,
      payload,
    };
  };