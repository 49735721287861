import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { default as NumberFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { Utils } from "../../utils/utils";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import StatusButton from "../Common/StatusButton";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import PaginationComponent from "./../Common/PaginationComponent";
import SearchComponents from "./../Common/SearchComponents";
import Spinner from "./../Common/Spinner";

export const SpecialOffersList = (props) => {
  const { t, i18n } = useTranslation();
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const extraService = useSelector((state) => state.extraservices.serviceList);

  const dealSearchTerm = useSelector((state) => state?.common?.dealSearch);

  const [searchKey, setSearchKey] = useState(dealSearchTerm || null);
  const [searchTerm, setSearchTerm] = useState(dealSearchTerm || null);
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const [headerDescription, setHeaderDescription] = useState();
  const langId = useSelector((state) => state.constants.languages);
  const [allowAccess, setAllowAccess] = useState(true);

  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const [currencySymbol, setCurrencySymbol] = useState();
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  //const currencySymbol = hotelDetails?.currency?.symbol;
  //const hotelId = 1
  const columnDataMap = {
    "DEAL NAME": "name",
    "PRICE ": "price",
    "START DATE": "start_date",
    "END DATE": "end_date",
  };
  const sortColumns = [
    t("Modules.Deals and offers (main page).Deal name AZ"),
    t("Modules.Deals and offers (main page).Price", {
      symbol: currencySymbol && currencySymbol,
    }),
    t("Modules.Deals and offers (main page).Start Date"),
    t("Modules.Deals and offers (main page).End Date"),
  ];

  //get Page no,SortBy and SortColumn from reducer
  const dealsListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(dealsListCommonDetails.dealsListPageNo);
  const [sortOrder, setSortOrder] = useState(dealsListCommonDetails.dealSortBy);
  const [sortingColumnName, setSortingColumnName] = useState(
    dealsListCommonDetails.dealColumnName
  );

  const sortColumn = useSelector((state) => state?.common.sortForDeals);
  const [sort, setSort] = useState(sortColumn);

  const onSearchEvent = (searchTxt = "") => {
    // setSearchTerm(searchTxt);
    // getTableInfo(searchTxt, 1, "", "", "");
    dispatch(actions.setSearchDealList(searchTxt));
    setPageNo(1);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    const sortBy = columnDataMap[updatedInfo];
    const updatedSearchTxt = searchTxt.replace(",", "");
    setSearchTerm(updatedSearchTxt);
    getTableInfo(updatedSearchTxt || "", 1, sortBy, sortOrder, "");
  };
  useEffect(() => {
    if (sortColumn) {
      //set current page no
      if (sortColumn["PRICE"]) {
        const priceKey = "PRICE " + currencySymbol;
        setSort({ ...sort, [`${priceKey}`]: true });
      }
    }
  }, [sortColumn]);

  useEffect(() => {
    if (dealsListCommonDetails) {
      //set current page no
      setPageNo(dealsListCommonDetails.dealsListPageNo);
    }
  }, [dealsListCommonDetails]);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (extraService && hotelDetails) {
      mapTableInfo();
    }
  }, [extraService, defaultSelectedLanguage, hotelDetails]);

  useEffect(() => {
    if (hotelDetails) {
      setCurrencySymbol(hotelDetails?.currency?.symbol);
    }
  }, [hotelDetails]);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      getTableInfo(
        dealSearchTerm || "",
        dealsListCommonDetails.dealsListPageNo
          ? dealsListCommonDetails.dealsListPageNo
          : pageNo,
        dealsListCommonDetails.dealSortColumnName
          ? dealsListCommonDetails.dealSortColumnName
          : columnDataMap[sortingColumnName],
        dealsListCommonDetails.dealSortBy
          ? dealsListCommonDetails.dealSortBy
          : sortOrder,
        ""
      );
    }
  }, [defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    if (selectedLanguage) {
      const langFiltered = langId.filter(
        (option) => option.language.key === selectedLanguage
      );
      onLanguageSelect(langFiltered[0]?.language?.id);
    }
  });
  const onLanguageSelect = (selectedLanguage) => {
    if (selectedLanguage) {
      setDefaultLanguage(selectedLanguage);
    }
  };
  const mapTableInfo = () => {
    const mapper = extraService?.records?.map((serviceInfo, index) => {
      const tableHeader = {
        id: serviceInfo.id,
      };
      // tableHeader[`${t("Modules.Deals and offers (main page).Deal name AZ")}`] =
      //   Utils.langValueGenerator(
      //     serviceInfo?.h_offers_and_services_ts,
      //     "name",
      //     defaultSelectedLanguage
      //   );
      tableHeader[`${t("Modules.Deals and offers (main page).Deal name AZ")}`] =
        serviceInfo.name;

      tableHeader[
        `${t("Modules.Deals and offers (main page).Price", {
          symbol: hotelDetails?.currency?.symbol,
        })}`
      ] = Utils.NumberFormatText(
        hotelDetails?.currency?.symbol,
        serviceInfo.price
      );
      tableHeader[`${t("Modules.Deals and offers (main page).Start Date")}`] =
        Utils.getDDMMYY(serviceInfo.start_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).End Date")}`] =
        Utils.getDDMMYY(serviceInfo.end_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).Status")}`] = (
        <StatusButton
          pending={serviceInfo.is_active == 1 ? "completed" : "pending"}
        >
          {" "}
        </StatusButton>
      );
      return tableHeader;
    });
    const tableHeaderDesc = {
      [`${t("Modules.Deals and offers (main page).Status")}`]: t(
        "Modules.Deals and offers (main page).Status - Question mark"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };
  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    const updatedInfo = headerInfo.replace(currencySymbol, "");
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[updatedInfo],
      sortOrder,
      defaultSelectedLanguage
    );
  };
  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = ""
  ) => {
    const payload = {
      searchKey: encodeURIComponent(searchTerm),
      hotel_id: hotelId,
      limit: 10,
      language_id: defaultSelectedLanguage ? defaultSelectedLanguage : 1,
    };
    // if(!searchTerm){
    //   payload.sort = "name";
    //   payload.sortBy = "ASC";
    // }

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }
    dispatch(actions.getService({ ...payload }));
  };

  const onEditRow = (categoryId) => {
    history.push("/extra-service/edit/" + categoryId);
  };
  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  useEffect(() => {
    // dispatch(actions.getService(hotelId));
    //getTableInfo();
  }, []);

  useEffect(() => {
    if (hotelDetails) {
      extraService.records
        ? mapTableInfo(extraService.records)
        : mapTableInfo([]);
      setTotalCount(extraService?.totalCount);
    }
  }, [extraService, hotelDetails]);

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    getTableInfo(
      searchTerm || "",
      pageNo,
      dealsListCommonDetails.dealSortColumnName
        ? dealsListCommonDetails.dealSortColumnName
        : columnDataMap[sortingColumnName],
      dealsListCommonDetails.dealSortBy
        ? dealsListCommonDetails.dealSortBy
        : sortOrder,
      defaultSelectedLanguage
    );
  };
  // listening on language Changed event to update table header
  // TODO: update listing on language change
  // i18n.on("languageChanged", (selectedLang) => {
  //   mapTableInfo();
  // });
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col>
              <h4>{t("Modules.Deals and offers (main page).Deals")}</h4>
            </Col>
          </Row>
          <Row>
            <div
              className="alert alert-info informationBox sub-header mt-1 mb-1"
              role="alert"
            >
              <h6>
                {t("Modules.Deals and offers (main page).Deal Information box")}
              </h6>
            </div>
          </Row>
          <Row>
            <Col className="mt-2">
              <SearchComponents
                buttonLabel={t(
                  "Modules.Deals and offers (main page).+ Add new deal"
                )}
                addBtn={allowAccess}
                path={PRIVATE_ROUTES.deal_create}
                search={onSearchEvent}
                commonSearchTerm={dealSearchTerm}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <BootstrapTable
                sortColumns={sortColumns}
                currentlySorted={sort}
                sortingColumnName={
                  sortingColumnName === "PRICE"
                    ? sortingColumnName + " " + currencySymbol
                    : sortingColumnName
                }
                onSortClick={sortingClick}
                isLoading={isLoading}
                tableExtraActions={allowAccess ? tableExtraActions : []}
                component={"Deals and Offers"}
                headerDescription={headerDescription}
                tableMapper={tableMapper}
              />
            </Col>
          </Row>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <PaginationComponent
                    pageNo={pageNo}
                    totalCount={Math.ceil(totalCount / 10)}
                    onChange={(number) => {
                      updatePagination(number);
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default SpecialOffersList;
