import i18next from "i18next";
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import { RoomCategoriesForm } from "./RoomCategoriesForm";

export const AddRoomCategories = () => {
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const { t, i18n } = useTranslation();
  const langId = useSelector(state => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(langId.filter(option => option.language.key === i18next.language)[0]?.language?.id);
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-room-categories", title: hotelName },
    { path: "/hotel-room-categories", title: "Room categories" },
    { path: "/hotel-room-categories/create", title: "Add new room category" }
  ];


  // i18next.on("languageChanged", (selectedLanguage) => {
  //   const langFiltered = langId.filter(option => option.language.key === selectedLanguage)

  //   onLanguageSelect(langFiltered[0]?.language?.id)
  // })

  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(option => option.language.key === selectedLanguage)
    onLanguageSelect(langFiltered[0]?.language?.id)
  })


  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);

  };
  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
          <div className="header margin-left">
            {t("Modules.Room categories (+ add new).Add new room category")}
            
            </div>
          </Col>
        </Row>
      </div>
      <div className="content mt-3">
        <div
          className="alert alert-info informationBox sub-header mt-1 mb-1"
          role="alert"
        >
          <h6>
            {t(
              "Modules.Room categories (+ add new).Add new room category  - Information box"
            )}
          </h6>
        </div>
        <Row>
          <Col md={6}>
          </Col>
          <Col md={6} className="alignContentCenter">
            {/* <Languages
              onChange={onLanguageSelect}
              defaultValue={defaultSelectedLanguage}
              valueAsKey={true}
              extraClasses="small-dropdown"
            /> */}
          </Col>
        </Row>
        <div>
          <RoomCategoriesForm isForEdit={false} selectedLanguageForUpdate={defaultSelectedLanguage} baseLangId={availableFilteredLanguages[0]?.language?.id} />
        </div>
      </div>
    </React.Fragment>
  );
};