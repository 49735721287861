import React from "react";
import { HotelTranslationForm } from "./../HotelLangaugeSettings/HotelTranslationForm";

export const TagsTranslation = () => {
  return (
    <>
      <HotelTranslationForm />
    </>
  );
};
