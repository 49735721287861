import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const Breadcrumbs = (props) => {
  return (
    <Breadcrumb>
        <NavLink className="breadcrumb-item" key = "home" to="/home">Home </NavLink>
      {props.BreadcrumbData.map((item, index) => {
        return (
          <li className="breadcrumb-item" key={index}>
            <NavLink
              activeClassName="breadcrumbs-active"
              key={index}
              to={item.path}
            >
              {item.title}
            </NavLink>
          </li>
        );
      })}
    </Breadcrumb>
  );
};
export default Breadcrumbs;
