import React from 'react'

export const ActiveStatusButton = (props)=>{
    const response = ()=>{
        
        if(!props.pending){
           return <span className="dot-pending"></span>
        }else{
           return <span className="dot-completed"></span>
        }
    }
    return(
        <React.Fragment>
            {response()}
        </React.Fragment>
    )
}

export default ActiveStatusButton