import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

const CategoryDropDown = (props) => {
  let [temp, setTemp] = useState();
  const [initialValue,setInitialValue] = useState([])
  const sendDropdownValue = (evt, val) => {
    let e = {
      target: {},
    };
    e["target"]["name"] = val.name;
    let tempArr = evt.reduce((v, i) => {
     setInitialValue([...initialValue,i])
      v.push(i?.value);
      return v;
    }, []);
    const data = tempArr.filter(function( element ) {
          return element !== undefined;
       });
    e["target"]["value"] = data;
    setTemp(tempArr);
    props.defaultValue.push(val.option)
    props.updateform(e);
  };
  const clearDropdown = (evt, val) => {
    // props.defaultValue("")
    //props.updateform({target:{name: 'category_mappings', value:[]}});
  };
const removeValue = (selectVal,state) =>{
  if(state.action=="remove-value"){
    selectVal?.forEach((val,i)=>{
        if(val?.value===state?.removedValue?.value){
          selectVal.splice(i,1);
        }
    })
    sendDropdownValue (selectVal,state)
  }
  if(state.action=="clear"){
    props.clearValue([])
  }
  
}
  let invalidStyle = {}
  let isInvalid = (props.touched[props.inpName] && props.errors[props.inpName])?(true):(false)
  if(isInvalid){
    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: '1px solid red',
        '&:hover': { borderColor: 'red' },
        boxShadow: 'none'
      })
    }
  }
  const options = []

  props.lang.forEach((v,i)=>{
    options.push({value: v['id'], label:v['name']})
  })
  return (
    <Row>
      <Col md={12} sm={12}>
        <Form.Group>
          <Form.Label className={"sub-header-text"}>
            {props.lable} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Select
            isMulti
            styles={invalidStyle}
            name={props.inpName}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            //onChange={sendDropdownValue}
            onChange={(e,state) => {state.action =="select-option"?sendDropdownValue(e,state):removeValue(props.defaultValue,state)}}
            value ={props.defaultValue}
          />
          
            <Form.Control className='d-none' isInvalid={isInvalid && props.req}></Form.Control>
            <Form.Control.Feedback type='invalid'>
              {/* {"Please fill out the field correctly"} */}
            </Form.Control.Feedback>
             
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CategoryDropDown;
