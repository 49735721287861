import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import actions from '../../../store/actions/actions';
import expectmelogo from '../icons/expectmelogo.svg';
import ResetPasswordModal from '../ResetPasswordModal';
import Logo from '../SidebarSvg/Logo.svg';
import { AuthHeader } from "./AuthHeader";

const ForgetPassword = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const isFogotPasswordApiSuccess = useSelector((state) => state.network.isLoading);
    const isLoading = useSelector((state) => state?.network?.isLoading);

    const [email, setEmail] = useState({ email: "" });
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector((state) => state?.hotelLanguageSettings?.languages);
    const [availableFilteredLanguages, setAvailableFilteredLanguages] = useState([]);
    const forgotPassword = (payload) => {
        dispatch(actions.forgotPassword(payload));
    }
    useEffect(() => {
        if (isLoading) {
            setModalShow(isLoading)
        }
    }, [isLoading])
    const isConfirm = () => {
        history.push("/reset-password");
    }
    const backToLogin = () => {
        history.push("/login");
    }
    const formInitialValue = {
        email: "",
    };
    const formSchema = yup.object().shape({
        email: yup.string().required('Required'),
    });

    useEffect(() => {
        dispatch(actions.getLanguage());
    }, []);
    useEffect(() => {
        if (languages && languages?.length) {
            setAvailableFilteredLanguages(languages)
        }
    }, [languages]);



    return (
        <React.Fragment>
            <Formik initialValues={{
                ...formInitialValue,
            }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values
                    }
                    forgotPassword(payload)

                }}
            >
                {({
                    handleChange,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <Row className="noMargin">
                            <Col md={6} sm={12} xs={12} className="loginleft">
                                <div className="logo">
                                    <img src={expectmelogo} />
                                </div>
                                <AuthHeader availableFilteredLanguages={availableFilteredLanguages} />
                                <div className="dashborad-login-form">
                                    <div>
                                        <span className="welcometext">{t("Modules.Forget Password.Forgot password?")}</span>
                                        <br />
                                        <span className="welcome-description">
                                            {t(
                                                "Modules.Forget Password.Forgot password - Description"
                                            )}
                                        </span>
                                        <br />
                                        <br />
                                    </div>
                                    <BootstrapForm.Group>
                                        <BootstrapForm.Label>{t("Modules.Forget Password.Your email")} <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type="email" name="email" onChange={handleChange} isInvalid={touched?.email && errors?.email} value={values?.email}
                                        ></BootstrapForm.Control>
                                    </BootstrapForm.Group>
                                    <Row className="mt-3">
                                        <Col md={6} sm={6} xl={6}>
                                            <Button variant="primary" type="submit">{t("Modules.Forget Password.Send new password")}</Button>
                                        </Col>
                                        <Col md={6} sm={6} xl={6}>
                                            <Button variant="secondary" onClick={backToLogin}>{t("Modules.Forget Password.Back to Login")}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col md={6} sm={12} xs={12} className="loginright">
                                <div className="login-right-panel ">
                                    <div className="background-login">
                                    </div>
                                    <div className="dashboard-header-login">
                                        <img src={Logo} />
                                    </div>
                                </div>
                            </Col>
                            <ResetPasswordModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                isConfirm={isConfirm}
                                header={"Confirm"}
                                body={t("Modules.Forget Password.Verification-popup-message")}
                            />
                        </Row>
                    </Form>)}
            </Formik>
        </React.Fragment>

    )
}

export default ForgetPassword;