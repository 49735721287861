import actions from "../actions/actions";

const initialState = {
    result: false,
    msg: false,
    success: false,
    isLoading: false,
    offersList:false,
    offerById:false,
    isUpdate:false,
    isAdd:false
  };
  

  export const OffersReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.FETCH_OFFERS_LIST:{
        return {
          ...state,
          success: true,
          isAdd:false,
          isUpdate:false,
          offersList:false
        }
      }
      case actions.FETCH_OFFERS_LIST_SUCCESS: {
        state.offersList = action?.result;
        return {
          ...state,
          success: true,
          isAdd:false,
          isUpdate:false,
        };
      }
      case actions.FETCH_OFFERS_LIST_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
              isUpdate:false,
              isAdd:false
          },
          success: false
        };
      }
      case actions.FETCH_OFFERS_BY_ID_SUCCESS: {
        state.offerById = action?.result;
        return {
          ...state,
          success: true,
          isUpdate:false,
          isAdd:false
        };
      }
      case actions.FETCH_OFFERS_BY_ID_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
              isUpdate:false,
              isAdd:false
          },
          success: false
        };
      }

      case actions.ADD_OFFER_SUCCESS: {
        return {
          ...state,
          success: true,
          isAdd:true
        };
      }
      case actions.ADD_OFFER_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.UPDATE_OFFER_SUCCESS: {
        return {
          ...state,
          success: true,
          isUpdate:true
        };
      }
      case actions.UPDATE_OFFER_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
              isUpdate:false,
              isAdd:false
          },
          success: false
        };
      }
      case actions.CLEAR_OFFER_BY_ID: {
        return {
          ...state,
          offerById:false
        };
      }
      default: {
        return state;
      }
    }
  };