import { call, put, takeLatest } from "redux-saga/effects";
import CommonService from "../../services/common.service";
import LanguageSettingsService from "../../services/Hotel/LanguageSettings.service";
import actions from "../actions/actions";

const languageService = new LanguageSettingsService();
const commonServe = new CommonService();

const getLanguageSettingForHotel = async (hotelId) => {
  try {
    const response = await languageService.getLanguageSettings(hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

const storeLanguageSettings = async (payload) => {
  try {
    const response = await languageService.storeLanguageSettings(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getLanguage = async () => {
  try {
    const response = await languageService.getLanguage();
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchEditHotelLanguageDetails(action) {
  try {
    const hotelId = action.payload.hotelId;
    yield put({ type: actions.API_FETCHING });
    const response = yield call(storeLanguageSettings, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.EDIT_HOTEL_LANG_SETTINGS_SUCCESS });
      yield call(dispatchGetHotelLanguageDetails, { hotelId });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.EDIT_HOTEL_LANG_SETTINGS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.EDIT_HOTEL_LANG_SETTINGS_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchGetHotelLanguageDetails(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getLanguageSettingForHotel, action.hotelId);
    if (response?.data?.success) {
      yield put({
        type: actions.FETCH_HOTEL_LANG_SETTINGS_LIST_SUCCESS,
        data: response.data,
      });
      yield put({ type: actions.API_SUCCESS });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchgetLanguage(action) {
  try {
    const response = yield call(getLanguage);
    if (response?.data?.success) {
      yield put({
        type: actions.GET_LANGUAGE_SUCCESS,
        result: response.data,
      });
      //yield put({ type: actions.API_SUCCESS, data: response });
    } else {
      yield put({
        type: actions.GET_LANGUAGE_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.GET_LANGUAGE_FAILED, msg: error?.message });
  }
}




export function* fetchHotelLanguageSettings() {
  yield takeLatest(
    actions.FETCH_HOTEL_LANG_SETTINGS_LIST,
    dispatchGetHotelLanguageDetails
  );
}

export function* fetchStoreHotelLanguageSettings() {
  yield takeLatest(
    actions.EDIT_HOTEL_LANG_SETTINGS,
    dispatchEditHotelLanguageDetails
  );
}

export function* listenGetLanguage() {
  yield takeLatest(
    actions.GET_LANGUAGE,
    dispatchgetLanguage
  );
}
