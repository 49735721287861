import React, { useEffect, useState } from "react";

const HotSpot = ({ content, transform, visibility = "visible",id="" }) => {
  const [styleTop, setStyleTop] = useState(50);
  const [styleLeft, setStyleLeft] = useState(50);

  useEffect(() => {
    if (transform) {
      const { top, left } = JSON.parse(transform)?.percentage;
      setStyleTop(top);
      setStyleLeft(left);
    }
  }, [transform]);

  const style = {
    position: "absolute",
    top: `calc(${styleTop}%)`,
    left: `calc(${styleLeft}%)`,
    transform: transform,
    visibility,
  };

  return <div id={id} style={style}>{content}</div>;
};

export default HotSpot;
