import { Form, Formik } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import Breadcrumbs from "../Common/Breadcrumbs";
import Spinner from "../Common/Spinner";
import BasicInformation from "./BasicInformation";
import DetailedInformation from "./DetailedInformation";
import HighLightRoom from "./HighLightRoom";
import RoomPictures from "./RoomPictures";

export const AddRoom = (props) => {
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const { t } = useTranslation();
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-configuration", title: hotelName },
    { path: "/hotel-room-list", title: "Room list" },
    { path: "/add-room", title: "Add new room" },
  ];
  const dispatch = useDispatch();
  const hotelId = localStorage.getItem("hotelId");
  const [allowAccess, setAllowAccess] = useState(true);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_create));
    dispatch(actions.pmsHotelId(hotelId));

    dispatch(actions.assignToFloor(hotelId));
  }, []);
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const pmsHotel = useSelector((state) => state?.constants?.pmsHotel);
  const langId = useSelector((state) => state.constants.languages);
  const hotelRoomCategory = useSelector(
    (state) => state?.constants?.hotelRoomCategory
  );
  const hotelAssignFloor = useSelector(
    (state) => state.constants.hotelAssignFloor
  );

  const [content, setContent] = useState(null);
  const [editorState, setEditorState] = useState();
  const [fileUploaded, setFileUploaded] = useState([]);
  const [tags, setTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [tagsInApi, settagsInApi] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const isLoading = useSelector((state) => state?.hotelRoomAdd?.isLoading);
  const [formInitialValue, setFormInitialValue] = useState({
    pms_room_id: "",
    name: "",
    category_id: "",
    floor_id: "",
    is_active: 0,
    description: "",
    hotel_tags: [],
    new_tags: [],
    tagsString: tags.toString(),
    is_manager_choice: Boolean(),
    hotel_id: hotelId,
    language_id: defaultSelectedLanguage,
    hotspot_data: false,
    hotspot_width: false,
  });
  const toast = new ToastService();

  useEffect(() => {
    dispatch(actions.searchTags(hotelId, searchKey));
  }, [searchKey]);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      dispatch(actions.hotelRoomCategory(hotelId, defaultSelectedLanguage));
    }
  }, [defaultSelectedLanguage]);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      dispatch(actions.hotelRoomCategory(hotelId, defaultSelectedLanguage));
    }
  }, [defaultSelectedLanguage]);

  useEffect(() => {
    setDefaultLanguage(
      langId.filter((option) => option.language.key === i18next.language)[0]
        ?.language?.id
    );
    setFormInitialValue({
      pms_room_id: null,
      name: "",
      category_id: null,
      floor_id: null,
      hotspot_data: false,
      hotspot_width: false,
      description: "",
      is_active: 1,
      hotel_tags: [],
      new_tags: [],
      tagsString: tags.toString(),
      is_manager_choice: Boolean(),
      hotel_id: hotelId,
      language_id: langId.filter(
        (option) => option.language.key === i18next.language
      )[0]?.language?.id,
    });
  }, [langId]);

  const tagsReceived = useSelector((state) => state.hotelDetails.tags);

  const result = useSelector((state) => state.hotelRoomAdd.created);
  useEffect(() => {
    if (result) {
      toast.onSuccess("Room Created");
      props.history.push("/hotel-room-list");
    }
  }, [result]);

  const formSchema = yup.object().shape({
    tagsString: yup.string().min(2).required("Please fill out this field"),
    name: yup
      .string()
      .min(2, "To short")
      .max(255, "Maximum Limit reached")
      .required("Required"),
    pms_room_id: yup.number().required("Please Enter the field"),
    description: yup
      .string()
      .test(
        "length",
        "Maximum is 2000",
        (value) => Utils.getHTMLtoPlainText(value).length <= 2000
      ),
    category_id: yup.number().required("Please Enter the field"),
    floor_id: yup.number().required("Please Enter the field"),
  });

  const selectedTags = useSelector(
    (state) => state?.hotelDetails?.result?.tags
  );

  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );

    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };

  const updateEditorText = (editorState, setFieldValue) => {
    setEditorState(editorState);
    setContent(editorState);
    if (Utils.getHTMLtoPlainText(editorState).trim().length) {
      setEditorState(editorState);
    } else {
      setEditorState("");
    }
  };

  const addNewRoom = (data) => {
    dispatch(actions.createRoom(data));
  };

  <hr className="border-line" />;

  return !isLoading ? (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs}></Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3> {t("Modules.Rooms List.Add a new room")} </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col md={6}></Col>
          <Col md={6} className="alignContentCenter"></Col>
        </Row>

        <Formik
          initialValues={{
            ...formInitialValue,
          }}
          validationSchema={formSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            let tagsAlreadyPresent = "";
            tagsInApi.forEach((tag, i) => {
              if (!i) {
                tagsAlreadyPresent = tagsAlreadyPresent + tag.id;
              } else {
                tagsAlreadyPresent = tagsAlreadyPresent + "," + tag.id;
              }
            });

            const payload = {
              ...values,
              description: editorState,
              images: { data: fileUploaded, name: "room_images" },
              new_tags: newTags.toString(),
              hotel_tags: tagsAlreadyPresent,
              is_manager_choice: values.is_manager_choice ? 1 : 0,
            };

            addNewRoom(payload);
          }}
        >
          {({
            handleChange,
            errors,
            values,
            touched,
            submitForm,
            setFieldValue,
          }) => (
            <Form>
              <BasicInformation
                change={handleChange}
                error={errors}
                roomId={false}
                values={values}
                pmsHotel={pmsHotel}
                touched={touched}
                hotelRoomCategory={hotelRoomCategory}
                hotelAssignFloor={hotelAssignFloor}
                filedValue={setFieldValue}
                updateEditorText={(updateText, value) => {
                  console.log(`Description ==>>> : ${updateText}`);
                  setFieldValue("description", updateText);
                  setEditorState(updateText);
                  setContent(updateText);
                }}
                defaultSelectedLanguage={defaultSelectedLanguage}
              ></BasicInformation>

              <hr className="border-line" />
              <DetailedInformation
                change={handleChange}
                error={errors}
                touched={touched}
                values={values}
                content={content}
                editorValue={editorState}
                updateTags={{ tags, setTags }}
                removeTags={{ deletedTags, setDeletedTags }}
                setNewTags={{ newTags, setNewTags }}
                existingTags={selectedTags || tagsReceived}
                searchTags={{ searchKey, setSearchKey }}
                tagsApiId={{ tagsInApi, settagsInApi }}
                setFieldValue={setFieldValue}
                path={"create"}
                updateEditorText={(updateText) => {
                  console.log(`Description ==>>> : ${updateText}`);
                  updateEditorText(updateText, setFieldValue);
                }}
                defaultSelectedLanguage={defaultSelectedLanguage}
              ></DetailedInformation>

              <hr className="border-line" />
              <HighLightRoom
                change={handleChange}
                error={errors}
                setFieldValue={setFieldValue}
                values={values}
              ></HighLightRoom>
              <hr className="border-line" />
              <RoomPictures
                {...props}
                change={setFileUploaded}
                error={errors}
                values={values}
                path={"add"}
                maximumFile={0}
                multi={true}
              ></RoomPictures>
              <Row className="mt-4">
                <Col md={2} sm={6} xs={6}>
                  {allowAccess ? (
                    <Button
                      variant="primary"
                      disabled={
                        defaultSelectedLanguage !== langId[0]?.language?.id
                      }
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {t("Modules.Rooms List.Add new room")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={2} sm={6} xs={6}>
                  <Button
                    variant="secondary"
                    onClick={() => props.history.goBack()}
                  >
                    {t("Modules.Rooms List.Cancel button")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  ) : (
    <Spinner />
  );
};

export default AddRoom;
