import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Col, Form as bootstrapForm, Row
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";

export const SeasonTranslation = ({
  data,
  languageIds,
  languageNames,
  hotelId,
  filter,
}) => {
  const [forHeader, setForHeader] = useState([
    {
      header: "Season Name",
    },
  ]);
  const { t } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const formInitialValue = {
    translation: {},
  };

  const updateSeasonMessages = (payload) => {
    const formValue = payload;
    formValue.forEach((seasonDetail) => {
      seasonDetail.hotelId = hotelId;
    });
    dispatch(actions.updateTranslation({ season: formValue }, hotelId, filter));
  };
  const [allowAccess, setAllowAccess] = useState(true);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create))

    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const filteredData = Utils.filterArrayForSeasons(data, languageIds);
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);
  return (
    <>
      <div className="row-vertical-scroll">
        <Row className="mb-3">
          {forHeader?.map((headerInfo, index) => (
            <Col key={`header-${index}`}>
              <strong>{headerInfo?.header}</strong>
            </Col>
          ))}
        </Row>
        <Formik
          innerRef={formikRef}
          initialValues={{ ...formInitialValue }}
          onSubmit={(values) => {
            updateSeasonMessages(values.translation);
          }}
        >
          {({ handleChange, values, submitForm, setFieldValue }) => (
            <>
              <FieldArray name="translations">
                {() => {
                  const translationArray = values?.translation;
                  return (
                    <>
                      {translationArray && translationArray.length ? (
                        <>
                          <Row>
                            {translationArray?.map((seasonInfo, index) => (
                              <React.Fragment key={`form-${index}`}>
                                <Col
                                  key={`form-name-${index}`}
                                  className="full-wrap"
                                >
                                  <bootstrapForm.Control
                                    key={`season-label-form-${index}`}
                                    className="mt-3 disable-form"
                                    disabled={true}
                                    value={seasonInfo?.name || "NA"}
                                  />
                                </Col>
                                {seasonInfo?.seasons_ts.map(
                                  (translationValue, tagIndex) => (
                                    <Col key={`col-${tagIndex}-${index}`}>
                                      <div key={`col-div-${tagIndex}-${index}`}>
                                        <bootstrapForm.Control
                                          key={`translation-form-${tagIndex}`}
                                          type="text"
                                          value={translationValue?.name}
                                          className="mt-3"
                                          name={`translation.${index}.seasons_ts.${tagIndex}.name`}
                                          onChange={(event)=>{
                                            if(event.target.value.trim().length > 0){
  
                                              handleChange(event)
                                            }else if (event.target.value.trim().length < 1){
                                              setFieldValue(`translation.${index}.seasons_ts.${tagIndex}.name`,"")
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  )
                                )}
                                <span className="mt-2 mb-2">
                                  <hr />
                                </span>
                              </React.Fragment>
                            ))}
                            {/* } */}
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                }}
              </FieldArray>
              {allowAccess?<Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>:<></>}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
