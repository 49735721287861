import actions from '../actions/actions'

const initialState = {
    roles: false,
    
}

export const authrReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case actions.GET_ROLE_SUCCESS: {
            return {
                ...state,
                roles: action.data,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }
        default: {
            return state
        }
    }
}