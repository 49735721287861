import { Form, Formik } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import Breadcrumbs from "../Common/Breadcrumbs";
import Spinner from "../Common/Spinner";
import BasicInformation from "./BasicInformation";
import DetailedInformation from "./DetailedInformation";
import HighLightRoom from "./HighLightRoom";
import RoomPictures from "./RoomPictures";

export const UpdateRoom = (props) => {
  const toast = new ToastService();
  const roomId = props.match.params.roomId;
  const hotelId = localStorage.getItem("hotelId");
  const dispatch = useDispatch();
  const langId = useSelector((state) => state.constants.languages);
  const { t } = useTranslation();
  const [content, setContent] = useState(null);
  const [editorState, setEditorState] = useState();
  // const [editorText, setEditorState] = useState(EditorState.createEmpty());
  const [fileUploaded, setFileUploaded] = useState([]);
  const [imageFromApi, setimageFromApi] = useState([]);
  const [tags, setTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [tagsInApi, settagsInApi] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [firstRendering, setFirstRendering] = useState(true);
  const [allowAccess, setAllowAccess] = useState(true);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const isLoading = useSelector((state) => state?.hotelRoomAdd?.isLoading);
  const [formInitialValue, setFormInitialValue] = useState();

  useEffect(() => {
    dispatch(actions.pmsHotelId(hotelId));
    dispatch(actions.assignToFloor(hotelId));
  }, []);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_update));

    setDefaultLanguage(
      langId.filter((option) => option.language.key === i18next.language)[0]
        ?.language?.id
    );
  }, [langId]);

  console.log(`Rendering UI components`);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      dispatch(actions.hotelRoomCategory(hotelId, defaultSelectedLanguage));

      dispatch(actions.getRoom(roomId, defaultSelectedLanguage));
    }
  }, [defaultSelectedLanguage]);

  const hotelRoomDetails = useSelector((state) => state?.hotelRoomAdd?.result);
  const pmsHotel = useSelector((state) => state?.constants?.pmsHotel);

  const hotelRoomCategory = useSelector(
    (state) => state?.constants?.hotelRoomCategory
  );

  const serviceTypes = useSelector((state) => state?.constants?.types);

  const hotelAssignFloor = useSelector(
    (state) => state.constants.hotelAssignFloor
  );

  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-configuration", title: hotelName },
    { path: "/hotel-room-list", title: "Room list" },
    { path: "/update-room", title: hotelRoomDetails?.pms_room?.name },
  ];

  useEffect(() => {
    if (defaultSelectedLanguage) {
      dispatch(actions.searchTags(hotelId, searchKey, defaultSelectedLanguage));
    }
  }, [searchKey, defaultSelectedLanguage]);

  const tagsReceived = useSelector((state) => state.hotelDetails.tags);

  useEffect(() => {
    if (hotelRoomDetails) {
      setTags([]);
      setDeletedTags([]);
      setNewTags([]);
      settagsInApi([]);
      setSelectedTags([]);
      setSearchKey("");
      try {
        const des = Utils.langValueGenerator(
          hotelRoomDetails?.rooms_ts || [],
          "description",
          defaultSelectedLanguage
        );

        setFormInitialValue({
          pms_room_id: hotelRoomDetails?.pms_room_id,
          name: Utils.langValueGenerator(
            hotelRoomDetails?.rooms_ts || [],
            "name",
            defaultSelectedLanguage
          ),
          category_id: hotelRoomDetails?.category_id,
          floor_id: hotelRoomDetails?.floor_id,
          hotspot_data: hotelRoomDetails?.h_floor_room_mappings?.[0]?.map_data,
          hotspot_width:
            hotelRoomDetails?.h_floor_room_mappings?.[0]?.hotspot_width,
          hotspot_id: hotelRoomDetails?.h_floor_room_mappings?.[0]?.id,
          description: des,
          is_active: hotelRoomDetails?.is_active,
          hotel_tags: hotelRoomDetails?.room_tags,
          new_tags: [],
          tagsString: tags.toString(),
          is_manager_choice: hotelRoomDetails?.is_manager_choice,
          hotel_id: hotelId,
          language_id: defaultSelectedLanguage,
        });
        const img = [];
        hotelRoomDetails?.room_images?.map((image, i) =>
          img.push({ arrId: image.id, preview: image.image_path })
        );

        setimageFromApi(img);
        pmsHotel.push({
          id: hotelRoomDetails?.pms_room?.id,
          value: hotelRoomDetails?.pms_room?.name,
        });

        console.log(`Use effect Description :`);

        setEditorState(des || "");
        setContent(des || "");
      } catch (error) {}
    }
  }, [hotelRoomDetails, pmsHotel]);

  const updated = useSelector((state) => state?.hotelRoomAdd?.updated);
  useEffect(() => {
    if (updated) {
      toast.onSuccess("Room Updated");
      props.history.push("/hotel-room-list");
    }
  }, [updated]);

  const formSchema = yup.object().shape({
    tagsString: yup.string().min(2).required("Please fill out this field"),
    name: yup.string().min(2, "To short").required("Required"),
    pms_room_id: yup.number().required("Please Enter the field"),
    category_id: yup.number().required("Please Enter the field"),
    floor_id: yup.number().required("Please Enter the field"),
    description: yup
      .string()
      .test(
        "length",
        "Maximum is 2000",
        (value) => Utils.getHTMLtoPlainText(value).length <= 2000
      ),
  });

  const languages = [
    {
      id: 1,
      code: "English",
      is_deleted: 0,
      is_active: 1,
    },
  ];

  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );

    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };
  const selectedTagsApi = useSelector(
    (state) => state?.hotelRoomAdd?.result?.room_tags
  );

  useEffect(() => {
    if (selectedTagsApi) {
      const tempapiTag = [];

      selectedTagsApi.forEach((apiTag, i) => {
        tempapiTag.push({
          id: apiTag.id,
          name: Utils.langValueGenerator(
            apiTag.tag_tag?.tags_ts,
            "name",
            defaultSelectedLanguage
          ),
        });
      });

      setSelectedTags(tempapiTag);
    }
  }, [selectedTagsApi]);

  const updateEditorText = (editorState, setFieldValue) => {
    setContent(editorState);

    if (Utils.getHTMLtoPlainText(editorState).trim().length) {
      setEditorState(editorState);
    } else {
      setEditorState("");
    }
  };

  const updateRoom = (values) => {
    let tagsAlreadyPresent = "";
    tagsInApi.forEach((tag, i) => {
      if (!i) {
        tagsAlreadyPresent = tagsAlreadyPresent + tag.id;
      } else {
        tagsAlreadyPresent = tagsAlreadyPresent + "," + tag.id;
      }
    });

    const tagsDeleted = [];
    selectedTags.forEach((tagsPresent, i) => {
      if (!tags.includes(tagsPresent.name)) {
        tagsDeleted.push(tagsPresent.id);
      }
    });

    const imageAlreadyPresent = [];
    imageFromApi.forEach((alreadyPresent, i) => {
      imageAlreadyPresent.push(alreadyPresent.arrId);
    });

    const fileUploadedId = [];
    fileUploaded.forEach((uploadedFile, i) => {
      fileUploadedId.push(uploadedFile.arrId);
    });

    const imageDeletedFromAlreadyPresent = [];
    const fileSend = fileUploaded;
    imageAlreadyPresent.forEach((imageAlreadyPresentId, i) => {
      fileSend.forEach((uploadedFile, i2) => {
        if (uploadedFile.arrId === imageAlreadyPresentId) {
          fileSend.splice(i2, 1);
        }
      });
    });

    imageAlreadyPresent.forEach((imageAlreadyPresentId, i) => {
      if (!fileUploadedId.includes(imageAlreadyPresentId)) {
        imageDeletedFromAlreadyPresent.push(imageAlreadyPresentId);
      }
    });

    const payload = {
      ...values,
      description: editorState,
      images: { data: fileSend, name: "room_images" },
      new_tags: newTags.toString(),
      hotel_tags: tagsAlreadyPresent,
      deleted_tags: tagsDeleted.toString(),
      deleted_images: imageDeletedFromAlreadyPresent.toString(),
      is_manager_choice: values.is_manager_choice ? 1 : 0,
    };

    dispatch(actions.updateRoom(payload, roomId));
  };
  <hr className="border-line" />;
  return !isLoading ? (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs}></Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3> {t("Modules.Rooms List.Update Room")} </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col md={6}></Col>
          <Col md={6} className="alignContentCenter"></Col>
        </Row>
        {hotelRoomDetails && formInitialValue && (
          <Formik
            initialValues={{
              ...formInitialValue,
            }}
            validationSchema={formSchema}
            onSubmit={(values) => {
              updateRoom(values);
            }}
            enableReinitialize={true}
          >
            {({
              handleChange,
              errors,
              values,
              touched,
              submitForm,
              setFieldValue,
            }) => (
              <Form>
                <BasicInformation
                  change={handleChange}
                  roomId={roomId}
                  error={errors}
                  values={values}
                  pmsHotel={pmsHotel}
                  touched={touched}
                  hotelRoomCategory={hotelRoomCategory}
                  hotelAssignFloor={hotelAssignFloor}
                  filedValue={setFieldValue}
                  updateEditorText={(updateText, value) => {
                    setEditorState(updateText);
                    setContent(updateText);
                    //setFieldValue("description", updateText);

                    console.log(`Basic Information Text : `);

                    // if (formInitialValue.category_id === value) {
                    //   setFieldValue(
                    //     "description",
                    //     formInitialValue.description
                    //   );

                    //   setEditorState(formInitialValue.description);
                    //   setContent(formInitialValue.description);
                    // } else {

                    // }
                  }}
                ></BasicInformation>

                <hr className="border-line" />

                <DetailedInformation
                  change={handleChange}
                  error={errors}
                  touched={touched}
                  values={values}
                  path={"update"}
                  editorValue={editorState}
                  content={content}
                  updateTags={{ tags, setTags }}
                  removeTags={{ deletedTags, setDeletedTags }}
                  setNewTags={{ newTags, setNewTags }}
                  existingTags={tagsReceived}
                  searchTags={{ searchKey, setSearchKey }}
                  tagsApiId={{ tagsInApi, settagsInApi }}
                  setFieldValue={setFieldValue}
                  updateEditorText={(updateText) => {
                    console.log(`Detailed Information Text : `);
                    updateEditorText(updateText, setFieldValue);
                  }}
                  disabled={defaultSelectedLanguage !== langId[0]?.language?.id}
                  initialValues={formInitialValue}
                ></DetailedInformation>

                <hr className="border-line" />
                <HighLightRoom
                  change={handleChange}
                  setFieldValue={setFieldValue}
                  error={errors}
                  values={values}
                ></HighLightRoom>
                <hr className="border-line" />
                <RoomPictures
                  {...props}
                  change={setFileUploaded}
                  error={errors}
                  values={imageFromApi}
                  maximumFile={10}
                  multi={true}
                  path={"update"}
                ></RoomPictures>
                <Row className="mt-4">
                  <Col md={2} sm={6} xs={6}>
                    {allowAccess ? (
                      <Button
                        disabled={
                          defaultSelectedLanguage !== langId[0]?.language?.id
                        }
                        variant="primary"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {/* {t("Modules.Rooms List.Update Room")} */}
                        {t("Modules.Rooms List.Save button")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col md={2} sm={6} xs={6}>
                    <Button
                      variant="secondary"
                      onClick={() => props.history.goBack()}
                    >
                      {t("Modules.Rooms List.Cancel button")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
        {!hotelRoomDetails && <Spinner></Spinner>}
      </div>
    </React.Fragment>
  ) : (
    <Spinner />
  );
};

export default UpdateRoom;
