import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";

const DraggableHotSpot = ({
  content,
  setHotspotCoOrdinatesData,
  transform,
  isNew,
  hotspotWidth,
  defaultPosition,
  imageHotSpotRef,
  scale,
}) => {
  const onHanldeDragStop = (e) => {
    const { width, height } = getComputedWidthAndHeight(
      nodeRef.current.parentElement
    );

    const [x, y] = nodeRef.current.style.transform
      .replace("translate(", "")
      .split(",");
    setX(parseFloat(x));
    setY(parseFloat(y));

    const left = (parseFloat(x) * 100) / width;
    const top = ((height - Math.abs(parseFloat(y))) * 100) / height;

    // setHotspotCoOrdinatesData(e.target.parentElement.style.transform);
    setHotspotCoOrdinatesData(
      JSON.stringify({
        percentage: { top: 100 - top, left },
        transform: nodeRef.current.style.transform,
        width: hotspotWidth,
      })
    );
    // setHotspotCoOrdinatesData(e.target.parentElement.style.transform);
  };

  const getComputedWidthAndHeight = (imageHotSpotRef) => {
    let element = imageHotSpotRef?.instance
      ? imageHotSpotRef.instance.contentComponent
      : imageHotSpotRef;
    const { width, height } = getComputedStyle(element);
    const parsedWidth = parseFloat(width);
    const parsedHeight = parseFloat(height);
    return { width: parsedWidth, height: parsedHeight };
  };

  const [windowWidth, setWindowWidth] = useState();
  const [windowHeight, setWindowHeight] = useState();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // useEffect(() => {
  //   resizeWindow();
  //   window.addEventListener("resize", resizeWindow);
  // }, []);

  useEffect(() => {
    calculateXAndY();
    const style = `translate(${x}px, ${y}px)`;
    if (nodeRef.current !== null) nodeRef.current.style.transform = style;
  }, [transform]);

  const [x, setX] = useState();
  const [y, setY] = useState();

  const calculateXAndY = () => {
    const { width, height } = getComputedWidthAndHeight(imageHotSpotRef);
    if (transform) {
      //causing issues with panzoom + transform
      const { top, left } = JSON.parse(transform)?.percentage;
      const x = (left * width) / 100;
      const y = (top * height) / 100;

      let [_x, _y] = JSON.parse(transform)
        .transform.replace("translate(", "")
        .split(",");

      _x = parseFloat(_x);
      _y = parseFloat(_y);

      setX(parseFloat(x));
      setY(parseFloat(_y > 0 ? Math.abs(y) : y));
    } else {
      setX(width * 0.5);
      setY(height * 0.5);
      // imageHotSpotRef.centerView(1,500);
    }
  };

  const [nodeRef, setNodeRef] = useState(React.createRef(null));

  return x && y ? (
    <Draggable
      nodeRef={nodeRef}
      onStop={onHanldeDragStop}
      // defaultPosition={defaultPosition}
      position={{ x, y }}
      scale={scale}
    >
      <div
        ref={nodeRef}
        style={{
          position: "absolute",
          zIndex: 10000,
        }}
      >
        {content}
      </div>
    </Draggable>
  ) : (
    <></>
  );
};

export default DraggableHotSpot;
