import actions from "../actions/actions";

const initialState = {
    result: false,
    msg: false,
    success: false,
    isLoading: false,
    roomCategories:false,
    PMSRoomTypes:false,
    roomCategoriesById:false,
    isAdd:false,
    isUpdate:false
  };
  

  export const RoomCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.FETCH_ROOM_CATEGORIES: {
        return {
          ...state,
          roomCategories: false,
          success: false,
          isAdd:false,
          isUpdate:false
        };
      }
      case actions.FETCH_ROOM_CATEGORIES_SUCCESS: {
        state.roomCategories = action?.result;
        return {
          ...state,
          success: true,
          isAdd:false,
          isUpdate:false,
          roomCategoriesById:false
        };
      }
      case actions.FETCH_ROOM_CATEGORIES_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.FETCH_PMS_ROOM_TYPES_SUCCESS: {
        state.PMSRoomTypes = action?.result;
        return {
          ...state,
          success: false
        };
      }
      case actions.FETCH_PMS_ROOM_TYPES_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.FETCH_ROOM_CATEGORY_BY_ID_SUCCESS: {
        state.roomCategoriesById = action?.result;
        return {
          ...state,
          success: false
        };
      }
      case actions.FETCH_ROOM_CATEGORY_BY_ID_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }


      case actions.ADD_ROOM_CATEGORIES_SUCCESS: {
        return {
          ...state,
          success: false,
          isAdd:true,
        };
      }
      case actions.ADD_ROOM_CATEGORIES_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.UPDATE_ROOM_CATEGORY_SUCCESS: {
        return {
          ...state,
          success: false,
          isUpdate:true
        };
      }
      case actions.UPDATE_ROOM_CATEGORY_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.RESET_ROOM_CATEGORIES: {
        return{
          state
        }
      }

      default: {
        return state;
      }
    }
  };