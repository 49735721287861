import { call, put, takeLatest } from 'redux-saga/effects';
import Authr from "../../services/Authr/index";
import { StorageService } from "../../services/storage.service";
import UserManagement from '../../services/UserManagement/index';
import actions from '../actions/actions';
const storageService = new StorageService();

const columnMapForReducer = {
    username:"USERNAME",
    email:"EMAIL",
    role:"ROLE",
    code:"LANGUAGE",
  }

let UserManagementServe = new UserManagement();
let AuthrServe = new Authr();
const getUsers = async (hotelGroupId, payload) => {
    try {
        const response = await AuthrServe.getUsers(hotelGroupId, payload)
        return response
    }
    catch (err) {
        throw err;
    }
};

const addUser = async (payload) => {
    try {
        const response = await AuthrServe.createUser(payload)
        return response
    }
    catch (err) {
        throw err;
    }
};

const updateUser = async (userId, payload) => {
    try {
        const response = await AuthrServe.UpdateUser(userId, payload)
        return response
    }
    catch (err) {
        throw err;
    }
};

const getUserProfile = async (payload) => {
    try {
        const response = await UserManagementServe.getUserProfile(payload)
        return response
    }
    catch (err) {
        throw err;
    }
};

const updateUserProfile = async (payload) => {
    try {
        const response = await UserManagementServe.updateUserProfile(payload)
        return response
    }
    catch (err) {
        throw err;
    }
};

const getUserById = async (userId) => {
    try {
        const response = await AuthrServe.getUserById(userId)
        return response
    }
    catch (err) {
        throw err;
    }
};

function* dispatchgetUsers(action) {
    try {
        const response = yield call(getUsers, action.hotelGroupId, action.payload)
        yield put({ type: actions.API_FETCHING });
        if (response.data.success) {
            // setting up the token
            yield put({ type: actions.API_SUCCESS });
            yield put({
                type: actions.FETCH_USERS_SUCCESS,
                msg: response.data.msg,
                result: response?.data?.data,
            })
            yield put({
                type: actions.SET_USER_LIST_PAGE_NO,
                page: response?.data?.data.page,
              });
              yield put({
                type: actions.CLEAR_USER_BY_ID,
              });
        
              yield put({
                type: actions.SET_USER_SORT,
                sortBy: action.payload.sortBy?action.payload.sortBy:"ASC",
                sortName: action.payload.sort,
                sortColumnName: columnMapForReducer[action.payload.sort] ? columnMapForReducer[action.payload.sort] : "USERNAME",
                sort: action.payload.sortBy === "DESC" ? true : false
              });
        } else {
            yield put({
                type: actions.FETCH_USERS_FAILED,
                msg: response.data.msg
            })
            yield put({ type: actions.API_FAILED, });
        }
    } catch (error) {
        yield put({
            type: actions.FETCH_USERS_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchAddUser(action) {
    try {
        const response = yield call(addUser, action.payload)
        if (response.data.success) {
            // setting up the token
            yield put({
                type: actions.ADD_USERS_SUCCESS,
                msg: response.data.msg,
            })
            yield put({
                type: actions.API_SUCCESS,
                data: response,
            });
        } else {
            yield put({
                type: actions.ADD_USERS_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.ADD_USERS_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchUpdateUser(action) {
    try {
        const response = yield call(updateUser, action.userId, action.payload)
        if (response.data.success) {
            // setting up the token
            yield put({
                type: actions.UPDATE_USERS_SUCCESS,
                msg: response.data.msg,
            })
            yield put({
                type: actions.API_SUCCESS,
                data: response,
            });
        } else {
            yield put({
                type: actions.UPDATE_USERS_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.UPDATE_USERS_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchgetUserProfile(action) {
    try {
        const response = yield call(getUserProfile, action.payload)
        if (response.data.success) {
            // setting up the token
            yield put({
                type: actions.GET_USER_PROFILE_SUCCESS,
                msg: response.data.msg,
                result: response?.data?.data,
            })
            storageService.setItem("user-first-name",response?.data?.data?.first_name);
        } else {
            yield put({
                type: actions.GET_USER_PROFILE_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_USER_PROFILE_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchupdateUserProfile(action) {
    try {
        const response = yield call(updateUserProfile, action.payload)
        const accessToken = { payload: { "accessToken": action.payload.data.accessToken } }
        if (response.data.success) {
            // setting up the token
            yield put({
                type: actions.UPDATE_USER_PROFILE_SUCCESS,
                msg: response.data.msg,
            })
            yield call(dispatchgetUserProfile, accessToken)
            yield put({
                type: actions.API_SUCCESS,
                data: response,
            });
        } else {
            yield put({
                type: actions.UPDATE_USER_PROFILE_FAILED,
                msg: response.data.msg
            })
            yield put({
                type: actions.API_FAILED,
                data: response,
            });
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response || error,
        });
        yield put({
            type: actions.UPDATE_USER_PROFILE_FAILED,
            msg: error?.response?.data?.msg
        })
    }
}

function* dispatchgetUserById(action) {
    try {
        const response = yield call(getUserById, action.userId)
        if (response.data.success) {
            // setting up the token
            yield put({
                type: actions.GET_USER_BY_ID_SUCCESS,
                msg: response.data.msg,
                result: response?.data?.data,
            })
            yield put({
                type: actions.API_SUCCESS,
                // data: response,
            });
        } else {
            yield put({
                type: actions.GET_USER_BY_ID_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_USER_BY_ID_FAILED,
            msg: error?.response?.data?.msg
        })
    }
}


export function* listengetUsers() {
    yield takeLatest(actions.FETCH_USERS, dispatchgetUsers)
}

export function* listenAddUser() {
    yield takeLatest(actions.ADD_USERS, dispatchAddUser)
}

export function* listenUpdateUser() {
    yield takeLatest(actions.UPDATE_USERS, dispatchUpdateUser)
}

export function* listengetUserProfile() {
    yield takeLatest(actions.GET_USER_PROFILE, dispatchgetUserProfile)
}

export function* listenupdateUserProfile() {
    yield takeLatest(actions.UPDATE_USER_PROFILE, dispatchupdateUserProfile)
}

export function* listengetUserById() {
    yield takeLatest(actions.GET_USER_BY_ID, dispatchgetUserById)
}