import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import * as yup from "yup";
import actions from "../../store/actions/actions";
import PmsCategories from "../Common/PmsCategories";
import { StorageService } from "./../../services/storage.service";

const ConfigurationForm = () => {
    const { t } = useTranslation()
    const storageService = new StorageService();
    const isLoading = useSelector((state) => state?.network?.isLoading);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(actions.setSearchHotelRoomList(""))
        dispatch(actions.setSearchFloorList(""))
        dispatch(actions.setSearchSeason(""))
        dispatch(actions.setSearchDealList(""))
        dispatch(actions.setSearchOfferList(""))
        dispatch(actions.setSearchUserList(""))
    },[])

    //get hotel id from local storage
    const hotelId = +storageService.getItem("hotelId");
    const formikRef = useRef();

    const [isConfigured, setIsConfigured] = useState(false);
    const [pmsId, setPMSid] = useState(false);
    const [pmsName, setPMSName] = useState([]);
    const [selectedPMSName, setSelectedPMSName] = useState();

    const pmsRoomTypesData = useSelector(
        (state) => state.roomCategories.PMSRoomTypes
    );
    const hotelConfiguration = useSelector(
        (state) => state.hotelDetails.configuration
    );
    const [formInitialValue, setFormInitialValue] = useState({
        is_active: false,
        pms_category_id: "",
        email: "",
    });

    //formik formSchema for validating form
    const formSchema = yup.object().shape({
        is_active: false,
        pms_category_id: yup.string().required("Please select PMS Category"),
        email: yup.string().trim().min(1).required("Please Enter Email"),

    });
    useEffect(() => {
        if (hotelConfiguration) {
            //assigning values to variable for validating form
            setPMSid(hotelConfiguration.pms_integration_id)
            setIsConfigured(hotelConfiguration.is_configured)
            setFormInitialValue({
                ...formInitialValue,
                is_active: hotelConfiguration.is_live,
            });
        }
    }, [hotelConfiguration]);

    useEffect(() => {
        //assigning values to variable for validating form
        dispatch(actions.getPMSRoomsTypes(hotelId));
        dispatch(actions.getHotelBasicConfiguration(hotelId));
    }, []);
    useEffect(() => {
        if (pmsRoomTypesData) {
            //assiging Pms list  to dropdown
            const pmsName = pmsRoomTypesData?.map((pms) => {
                return { value: pms.name, id: pms.id };
            });
            setPMSName(pmsName);
        }
    }, [pmsRoomTypesData]);
    const updateHotelDetails = (value) => {
        //updating is live flag using hotel details API
        const payload = {
            "is_live": value ? true : false
        };
        dispatch(actions.hotelDetailUpdate(payload, hotelId));
    }

    return (
        <>
            <React.Fragment>
                <Formik
                    initialValues={{
                        ...formInitialValue,
                    }}
                    validationSchema={formSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        if (values) {
                            //dispatching API
                            values['hotel_id'] = hotelId
                            dispatch(actions.getBookingStatus(values));
                        }
                    }}
                >
                    {({
                        handleChange,
                        errors,
                        values,
                        touched,
                        submitForm,
                        setFieldValue,
                    }) => (<Form>
                        <div className="m-3 content">
                            <Row
                                className="mt-3 sub-header-text"
                                style={{ fontSize: "13px" }}
                            >

                                <Col>
                                    <Row>
                                        <label style={{ fontSize: "22px" }}>
                                            {t("Modules.Go Live.Make This Hotel Live")}
                                        </label>
                                        <div className="mt-4">
                                            <Form.Group>
                                                <Switch
                                                    name="is_active"
                                                    checked={values?.is_active}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            setFieldValue("is_active", 1);
                                                            setFormInitialValue({
                                                                ...formInitialValue,
                                                                is_active: 1,
                                                            })

                                                        } else {
                                                            setFieldValue("is_active", 0);
                                                            setFormInitialValue({
                                                                ...formInitialValue,
                                                                is_active: 0,
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            {isConfigured ? <Button
                                                variant="primary"
                                                onClick={(event) => {
                                                    event?.preventDefault()
                                                    updateHotelDetails(values.is_active)
                                                }}
                                            > {t("Modules.Go Live.Save")} </Button> : <></>}
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <label style={{ fontSize: "22px" }}>
                                            {t("Modules.Go Live.Create Test Connection")}
                                        </label>
                                        <div className="mt-3">
                                            <Form.Group>
                                                <Form.Label className={"sub-header-text"}>
                                                    {t("Modules.Go Live.PMS Category")}
                                                    <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <PmsCategories
                                                    val={pmsName}
                                                    inpName={"pms_category_id"}
                                                    updateDropdown={(selectedPmsCategory) => {
                                                        setSelectedPMSName(selectedPmsCategory);
                                                        setFieldValue("pms_category_id", selectedPmsCategory?.value);
                                                        setFormInitialValue({
                                                            ...formInitialValue,
                                                            pms_category_id: selectedPmsCategory?.value,
                                                        })
                                                    }}
                                                    values={selectedPMSName}
                                                    //touched={touched}
                                                    errors={errors}
                                                    touched={touched}
                                                ></PmsCategories>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label className={"sub-header-text"}>
                                                {t("Modules.Go Live.Email")}
                                                <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                isInvalid={
                                                    errors?.email && touched?.email
                                                }
                                                onChange={(event) => {
                                                    setFieldValue("email", event.target.value);
                                                    setFormInitialValue({
                                                        ...formInitialValue,
                                                        email: event.target.value,
                                                    })
                                                }}
                                                value={values?.email || ""}

                                            ></Form.Control>
                                            <div className="mt-3">
                                                {isConfigured && pmsId ? <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        submitForm();
                                                    }}
                                                >
                                                    {t("Modules.Go Live.Test Booking")}
                                                </Button> : <></>}
                                            </div>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Form>)}
                </Formik>
            </React.Fragment>
        </>
    )
};

export default ConfigurationForm;
