import FileSaver from "file-saver";
import { Formik } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { default as NumberFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import BootstrapTable from "../Common/BootstrapTable";
import Breadcrumbs from "../Common/Breadcrumbs";
import DropDown from "../Common/DropDown";
import { ReactComponent as ExportSVG } from "../Common/icons/export.svg";
import PaginationComponent from "../Common/PaginationComponent";
import SearchComponents from "../Common/SearchComponents";
import Spinner from "../Common/Spinner";

const BookingReport = () => {
  const breadcrumbs = [{ path: "/bookings", title: "Bookings" }];
  const hotelGroupId = localStorage.getItem("hotelGroupId");
  useEffect(() => {
    return () => {
      dispatch(actions.resetReportTab());
    };
  }, []);
  const langId = useSelector((state) => state.constants.languages);
  const toast = new ToastService();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchDealList(""));
    dispatch(actions.setSearchOfferList(""));
    dispatch(actions.setSearchUserList(""));
  }, []);
  const { t } = useTranslation();

  const [pageNo, setPageNo] = useState(1);
  const [sort, setSort] = useState({});
  const [sortingColumnName, setSortingColumnName] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const [sortingApplied, setSortingApplied] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [formInitialValue, setFormInitialValue] = useState({
    hotel_ids: "",
    booking_date_radio: "",
    stay_date_radio: "",
    booking_date: "",
    arrival_date: "",
    departure_date: "",
    b_s_date: false,
  });
  const [sortOrder, setSortOrder] = useState();
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const bookingReports = useSelector(
    (state) => state?.bookingReports?.result?.records
  );

  useEffect(() => {
    dispatch(actions.getHotelFromHotelGroup(hotelGroupId));
  }, []);

  const hotellist = useSelector(
    (state) => state?.bookingReports?.hotelListforHotelGroup
  );

  useEffect(() => {
    if (hotellist?.length > 0) {
      const formatting = hotellist?.map((hotel) => {
        return { id: hotel?.id, name: hotel?.value };
      });

      setHotelList(formatting);
    }
  }, [hotellist]);

  const isLoading = useSelector((state) => state?.bookingReports?.isLoading);
  const fileReadyForDownload = useSelector(
    (state) => state?.bookingReports?.fileReadyForDownload
  );
  const exportedFile = useSelector(
    (state) => state?.bookingReports?.exportedFile
  );
  useEffect(() => {
    if (fileReadyForDownload) {
      const csvData = new Blob([exportedFile?.data], {
        type: "text/csv;charset=utf-8;",
      });
      FileSaver.saveAs(csvData, `booking_records_${new Date().getTime()}.csv`);
    }
  }, [fileReadyForDownload]);

  const formSchema = yup.object().shape({
    hotel_ids: yup.string().required("Required"),
    b_s_date: yup.boolean().oneOf([true]).required("Required"),
    arrival_date: yup.date().when("stay_date_radio", {
      is: true,
      then: yup.date().required("required"),
    }),
    departure_date: yup.date().when("stay_date_radio", {
      is: true,
      then: yup
        .date()
        .when(
          "arrival_date",
          (arrival_date, schema) => arrival_date && schema.min(arrival_date)
        )
        .required("Required"),
      //then:yup.date().min(yup.ref('arrival_date'),"End data can't be before start date").required("Required")
    }),
    booking_date: yup.string().when("booking_date_radio", {
      is: true,
      then: yup.string().required("Required"),
    }),
  });

  const columnDataMap = {
    Hotel: "hotel_name",
    Currency: "currency_symbol",
    "PMS Confirmation #": "reservation_id",
    "Guest Name": "full_name",
    "EM Booking #": "em_booking_id",
    "EM Book Date": "booking_date",
    "Arrival Date": "start_date",
    "Departure Date": "end_date",
    "Original Room Type Booked": "original_room_type_booked",
    "Room Type Selected": "room_type",
    "Upsell Type": "upsell_type",
    "Room #": "room_name",
    "Room (price)": "upsell",
    Deals: "service_name",
    "Deals (price)": "service_price",
    Offers: "offer_name",
    "Offers (price)": "offer_price",
    Quantity: "quantity",
    "Time of Arrival": "arrival_time",
    "Comments/Requests": "customer_note",
  };

  const sortColumns = [
    t("Modules.Bookings (main page).Hotel"),
    t("Modules.Bookings (main page).Currency"),
    t("Modules.Bookings (main page).PMS Confirmation"),
    t("Modules.Bookings (main page).Guest Name"),
    t("Modules.Bookings (main page).EM Booking"),
    t("Modules.Bookings (main page).EM Book Date"),
    t("Modules.Bookings (main page).Arrival Date"),
    t("Modules.Bookings (main page).Departure Date"),
    t("Modules.Bookings (main page).Original Room Type Booked"),
    t("Modules.Bookings (main page).Room Upsell - Room Type"),
    t("Modules.Bookings (main page).Upsell"),
    t("Modules.Bookings (main page).Room Upsell - Room"),
    t("Modules.Bookings (main page).Room Upsell - Price"),
    t("Modules.Bookings (main page).Deal"),
    t("Modules.Bookings (main page).Deal - Price"),
    t("Modules.Bookings (main page).Special Offers"),
    t("Modules.Bookings (main page).Special Offers - Price"),
    t("Modules.Bookings (main page).Quantity"),
    t("Modules.Bookings (main page).Time of Arrival"),
    t("Modules.Bookings (main page).Comment/Requests"),
  ];

  const count = useSelector(
    (state) => state?.bookingReports?.result?.totalCount
  );

  useEffect(() => {
    setDefaultLanguage(
      langId.filter((option) => option.language.key === i18next.language)[0]
        ?.language?.id
    );
  }, [langId]);

  useEffect(() => {
    if (bookingReports) {
      mapTableInfo(bookingReports);
    }
  }, [bookingReports, defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );

    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };

  const mapTableInfo = (bookingInfo) => {
    const mapper = bookingInfo?.map((booking, index) => {
      const tableHeader = {
        id: booking.id,
      };
      /* If single hotel is there hotel name will not be present */
      if (formInitialValue?.hotel_ids.split(",").length > 1) {
        tableHeader[`${t("Modules.Bookings (main page).Hotel")}`] =
          booking?.hotel_name;
      }
      tableHeader[`${t("Modules.Bookings (main page).Currency")}`] =
        booking?.currency_symbol;
      tableHeader[`${t("Modules.Bookings (main page).PMS Confirmation")}`] =
        booking?.reservation_id;
      tableHeader[`${t("Modules.Bookings (main page).Guest Name")}`] =
        booking?.full_name;
      tableHeader[`${t("Modules.Bookings (main page).EM Booking")}`] =
        booking?.em_booking_id;
      tableHeader[`${t("Modules.Bookings (main page).EM Book Date")}`] =
        Utils.getDDMMYY(booking?.booking_date || "");

      tableHeader[`${t("Modules.Bookings (main page).Arrival Date")}`] =
        Utils.getDDMMYY(booking?.start_date || "");
      tableHeader[`${t("Modules.Bookings (main page).Departure Date")}`] =
        Utils.getDDMMYY(booking?.end_date || "");
      tableHeader[
        `${t("Modules.Bookings (main page).Original Room Type Booked")}`
      ] = booking?.original_room_type_booked;
      tableHeader[
        `${t("Modules.Bookings (main page).Room Upsell - Room Type")}`
      ] = booking?.room_type;
      tableHeader[`${t("Modules.Bookings (main page).Upsell")}`] =
        booking?.upsell_type;
      tableHeader[`${t("Modules.Bookings (main page).Room Upsell - Room")}`] =
        booking?.room_name;
      tableHeader[`${t("Modules.Bookings (main page).Room Upsell - Price")}`] =
        (
          <NumberFormat
            value={parseFloat(booking?.upsell)?.toLocaleString("en", {
              useGrouping: false,
              minimumFractionDigits: 2,
            })}
            displayType={"text"}
            thousandSeparator={true}
            /*   prefix={booking?.currency_symbol} */
          ></NumberFormat>
        );

      
      tableHeader[`${t("Modules.Bookings (main page).Deal")}`] =
        booking?.service_name;
      tableHeader[`${t("Modules.Bookings (main page).Deal - Price")}`] = (
        <NumberFormat
          value={booking?.service_price?.toLocaleString("en", {
            useGrouping: false,
            minimumFractionDigits: 2,
          })}
          displayType={"text"}
          thousandSeparator={true}
          /*  prefix={booking?.currency_symbol} */
        ></NumberFormat>
      );

      tableHeader[`${t("Modules.Bookings (main page).Special Offers")}`] =
        booking?.offer_name;
      tableHeader[
        `${t("Modules.Bookings (main page).Special Offers - Price")}`
      ] = (
        <NumberFormat
          value={booking?.offer_price?.toLocaleString("en", {
            useGrouping: false,
            minimumFractionDigits: 2,
          })}
          displayType={"text"}
          thousandSeparator={true}
          /* prefix={booking?.currency_symbol} */
        ></NumberFormat>
      );

      tableHeader[`${t("Modules.Bookings (main page).Quantity")}`] =
        booking?.quantity;

      tableHeader[`${t("Modules.Bookings (main page).Original Room Type Booked")}`] = booking?.original_room_type_booked

      tableHeader[`${t("Modules.Bookings (main page).Time of Arrival")}`] =
        booking?.arrival_time;

      

      tableHeader[`${t("Modules.Bookings (main page).Comment/Requests")}`] =
        booking?.customer_note;
      return tableHeader;
    });
    setTableMapper(mapper);
  };

  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();

    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    setSortingApplied(!sortingApplied);
  };

  const onSearchEvent = (searchTxt = "") => {
    setPageNo(1);
    setSearchTerm(searchTxt);
  };

  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    selectedLang = defaultSelectedLanguage,
    hotel_ids = null,
    arrivalDate = null,
    departureDate = null,
    bookingDate = null
  ) => {
    const payload = {
      searchKey: searchTerm,
      hotel_id: hotel_ids,
    };

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }

    if (selectedLang) {
      payload.selectedLang = selectedLang;
    }

    if (arrivalDate) {
      payload.startDate = arrivalDate;
    }

    if (departureDate) {
      payload.endDate = departureDate;
    }
    if (bookingDate) {
      payload.booking_date = bookingDate;
    }

    dispatch(
      actions.getHotelBooking(
        payload.page,
        20,
        payload.hotel_id,
        payload.searchKey,
        payload.sortBy,
        payload.sort,
        payload.selectedLang,
        payload.startDate,
        payload.endDate,
        payload.booking_date
      )
    );
  };

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
  };

  useEffect(() => {
    if (formInitialValue.hotel_ids) {
      if (formInitialValue?.b_s_date) {
        if (formInitialValue?.booking_date_radio) {
          getTableInfo(
            searchTerm || "",
            pageNo,
            sortOrder || "",
            columnDataMap[sortingColumnName] || "",
            defaultSelectedLanguage,
            formInitialValue?.hotel_ids,
            null,
            null,
            formInitialValue?.booking_date
          );
        } else if (formInitialValue?.stay_date_radio) {
          getTableInfo(
            searchTerm || "",
            pageNo,
            sortOrder || "",
            columnDataMap[sortingColumnName] || "",
            defaultSelectedLanguage,
            formInitialValue?.hotel_ids,
            formInitialValue?.arrival_date,
            formInitialValue?.departure_date,
            null
          );
        }
      }
    }
  }, [
    formInitialValue,
    pageNo,
    searchTerm,
    sortingApplied,
    defaultSelectedLanguage,
  ]);

  const exportFile = () => {
    if (formInitialValue.hotel_ids) {
      if (formInitialValue?.b_s_date) {
        if (formInitialValue?.booking_date_radio) {
          dispatch(
            actions.getExportedFile(
              pageNo,
              20,
              formInitialValue?.hotel_ids,
              searchTerm || "",
              columnDataMap[sortingColumnName] || "",
              sortOrder || "",
              defaultSelectedLanguage,
              null,
              null,
              formInitialValue?.booking_date
            )
          );
        } else if (formInitialValue?.stay_date_radio) {
          dispatch(
            actions.getExportedFile(
              pageNo,
              20,
              formInitialValue?.hotel_ids,
              searchTerm || "",
              columnDataMap[sortingColumnName] || "",
              sortOrder || "",
              defaultSelectedLanguage,
              formInitialValue?.arrival_date,
              formInitialValue?.departure_date,
              null
            )
          );
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs}></Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3> {t("Modules.Bookings (main page).Bookings")} </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col>
            <Formik
              initialValues={{ ...formInitialValue }}
              validationSchema={formSchema}
              enableReinitialize={true}
              onSubmit={(values, errors) => {
                setFormInitialValue(values);
                setPageNo(1);
              }}
            >
              {({
                handleChange,
                errors,
                values,
                touched,
                submitForm,
                setFieldValue,
              }) => (
                <Form>
                  <Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Label className={"sub-header-text"}>
                            {t("Modules.Bookings (main page).Select hotel(s)")}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <DropDown
                            lang={hotelList}
                            updateform={(event) => {
                              setFieldValue(
                                event?.target?.name,
                                event?.target?.value.toString()
                              );
                            }}
                            inpName={"hotel_ids"}
                            req={true}
                            touched={touched}
                            errors={errors}
                          ></DropDown>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Row>
                        <Col md={3} sm={6} xs={6}>
                          <Form.Group>
                            <Form.Check
                              label={t(
                                "Modules.Define Export Criterias dialog box.Book Date (single day)"
                              )}
                              type="radio"
                              name="b_s_date"
                              className={"sub-header-text"}
                              isInvalid={touched.b_s_date && errors.b_s_date}
                              onChange={(event) => {
                                setFieldValue(
                                  "booking_date_radio",
                                  event.target.checked
                                );
                                setFieldValue(
                                  "stay_date_radio",
                                  !event.target.checked
                                );
                                setFieldValue("b_s_date", true);
                                setFieldValue("booking_date", "");
                              }}
                            ></Form.Check>
                          </Form.Group>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <Form.Group>
                            <Form.Check
                              label={t(
                                "Modules.Define Export Criterias dialog box.Stay Date"
                              )}
                              type="radio"
                              name="b_s_date"
                              className={"sub-header-text"}
                              isInvalid={touched.b_s_date && errors.b_s_date}
                              onChange={(event) => {
                                setFieldValue(
                                  "booking_date_radio",
                                  !event.target.checked
                                );
                                setFieldValue(
                                  "stay_date_radio",
                                  event.target.checked
                                );
                                setFieldValue("b_s_date", true);
                                setFieldValue("arrival_date", "");
                                setFieldValue("departure_date", "");
                              }}
                            ></Form.Check>
                          </Form.Group>
                        </Col>
                      </Row>
                      {values?.b_s_date && (
                        <Row>
                          {values?.booking_date_radio && (
                            <Col md={3} sm={6} xs={6}>
                              <Form.Group>
                                <Form.Label className={"sub-header-text"}>
                                  {t(
                                    "Modules.Define Export Criterias dialog box.Booking Date"
                                  )}{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name="booking_date"
                                  isInvalid={
                                    touched.booking_date && errors.booking_date
                                  }
                                  onChange={handleChange}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          )}
                          {values?.stay_date_radio && (
                            <Row>
                              <Col md={3} sm={6} xs={6}>
                                <Form.Group>
                                  <Form.Label className={"sub-header-text"}>
                                    {t(
                                      "Modules.Define Export Criterias dialog box.Arrival Date"
                                    )}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="arrival_date"
                                    isInvalid={
                                      touched.arrival_date &&
                                      errors.arrival_date
                                    }
                                    onChange={handleChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                              <Col md={3} sm={6} xs={6}>
                                <Form.Group>
                                  <Form.Label className={"sub-header-text"}>
                                    {t(
                                      "Modules.Define Export Criterias dialog box.Departure Date"
                                    )}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="departure_date"
                                    min={values?.arrival_date}
                                    isInvalid={
                                      touched.departure_date &&
                                      errors.departure_date
                                    }
                                    onChange={handleChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      )}
                    </Row>
                    <Row>
                      <Col md={3} sm={3} xs={3}>
                        <Button
                          type="submit"
                          value="Generate"
                          onClick={(event) => {
                            submitForm();
                            event.preventDefault();
                            if (Object.keys(errors).length > 0) {
                              toast.onWarning("Please fill the forms");
                            }
                          }}
                        >
                          {t("Modules.Bookings (main page).Generate")}
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={8} sm={8} xs={8}>
            <SearchComponents
              buttonLabel={"Trouble"}
              addBtn={false}
              search={onSearchEvent}
            ></SearchComponents>
          </Col>
          <Col md={4} sm={4} xs={4} style={{ textAlign: "end" }}>
            <Button
              variant="secondary"
              onClick={() => {
                exportFile();
              }}
              className="btn btn-primary"
            >
              <ExportSVG />
              &nbsp;
              {t("Modules.Bookings (main page).Export")}
            </Button>
          </Col>
        </Row>
        {!isLoading && (
          <Row>
            <Col>
              <BootstrapTable
                sortColumns={sortColumns}
                currentlySorted={sort}
                sortingColumnName={sortingColumnName}
                onSortClick={sortingClick}
                tableMapper={tableMapper}
              />
            </Col>
          </Row>
        )}
        {isLoading && <Spinner />}
        <Row>
          <PaginationComponent
            pageNo={pageNo}
            totalCount={Math.ceil(count / 20) || 1}
            onChange={(number) => {
              updatePagination(number);
            }}
          ></PaginationComponent>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default BookingReport;
