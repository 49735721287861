import axios from "../axiosInstance";
import { Utils } from "../../utils/utils";

class HotelAddRoom{
    fetchCreateHotelRoom =async (payload)=>{
        try {
            const formDataPayload = Utils.appendData(payload);
            const response = await axios.post("/room", formDataPayload, { headers: { "Content-Type": "multipart/form-data" }});
            return response;
          } catch (error) {
            throw error;
          }
            
    }
    fetchRoomDetails = async (payload,defaultSelectedLanguage)=>{
      try { 
        const response = await axios.get(`/room/${payload}?language_id=${defaultSelectedLanguage}`)
        return response
      } catch (error) {
        throw error
      }
    }

    fetchGetRoomDetails = async (pageNo, limit, hotelId, searchKey, sortBy, sortColumnName, floorId, defaultSelectedLanguage)=>{
      try { 
        const response = await axios.get(`/rooms?hotel_id=${hotelId}&limit=${limit}&page=${pageNo}&searchKey=${searchKey}&sortBy=${sortBy}&sort=${sortColumnName}&floor_id=${floorId}&language_id=${defaultSelectedLanguage}`)
        return response
      } catch (error) {
        throw error
      }
    }
    
    fetchupdateRoom =async (payload, id)=>{
      try {
          const formDataPayload = Utils.appendData(payload);
          const response = await axios.put(`/room/${id}`, formDataPayload, { headers: { "Content-Type": "multipart/form-data" }});
          return response;
        } catch (error) {
          throw error;
        }
          
  }
    
}   

export default HotelAddRoom