import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DragDrop from "../Common/DragDrop";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import { OverlayTrigger, Row, Col, Tooltip } from "react-bootstrap";
import catImg from "../Common/overlayImages/catImg.jpg"

export const RoomPictures = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="header">{t("Modules.Rooms List.Pictures")}</div>
      &nbsp;
      <div className="sub-header-text">
        {t("Modules.Rooms List.Pictures Description")}
        <OverlayTrigger
          key="right"
          placement="top-end"
          overlay={
            <Tooltip id="tooltip-right">
              <Row>
                <Col md={3} >
                  {t("Modules.Rooms List.Pictures - Question mark")}
                </Col>
                <Col>
                    <img src={catImg} className={'tooltip-image'} alt={'greeting-overview'} ></img>
                </Col>
              </Row>
              
            </Tooltip>
          }
        >
          <QuestionIcon  className="pointer" />
        </OverlayTrigger>
      </div>
      <br></br>
      <DragDrop 
          maximumFile={props.maximumFile}
          multiUpload={props.multi}
          handleChange = {props.change}
          values={props.values}
          path={props.path}
      ></DragDrop>
      <div>
          <div style={{fontSize:'small'}}>{t("Common Translations.Picture Validation")}</div>
          
      </div>
    </React.Fragment>
  );
};

export default RoomPictures;
