import axios from "../axiosInstance";
// import {FormData} from 'form-data';
import { Utils } from './../../utils/utils';

class HotelService {
  getHotelBasicDetails = async (hotelId) => {
    try {
      const response = await axios.get(`/hotel/${hotelId}/basic_information`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getHotelConfigurationDetails = async (hotelId) => {
    try {
      const response = await axios.get(`/hotel/${hotelId}/configuration`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getSearchedTags = async (hotelId,searchKey, defaultSelectedLanguage) => {
    try {
      const response = await axios.get(`/hotel/${hotelId}/tags?searchKey=${searchKey}&language_id=${defaultSelectedLanguage}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  

  getHotelTags = async (payload) => {
    try {
      const { hotelId, searchTerm = "" } = payload;
      const response = await axios.get(
        `/hotel/${hotelId}/tags?searchKey=${searchTerm}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  removeHotelTag = async (payload) => {
    try {
      const response = await axios.delete("tag", { data: payload });
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateHotelDetail = async (payload, hotelId) => {
    try {
      const bodyFormData = Utils.appendData(payload);
      const response = await axios.put(
        `hotel/${hotelId}/basic_information`,
        bodyFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  addHotelTag = async (payload) => {
    try {
      // dummy payload
      // {
      //     "name": "Standard",
      //     "hotel_id": 42,
      //     "created_by": 55,
      //     "updated_by": 55
      // }
      const response = await axios.post("/tag", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default HotelService;
