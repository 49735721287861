import axios from "../servicesInstance";

class SeasonPricingService {
  getSeasonPricing = async (payload) => {
    try {
      const params = {
        ...payload,
      };
      const response = await axios.get(`/seasons`, { params });
      return response;
    } catch (error) {
      throw error;
    }
  };

  getSeasonDetail = async (seasonId) => {
    try {
      const response = await axios.get(`/season/${seasonId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  createHotelSeason = async (payload) => {
    try {
      const response = await axios.post("/season", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  editHotelSeason = async (payload) => {
    try {
      const seasonId = payload.seasonId;
      delete payload.seasonId;
      const response = await axios.put(
        `/season/${seasonId}`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default SeasonPricingService;
