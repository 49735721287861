import actions from "../actions/actions";

const initialState = {
  data: false,
  isLoading: false,
};

export const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.API_FAILED: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case actions.API_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case actions.API_WARNING: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case actions.API_FETCHING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
        return state;
    }
  }
};
