import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import actions from '../../../store/actions/actions';
import expectmelogo from '../icons/expectmelogo.svg';
import Logo from '../SidebarSvg/Logo.svg';
import { AuthHeader } from "./AuthHeader";

const ResetPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const email_id = useSelector((state) => state?.login?.emailId);
    const isConfirmApiSuccess = useSelector((state) => state.network.isLoading);
    const languages = useSelector((state) => state?.hotelLanguageSettings?.languages);
    const [availableFilteredLanguages, setAvailableFilteredLanguages] = useState([]);
    const { t, i18n } = useTranslation();
    const formInitialValue = {
        verificationCode: "",
        password: "",
        confirm_password: ""
    };
    const formSchema = yup.object().shape({
        verificationCode: yup.string().required('Required'),
        password: yup.string().required('Required'),
        confirm_password: yup.string().required(" ").when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], `${t("Modules.Reset Password.password-verification-message")}`)
        })
    });
    const resetPassword = (payload) => {
        if (email_id) {
            payload['email'] = email_id
            dispatch(actions.confirmPassword(payload));
        }
    }
    useEffect(() => {
        if (isConfirmApiSuccess) {
            history.push("/login");
        }
    }, [isConfirmApiSuccess])
    useEffect(() => {
        dispatch(actions.getLanguage());
    }, []);
    useEffect(() => {
        if (languages && languages?.length) {
            setAvailableFilteredLanguages(languages)
        }
    }, [languages]);
    const backToLogin = () => {
        history.push("/login");
    }



    return (
        <React.Fragment>
            <Formik initialValues={{
                ...formInitialValue,
            }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values
                    }
                    resetPassword(payload)

                }}
            >
                {({
                    handleChange,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <Row className="noMargin">
                            <Col md={6} sm={12} xs={12} className="loginleft">
                                <div className="logo">
                                    <img src={expectmelogo} />
                                </div>
                                <AuthHeader availableFilteredLanguages={availableFilteredLanguages} />
                                <div className="dashborad-login-form">
                                    <div>
                                        <span className="welcometext">{t("Modules.Reset Password.Reset Password")}</span>
                                    </div>
                                    <BootstrapForm.Group>
                                        <BootstrapForm.Label>{t("Modules.Reset Password.VERIFICATION CODE")} <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type="text"
                                            name="verificationCode"
                                            onChange={handleChange} isInvalid={touched?.verificationCode && errors?.verificationCode} value={values?.verificationCode}
                                        ></BootstrapForm.Control>
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group className="mt-2">
                                        <BootstrapForm.Label>{t("Modules.Reset Password.NEW PASSWORD")} <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type="password"
                                            name="password"
                                            onChange={handleChange} isInvalid={touched?.password && errors?.password} value={values?.password}
                                        ></BootstrapForm.Control>
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group className="mt-2">
                                        <BootstrapForm.Label>{t("Modules.Reset Password.CONFIRM PASSWORD")} <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type="password"
                                            name="confirm_password"
                                            onChange={handleChange} isInvalid={touched?.confirm_password && errors?.confirm_password} value={values?.confirm_password}
                                        ></BootstrapForm.Control>
                                        <span style={{ color: "red" }}>{touched?.confirm_password ? errors?.confirm_password : ''}</span>
                                    </BootstrapForm.Group>
                                    <Row className="mt-3">
                                        <Col md={6} sm={6} xl={6}>
                                            <Button variant="primary" type="submit">{t("Modules.Reset Password.Save")}</Button>
                                        </Col>
                                        <Col md={6} sm={6} xl={6}>
                                            <Button onClick={() => { history.goBack() }} variant="secondary">{t("Common Translations.Back")}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col className="login-right-panel noPadding">
                                <div className="background-login">

                                </div>
                                <div className="dashboard-header-login">
                                    <img src={Logo} />
                                </div>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </React.Fragment>

    )
}

export default ResetPassword;