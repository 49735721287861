import { call, put, takeLatest } from "redux-saga/effects";
import HotelBookingReport from "../../services/BookingReport";
import CommonService from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import actions from "../actions/actions";

const hotelBookingReport = new HotelBookingReport();
const commonService = new CommonService()
const toast = new ToastService()
const getBookingReport = async (
  pageNo,
  limit,
  hotelId,
  searchKey,
  sortBy,
  sort,
  languageId,
  startDate,
  endDate,
  booking_date
) => {
  try {
    const response = await hotelBookingReport.fetchBookingReport(
      pageNo,
      limit,
      hotelId,
      searchKey,
      sortBy,
      sort,
      languageId,
      startDate,
      endDate,
      booking_date
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getExportedReportFile = async (
  pageNo,
  limit,
  hotelId,
  searchKey,
  sortBy,
  sort,
  languageId,
  startDate,
  endDate,
  booking_date
) => {
  try {
    const response = await hotelBookingReport.fetchExportedFile(
      pageNo,
      limit,
      hotelId,
      searchKey,
      sortBy,
      sort,
      languageId,
      startDate,
      endDate,
      booking_date
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getHotelList = async (hotelGroupId) => {
  try {
    const response = await commonService.fetchHotelList(hotelGroupId)
    return response
  } catch (error) {
    throw error
  }
}

function* dispatchGetReport(action) {
  try {
    const response = yield call(
      getBookingReport,
      action.pageNo,
      action.limit,
      action.hotelId,
      action.searchKey,
      action.sortBy,
      action.sort,
      action.languageId,
      action.startDate,
      action.endDate,
      action.booking_date
    );
    if (response.data.success) {
      toast.onSuccess(response?.data?.msg)
      yield put({
        type: actions.GET_HOTEL_BOOKING_SUCCESS,
        msg: response?.data?.msg,
        result: response?.data?.data,
      });
    } else {
      // toast.onFailure(response?.data?.msg)
      yield put({
        type: actions.GET_HOTEL_BOOKING_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    // toast.onFailure(error || error?.response)
    yield put({
      type: actions.GET_HOTEL_BOOKING_FAILED,
      data: error || error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchGetExportedFile(action) {
  try {
    const response = yield call(
      getExportedReportFile,
      action.pageNo,
      action.limit,
      action.hotelId,
      action.searchKey,
      action.sortBy,
      action.sort,
      action.languageId,
      action.startDate,
      action.endDate,
      action.booking_date
    );
    
      toast.onSuccess("Downloading")
      yield put({
        type: actions.GET_EXPORT_FILE_SUCCESS,
        msg: "Downloading",
        result: response,
      });
    
  } catch (error) {
    // toast.onFailure(error || error?.response)
    yield put({
      type: actions.GET_EXPORT_FILE_FAILED,
      data: error || error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchGetHotelFromHotelGrop(action) {
  try {
    const response = yield call(
      getHotelList,
      action.hotelGroupId,
      
    );
    if (response.data.success) {
      
      yield put({
        type: actions.GET_HOTEL_LIST_HOTELGROUP_SUCCESS,
        msg: response?.data?.msg,
        result: response?.data?.data,
      });
    } else {
      // toast.onFailure(response?.data?.msg)
      yield put({
        type: actions.GET_HOTEL_LIST_HOTELGROUP_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    // toast.onFailure(error || error?.response)
    yield put({
      type: actions.GET_HOTEL_LIST_HOTELGROUP_FAILED,
      data: error || error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

export function* listenGetReports() {
  yield takeLatest(actions.GET_HOTEL_BOOKING, dispatchGetReport);
}

export function* listenGetExportedFile() {
  yield takeLatest(actions.GET_EXPORT_FILE, dispatchGetExportedFile);
}


export function* listenGetHotelFromHotelGroup() {
  yield takeLatest(actions.GET_HOTEL_LIST_HOTELGROUP, dispatchGetHotelFromHotelGrop);
}
