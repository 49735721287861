import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import BookingReport from "../components/BookingReport/BookingReport";
import SetPassword from "../components/Common/Login/SetPassword";
import ContactUs from "../components/ContactUs/ContactUs";
import Dashboard from "../components/Dashboard";
import EmailSettings from "../components/EmailSettings/EmailSettings";
import { AddExtraServices } from "../components/ExtraServices/AddExtraServices";
import { EditExtraService } from "../components/ExtraServices/EditExtraService";
import { FloorConfiguration } from "../components/Floor/FloorConfiguration";
import { ConfigureHotel } from "../components/HotelManagment/ConfigureHotel";
import { DealsAndOffer } from "../components/HotelManagment/DealsAndOffer";
import HotelManagement from "../components/HotelManagment/HotelManagement";
import { HotelRoomCategories } from "../components/HotelManagment/HotelRoomCategories";
import { HotelRoomList } from "../components/HotelManagment/HotelRoomList";
import { HotelRoomPricing } from "../components/HotelManagment/HotelRoomPricing";
import TabsLayout from "../components/HotelManagment/TabsLayout";
import AddRoom from "../components/NewRoom/AddRoom";
import UpdateRoom from "../components/NewRoom/updateRoom";
import { AddRoomPricing } from "../components/RoomSeasonPricing/AddRoomSeasonPricing";
import { AddSpecialOffres } from "../components/SpecialOffres/AddSpecialOffers";
import { EditSpecialOffer } from "../components/SpecialOffres/EditSpecialOffers";
import CreateUser from "../components/User/CreateUser";
import EditUser from "../components/User/EditUser";
import UpdateUser from "../components/User/UpdateUser";
import UserList from "../components/User/UserList";
import { StorageService } from "../services/storage.service";
import { ToastService } from "../services/toast.service";
import { SetupHotelId } from "./../components/Common/SetHotelId";
import ConfigurationForm from "./../components/Configuration/ConfigurationForm";
import { AddFloor } from "./../components/Floor/AddFloor";
import { EditFloor } from "./../components/Floor/EditFloor";
import { FloorList } from "./../components/Floor/FloorList";
import { HotelTranslation } from "./../components/HotelLangaugeSettings/HotelTranslation";
import { AddRoomCategories } from "./../components/RoomCategories/AddRoomCategories";
import { EditRoomCategory } from "./../components/RoomCategories/EditRoomCategory";
import { EditRoomPricing } from "./../components/RoomSeasonPricing/EditRoomSeasonPricing";
export const PrivateRoute = ({ recentPath = null }) => {
  const toast = new ToastService();
  const history = useHistory();
  const storageService = new StorageService();
  const sessionToken = storageService.getItem("session-token");
  const lastRoute = () => {
    const needToRedirectRoutes = ["/", "/login", "/set-password", "/reset-password", "/forget-password"];
    if (sessionToken && sessionToken.length) {
      return "/set-password"
    }
    else {
      return recentPath &&
      recentPath.length &&
      !needToRedirectRoutes.includes(recentPath)
      ? recentPath
      : "/home";
      // if (Utils.isAccessAllowedByRoute(recentPath)) {
       
      // }
      // else{
      //   // toast.onFailure("Unauthorized Access");
      //   // return "/home"
      // }

    }

  };

  return (
    <>
      <Route exact path="/home" component={Dashboard}></Route>
      <Route exact path="/hotel-management" component={HotelManagement}></Route>
      <Route exact path="/user/create" component={CreateUser}></Route>
      <Route exact path="/user/list" component={UserList}></Route>
      <Route
        exact
        path="/bookings"
        component={BookingReport}
      ></Route>
      <Route exact path="/user/profile" component={UpdateUser}></Route>
      <Route exact path="/update-user/:id" component={EditUser}></Route>
      <Route exact path="/hotel-floor/create" component={AddFloor}></Route>
      <Route exact path="/hotel-floor/edit/:id" component={EditFloor}></Route>
      <Route exact path="/set-password" component={SetPassword}></Route>
      <Route
        exact
        path="/hotel-room-categories/edit/:id"
        component={EditRoomCategory}
      ></Route>
      <Route
        exact
        path="/special-offers/edit/:id"
        component={EditSpecialOffer}
      ></Route>
      <Route
        exact
        path="/extra-service/edit/:id"
        component={EditExtraService}
      ></Route>
      <Route exact path="/add-room" component={AddRoom}></Route>
      <Route exact path="/update-room/:roomId" component={UpdateRoom}></Route>
      <Route
        exact
        path="/hotel-room-pricing/create"
        component={AddRoomPricing}
      ></Route>
      <Route
        exact
        path="/hotel-room-pricing/edit/:id"
        component={EditRoomPricing}
      ></Route>
      <Route
        exact
        path="/hotel-room-categories/create"
        component={AddRoomCategories}
      ></Route>

      <Route
        exact
        path="/special-offers/create"
        component={AddSpecialOffres}
      ></Route>
      <Route
        exact
        path="/extra-service/create"
        component={AddExtraServices}
      ></Route>
      <Route exact path="/setup-hotel-id" component={SetupHotelId}></Route>
      <Route exact path="/assign-room/:floor_id/:room_id" component={FloorConfiguration}></Route>
      <Route 
        exact
        path="/contact-us"
        component={ContactUs}
      ></Route>
      {/* pages which contains tab on top */}
      <TabsLayout>
        <Route
          exact
          path="/hotel-configuration"
          component={ConfigureHotel}
        ></Route>
        <Route exact path="/hotel-floor/list" component={FloorList}></Route>
        <Route
          exact
          path="/hotel-room-categories"
          component={HotelRoomCategories}
        ></Route>
        <Route
          exact
          path="/email-settings"
          component={EmailSettings}
        ></Route>
        <Route
          exact
          path="/hotel-room-pricing"
          component={HotelRoomPricing}
        ></Route>
        <Route exact path="/hotel-room-list" component={HotelRoomList}></Route>
        <Route exact path="/deals-and-offer" component={DealsAndOffer}></Route>
        <Route
          exact
          path="/hotel-translation"
          component={HotelTranslation}
        ></Route>
          <Route
          exact
          path="/configuration"
          component={ConfigurationForm}
        ></Route>

      </TabsLayout>
      <Redirect to={lastRoute()}></Redirect>
    </>
  );
};
