import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StorageService } from "./../../services/storage.service";
import Breadcrumbs from "./../Common/Breadcrumbs";
import { FloorForm } from "./FloorForm";

export const AddFloor = () => {
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-configuration", title: hotelName ? hotelName : "" },
    { path: "/hotel-floor/list", title: "Floor List" },
    { path: "/hotel-floor/create", title: "Add new floor" },
  ];
  const { t } = useTranslation();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3> {t("Modules.Floor list (+ add new).Add a new floor plan")} </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <FloorForm isForEdit={false} hotelId={hotelId} />
      </div>
    </React.Fragment>
  );
};
