import { ToastService } from "../../services/toast.service"
import actions from "./../actions/actions"

const initialState = {
    result: false,
    tags:[],
    msg: false,
    page: 1,
    limit: 10,
    success: false,
    isLoading: false,
    created:false,
    updated:false
}

export const HotelRoomReducer = (state=initialState, action)=>{
    const toast = new ToastService()
    switch (action.type){

        case actions.CREATE_HOTEL_ROOOM:case actions.GET_ROOM_DETAIL: case actions.GET_ROOM_LIST: case actions.UPDATE_HOTEL_ROOOM:{
            return{
                ...state,
                isLoading:true,
                result: false,
                created:false,
                success: false,
                updated:false,
            }
        }

        case actions.CREATE_HOTEL_ROOOM_SUCCESS:{
            return{
                ...state,
                result: action?.result?.data,
                msg: action?.result?.msg,
                created:true,
                success: true,
                updated:false,
                isLoading: false
            }
        }

        case actions.CREATE_HOTEL_ROOOM_FAILED:{
            toast.onFailure(action?.result?.msg)
            return{
                ...state,
                result: false,
                msg: action?.result?.msg,
                created:false,
                success: true,
                updated:false,
                isLoading: false
            }
        }
        case actions.GET_ROOM_DETAIL_SUCCESS:{
            
            return{
                ...state,
                page:state.pageNo,
                result: action?.result,
                msg: action?.msg,
                success: true,
                isLoading: false,
                updated:false,
                created:false
            }
        }

        case actions.GET_ROOM_DETAIL_FAILED:{
            toast.onFailure(action?.msg)
            return{
                ...state,
                result: false,
                msg: action?.msg,
                success: false,
                isLoading: false,
                updated:false,
                created:false
            }
        }

        case actions.GET_ROOM_LIST_SUCCESS:{
            return{
                ...state,
                result:action?.result,
                msg:action.msg,
                isLoading:false,
                updated:false,
                created:false
            }
        }
       case actions.GET_ROOM_LIST_FAILED:{
            // toast.onFailure(action?.msg)
            return{
                ...state,
                result:false,
                msg:action.msg,
                isLoading:false,
                updated:false,
                created:false
            }
        }

        case actions.UPDATE_HOTEL_ROOOM_SUCCESS:{
            return{
                ...state,
                result: action?.result?.data,
                msg: action?.result?.msg,
                updated:true,
                success: true,
                isLoading: false,
                created:false
            }
        }

        case actions.UPDATE_HOTEL_ROOOM_FAILED:{
            // toast.onFailure(action?.result?.msg)
            return{
                ...state,
                result: false,
                msg: action?.result?.msg,
                updated:false,
                success: true,
                isLoading: false,
                created:false
            }
        }
          
        case actions.CLEAR_ROOM_LIST_STATE:{
            return initialState
        }
        default:{
            return state
            
        }
    }
}
