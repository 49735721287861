import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "./../../services/storage.service";
import { Utils } from "./../../utils/utils";
import { TranslationTabs } from "./../Translation/TranslationTabs";

export const HotelTranslation = () => {
  const { t } = useTranslation();
  const [allowAccess, setAllowAccess] = useState(true);

  const storageService = new StorageService();
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const languageArray = availableFilteredLanguages.map((languageInfo) => {
    return languageInfo?.language?.code;
  });
  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create))

  }, []);
  return (
    <React.Fragment>
      <div className="content mt-3">
        <div className="sub-header">
          {t("Modules.Translations.Language settings")}
        </div>
        <div className="sub-header-text">
          {t(
            "Modules.Translations.Language settings Description"
          )}
        </div>
        <div>
          {languageArray && languageArray.length ? (
            <div className="mt-3">
              <strong>
                {t(
                  "Modules.Translations.Language selected"
                )}
              </strong>
              <div className="sub-header-text">{languageArray?.toString()}</div>
            </div>
          ) : (
            ""
          )}
          <hr className="border-line" />
        </div>
        <div className="mt-3">
          <TranslationTabs />
        </div>
        {/* <div className="mt-3">
          <HotelTranslationForm />
        </div> */}
      </div>
    </React.Fragment>
  );
};
