import axios from "../axiosInstance";
import { Utils } from "./../../utils/utils";

class HotelFloorService {
  getFloors = async (payload) => {
    try {
      const params = {
        ...payload,
      };
      const response = await axios.get(`floors`, { params });
      return response;
    } catch (error) {
      throw error;
    }
  };

  getFloorDetail = async (floorId) => {
    try {
      const response = await axios.get(`floor/${floorId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  createHotelFloor = async (payload) => {
    try {
      const formDataPayload = Utils.appendData(payload);
      const response = await axios.post("/floor", formDataPayload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  editHotelFloor = async (payload) => {
    try {
      const floorId = payload.floorId;
      delete payload.floorId;
      const formDataPayload = Utils.appendData(payload);
      const response = await axios.put(`/floor/${floorId}`, formDataPayload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  assignRoom = async (floorId,payload) => {
    try {
      const response = await axios.post(`/floor/${floorId}/assign-room`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getFloorName = async (hotelId) => {
    try {
     
      const response = await axios.get(`floor/list?hotel_id=${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateHotelFloorAssignment = async (payload) => {
    try {
      const response = await axios.patch(`/room/assign_floor`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
}


export default HotelFloorService;
