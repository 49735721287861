import toast from "react-hot-toast";
import { TOAST_CONFIG } from "./../helper/constants";

export class ToastService {
  onSuccess = (msg = "") => {
    toast.success(msg, { ...TOAST_CONFIG.default });
  };

  onFailure = (msg = "Something went wrong, try again later.") => {
    toast.error(msg, { ...TOAST_CONFIG.default });
  };

  onWarning = (msg = "") => {
    toast(msg, {
      ...TOAST_CONFIG.default,
      icon: "⚠️",
    });
  };
}

export default ToastService;
