import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../actions/actions";
import HotelRoomService from "./../../services/Hotel/hotelAddRoom";

const hotelroomservice = new HotelRoomService()
const columnMapForReducer = {
  "name": "ROOM NAME",
  "pms_room_number": "ROOM NUMBER",
  "floor": "FLOOR",
  "category": "ROOM CATEGORY",
  "active":"STATUS"
}
const postCreateHotelRoom = async (data) => {
  try {
    const response = await hotelroomservice.fetchCreateHotelRoom(data);

    return response;
  } catch (err) {
    throw err;
  }
};
const getRoomDetails = async (data, defaultSelectedLanguage) => {
  try {
    const response = await hotelroomservice.fetchRoomDetails(data, defaultSelectedLanguage);

    return response;
  } catch (err) {
    throw err;
  }
};

const getRoomList = async (pageNo, limit, hotelId, searchKey, sortBy, sortColumnName, floorId, defaultSelectedLanguage) => {
  try {
    const response = await hotelroomservice.fetchGetRoomDetails(pageNo, limit, hotelId, searchKey, sortBy, sortColumnName, floorId, defaultSelectedLanguage);

    return response;
  } catch (err) {
    throw err;
  }
};

const putupdateRoom = async (data, id) => {
  try {
    const response = await hotelroomservice.fetchupdateRoom(data, id);

    return response;
  } catch (err) {
    throw err;
  }
};




function* dispatchCreateHotelRoom(action) {
  try {
    // calling same API to get update tags only

    const response = yield call(postCreateHotelRoom, action.data);

    if (response.data.success) {

      yield put({
        type: actions.CREATE_HOTEL_ROOOM_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.CREATE_HOTEL_ROOOM_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchupdateRoom(action) {
  try {
    // calling same API to get update tags only
    const response = yield call(putupdateRoom, action.data, action.id);
    if (response.data.success) {

      yield put({
        type: actions.UPDATE_HOTEL_ROOOM_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.UPDATE_HOTEL_ROOOM_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_HOTEL_ROOOM_FAILED,
      data: error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetRoomDetails(action) {

  try {
    // calling same API to get update tags only
    const response = yield call(getRoomDetails, action.room_id, action.defaultSelectedLanguage);
    if (response.data.success) {
      yield put({
        type: actions.GET_ROOM_DETAIL_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.GET_ROOM_DETAIL_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_ROOM_DETAIL_FAILED,
      data: error || error?.response,
    });
    yield put({
      type: actions.UPDATE_HOTEL_ROOOM_FAILED,
      data: error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetRoomList(action) {

  try {
    // calling same API to get update tags only
    const response = yield call(
      getRoomList,
      action.pageNo,
      action.limit,
      action.hotel_id,
      action.searchKey,
      action.sortBy,
      action.sortColumnName,
      action.floorId,
      action.defaultSelectedLanguage
    );
    if (response.data.success) {
      yield put({
        type: actions.GET_ROOM_LIST_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({
        type: actions.SET_ROOM_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_ROOM_LIST_SORT,
        sortBy: action.sortBy,
        sortName: action.sortColumnName,
        roomSortColumnName: columnMapForReducer[action.sortColumnName] ? columnMapForReducer[action.sortColumnName] : "ROOM NAME",
        sort: action.sortBy === "DESC" ? true : false
      });
    } else {
      yield put({
        type: actions.GET_ROOM_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_ROOM_LIST_FAILED,
      data: error || error?.response,
    });
    yield put({
      type: actions.UPDATE_HOTEL_ROOOM_FAILED,
      data: error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

export function* createHotelRoom() {
  yield takeLatest(actions.CREATE_HOTEL_ROOOM, dispatchCreateHotelRoom);
}

export function* fetchGetRoomDetails() {

  yield takeLatest(actions.GET_ROOM_DETAIL, dispatchGetRoomDetails);
}

export function* listenfetchRoomList() {

  yield takeLatest(actions.GET_ROOM_LIST, dispatchGetRoomList);
}

export function* listenupdateRoom() {
  yield takeLatest(actions.UPDATE_HOTEL_ROOOM, dispatchupdateRoom);
}
