import axios from "../authInstance";
import { Utils } from './../../utils/utils';

class UserManagement {
    getUsers = async(payload)=>{
        try {
            const params = {
                ...payload,
              };
            const response = await axios.get('/users',{ params });
            return response;
        }catch (error) {
            throw error;
        }
    }
    createUser = async(payload)=>{
        try {
            const response = await axios.post('/create-user',payload);
            return response;
        }catch (error) {
            throw error;
        }
    }
    UpdateUser = async(userId,payload)=>{
        try {
            const response = await axios.put(`/update-user/${userId}`,payload);
            return response;
        }catch (error) {
            throw error;
        }
    }
    getUserProfile = async(payload)=>{
        try {
            const response = await axios.post(`/profile/`,payload);
            return response;
        }catch (error) {
            throw error;
        }
    }
    updateUserProfile = async(payload)=>{
      
        const bodyFormData = Utils.appendDataServiceAndOffers(payload);
        try {
            const response = await axios.put(`/update-profile/`,bodyFormData);
            return response;
        }catch (error) {
            throw error;
        }
    }
    getUserById = async(userId)=>{
        try {
            const response = await axios.get(`/user/${userId}`);
            return response;
        }catch (error) {
            throw error;
        }
    }
}

export default UserManagement;