import axios from "../axiosInstance";
import { Utils } from './../../utils/utils';

class RoomCategoriesService {
    getRoomCategories = async (hotelId) => {
        try {
            const response = await axios.get(`/room-categories?hotel_id=${hotelId}`);
            return response;
        } catch (error) {
            throw error
        }
    }
    getPMSRoomsTypes = async (hotelId) => {
        try {
            const response = await axios.get(`/pms/categories/list?hotel_id=${hotelId}`);
            return response;
        } catch (error) {
            throw error
        }
    }
    addRoomCategories = async (payload) => {
        const bodyFormData = Utils.appendData(payload);
        try {
            const response = await axios.post(
                `/room-category`,
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return response;
        } catch (error) {
            throw error
        }
    }
    getRoomCategoryById = async (categoryId,hotelId) => {
        try {
            const response = await axios.get(`/room-category/${categoryId}?hotel_id=${hotelId}`);
            return response;
        } catch (error) {
            throw error
        }
    }
    updateRoomCategory = async (categoryId,payload) => {
        const bodyFormData = Utils.appendData(payload);
        try {
            const response = await axios.put(
                `/room-category/${categoryId}`,
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return response;
        } catch (error) {
            throw error
        }
    }
    sortCategories = async (hotelId,data) => {
        try {
            const response = await axios.patch(`/room-categories/sort?hotel_id=${hotelId}`,data);
            return response;
        } catch (error) {
            throw error
        }
    }
}

export default RoomCategoriesService;