import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";
import PaginationComponent from "./../Common/PaginationComponent";

export const CategoryTranslation = ({
  data,
  languageIds,
  languageNames,
  hotelId,
  filter,
  totalCount,
  onPageChange,
  pageNo,
}) => {
  const defaultLimit = 10;
  const dispatch = useDispatch();
  const [categoryTranslation, setCategoryTranslationData] = useState([]);
  const [categoryEditorData, setCategoryEditorData] = useState({
    translation: [],
  });
  const [forHeader, setForHeader] = useState([
    {
      header: "Name",
    },
    {
      header: "Field",
    },
  ]);
  const { t } = useTranslation();
  const formikRef = useRef();

  const formInitialValue = {
    translation: categoryTranslation,
  };
  const [allowAccess, setAllowAccess] = useState(true);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const finalData = Utils.refactorCategoryTranslationData(
        data,
        "category_ts"
      );
      const filteredData = Utils.filterCategoryTranslation(
        finalData,
        [...languageIds],
        "Category name",
        "Description"
      );
      setCategoryTranslationData(filteredData);
      setCategoryEditorData({
        translation: filteredData,
      });
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);

  const updateCategoryTranslation = (formValue) => {
    const categoryData = formValue;
    categoryData.forEach((categoryInfo) => {
      delete categoryInfo.descriptionLabel;
      delete categoryInfo.nameLabel;
      delete categoryInfo.name;
      categoryInfo.category_ts = categoryInfo.fields;
      // delete categoryInfo.fields;
      categoryInfo.hotelId = hotelId;
    });
    dispatch(
      actions.updateTranslation(
        { category: categoryData },
        hotelId,
        filter,
        pageNo,
        defaultLimit
      )
    );
  };

  const updatePagination = (pageNo) => {
    onPageChange({ page: +pageNo });
  };

  return (
    <div className="row-vertical-scroll">
      <Row className="mb-3">
        {forHeader?.map((headerInfo, index) => (
          <Col key={`category-header-${index}`}>
            <strong>{headerInfo?.header}</strong>
          </Col>
        ))}
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={{ ...formInitialValue }}
        onSubmit={(values) => {
          const formValue = categoryEditorData?.translation;
          updateCategoryTranslation(formValue);
        }}
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <>
            <FieldArray name="translations">
              {() => {
                const translationArray = values?.translation;
                return (
                  <>
                    {translationArray && translationArray.length ? (
                      <>
                        <Row>
                          {translationArray?.map((categoryInfo, index) => (
                            <React.Fragment key={`category-form-${index}`}>
                              <Col
                                key={`category-name-${index}`}
                                className="full-wrap"
                              >
                                <bootstrapForm.Control
                                  key={`season-label-form-${index}`}
                                  className="mt-3 disable-form"
                                  disabled={true}
                                  value={categoryInfo?.name || "NA"}
                                />
                              </Col>
                              <Col key={`category-form-${index}`}>
                                <div key={`category-form-div-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    value={categoryInfo.nameLabel}
                                    disabled={true}
                                    className="mt-3"
                                  />
                                </div>
                                <div key={`category-form-div-2-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    disabled={true}
                                    value={categoryInfo.descriptionLabel}
                                    className="mt-3"
                                  />
                                </div>
                              </Col>
                              {categoryInfo?.fields.map(
                                (translationValue, tagIndex) => (
                                  <Col
                                    key={`category-field-${index}-${tagIndex}`}
                                  >
                                    <div
                                      key={`category-field-div-${index}-${tagIndex}`}
                                    >
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.name}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.name`}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.trim().length > 0
                                          ) {
                                            categoryEditorData.translation[
                                              index
                                            ].fields[tagIndex].name =
                                              event.target.value;
                                            handleChange(event);
                                          } else if (
                                            event.target.value.trim().length < 1
                                          ) {
                                            categoryEditorData.translation[
                                              index
                                            ].fields[tagIndex].name = "";
                                            setFieldValue(
                                              `translation.${index}.fields.${tagIndex}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      key={`category-field-div-2-${index}-${tagIndex}`}
                                    >
                                      {/* <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.description}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.description`}
                                        onChange={handleChange}
                                      /> */}
                                      <div className="mt-3">
                                        <CKEditorComponent
                                          editorState={
                                            translationValue?.description
                                          }
                                          newContent={
                                            translationValue?.description
                                          }
                                          setEditorState={(updateContent) => {
                                            if (
                                              !(
                                                index in
                                                categoryEditorData.translation
                                              )
                                            ) {
                                              categoryEditorData.translation[
                                                index
                                              ] = {
                                                fields: [],
                                              };
                                              categoryEditorData.translation[
                                                index
                                              ].fields[tagIndex] = {};
                                            }

                                            categoryEditorData.translation[
                                              index
                                            ].fields[tagIndex].description =
                                              updateContent;

                                            // setFieldValue(
                                            //   `translation.${index}.fields.${tagIndex}.description`,
                                            //   updateContent
                                            // );
                                            // updateEditorState(
                                            //   updateContent,
                                            //   setFieldValue
                                            // );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                )
                              )}
                              <span className="mt-2 mb-2">
                                <hr />
                              </span>
                            </React.Fragment>
                          ))}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            </FieldArray>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={+pageNo}
                  totalCount={Math.ceil(totalCount / 10) || 1}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>

            {allowAccess ? (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
