export const FETCH_HOTEL_SEASON_PRICING_LIST =
  "FETCH_HOTEL_SEASON_PRICING_LIST";
export const FETCH_HOTEL_SEASON_PRICING_LIST_SUCCESS =
  "FETCH_HOTEL_SEASON_PRICING_LIST_SUCCESS";
export const FETCH_HOTEL_SEASON_PRICING_LIST_FAILED =
  "FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED";

export const FETCH_HOTEL_SEASON_PRICING_DETAIL =
  "FETCH_HOTEL_SEASON_PRICING_DETAIL";
export const FETCH_HOTEL_SEASON_PRICING_DETAIL_SUCCESS =
  "FETCH_HOTEL_SEASON_PRICING_DETAIL_SUCCESS";
export const FETCH_HOTEL_SEASON_PRICING_DETAIL_FAILED =
  "FETCH_HOTEL_LANG_SETTINGS_DETAIL_FAILED";

export const EDIT_HOTEL_SEASON_PRICING = "EDIT_HOTEL_SEASON_PRICING";
export const EDIT_HOTEL_SEASON_PRICING_SUCCESS =
  "EDIT_HOTEL_SEASON_PRICING_SUCCESS";
export const EDIT_HOTEL_SEASON_PRICING_FAILED =
  "EDIT_HOTEL_LANG_SETTINGS_FAILED";

export const CREATE_HOTEL_SEASON_PRICING = "CREATE_HOTEL_SEASON_PRICING";
export const CREATE_HOTEL_SEASON_PRICING_SUCCESS =
  "CREATE_HOTEL_SEASON_PRICING_SUCCESS";
export const CREATE_HOTEL_SEASON_PRICING_FAILED =
  "CREATE_HOTEL_LANG_SETTINGS_FAILED";

export const SET_SEASON_PRICE_SORT = "SET_SEASON_PRICE_SORT"
export const SET_SEASON_PRICE_LIST_PAGE_NO = "SET_SEASON_PRICE_LIST_PAGE_NO"

export const SET_SEARCH_SEASON = "SET_SEARCH_SEASON"

export const getHotelSeasonPricingList = (payload) => {
  return {
    type: FETCH_HOTEL_SEASON_PRICING_LIST,
    payload,
  };
};

export const createHotelSeasonPricing = (payload) => {
  return {
    type: CREATE_HOTEL_SEASON_PRICING,
    payload,
  };
};

export const editHotelSeasonPricing = (payload) => {
  return {
    type: EDIT_HOTEL_SEASON_PRICING,
    payload,
  };
};

export const HotelSeasonPricingDetail = (seasonId) => {
  return {
    type: FETCH_HOTEL_SEASON_PRICING_DETAIL,
    seasonId,
  };
};

export const setSearchSeason = (payload) => {
  return{
    type: SET_SEARCH_SEASON,
    payload
  }
}