import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Breadcrumbs from "../Common/Breadcrumbs";
import ConfirmWindow from "../Common/ConfirmWindow";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Spinner from "./../Common/Spinner";
import RoomPricingForm from "./RoomSeasonPricingForm";

export const EditRoomPricing = (props) => {
  const seasonId = props.match.params.id;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const storageService = new StorageService();
  const roomCategoryList = useSelector(
    (state) => state.roomCategories.roomCategories
  );
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const history = useHistory()
  const [extraSeasons, setExtraSeasons] = useState([]);
  const defaultError = useSelector((state)=>state?.hotelSeasonPricing?.error)
  const [showModal, setShowModal] = useState(false)
  const [resPayload, setResPayload] = useState(null);
  const seasonDetail = useSelector((state) => state.hotelSeasonPricing.result);
  const hotelId = +storageService.getItem("hotelId");
  const [seasonName, setSeasonName] = useState();
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    {
      path: "/hotel-configuration",
      title: hotelDetails?.name ? hotelDetails?.name : "",
    },
    { path: "/hotel-room-pricing", title: "Room pricing" },
    {
      path: `/hotel-room-pricing/edit/${seasonId}`,
      title: `${seasonName ? seasonName : ""}`,
    },
  ];
  useEffect(()=>{
    
    if(defaultError?.data?.is_default || defaultError?.data?.[0]?.is_default){
      setShowModal(true)
    }
  },[defaultError])

  const isUpdated = useSelector((state)=>state?.hotelSeasonPricing?.isUpdate)

  useEffect(()=>{
    if(isUpdated){
      history.push("/hotel-room-pricing")
    }
  },[isUpdated])
  useEffect(() => {
    dispatch(actions.HotelSeasonPricingDetail(seasonId));
  }, [seasonId]);

  useEffect(() => {
    if (seasonDetail && seasonDetail?.name && roomCategoryList) {
      setupSeasonDetails();
    } else if (!roomCategoryList) {
      // fetching room categories
      dispatch(actions.getRoomCategories(hotelId));
    }
  }, [seasonDetail, roomCategoryList]);

  useEffect(()=>{
    return(()=>{
      dispatch(actions.resetRoomCategories())
    })
  },[])

  const setupSeasonDetails = () => {
    if (seasonDetail && seasonDetail?.name) {
      setExtraSeasons(seasonDetail?.seasons_ts);
      const payload = {
        // season_name: seasonDetail?.seasons_ts[0]?.name,
        active_season: seasonDetail?.is_active === 1 ? true : false,
        isDefaultSeason: seasonDetail?.is_default || false,
        hotel_id: seasonDetail?.hotel_id,
        // 0th index as lang
        // language_id: seasonDetail?.seasons_ts[0]?.language_id,
        seasons_dates:
          seasonDetail?.seasons_dates.map((dateInfo) => {
            return {
              id: dateInfo.id,
              start_date: new Date(dateInfo?.start_date)
                .toISOString()
                .slice(0, 10),
              end_date: new Date(dateInfo?.end_date).toISOString().slice(0, 10),
              // making default dates as disabled
              is_disable: true,
            };
          }) || [],
      };

      if (i18n?.language) {
        const langInfo = selectedLangId(i18n?.language?.toLowerCase());
        if (langInfo) {
          let existingTranslation = (seasonDetail?.seasons_ts || []).find(
            (seasonTranslation) => {
              return seasonTranslation.language_id === langInfo.language_id;
            }
          );
          if (existingTranslation?.name?.length < 1)
            existingTranslation = (extraSeasons || []).find(
              (seasonTranslation) => {
                return seasonTranslation.language_id === 1;
              }
            );
          if (existingTranslation) {
            setSeasonName(existingTranslation?.name);
            payload.season_name = existingTranslation?.name
              ? existingTranslation?.name
              : "";
            payload.language_id = langInfo?.language_id;
          } else {
            payload.season_name = seasonDetail?.seasons_ts[0].name;
            setSeasonName(payload.season_name);
          }
          const pricingArray = setupEditSeasonPrizeTemplate(
            seasonDetail?.seasons_templates,
            langInfo.language_id,
            availableFilteredLanguages[0]?.languages?.id
          );
          payload.seasons_template = pricingArray;
        }
      }

      setResPayload(payload);
    }
  };

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  useEffect(() => {
    if (
      availableLanguagesFromSelector &&
      availableLanguagesFromSelector?.length
    ) {
      // setting up initially 0th index as lang id

      const langInfo = {
        language_id: availableFilteredLanguages[0]?.language?.id,
      };
      if (langInfo) {
        let existingTranslation = (extraSeasons || []).find(
          (seasonTranslation) => {
            return seasonTranslation.language_id === langInfo.language_id;
          }
        );
        if (existingTranslation?.name?.length < 1)
          existingTranslation = (extraSeasons || []).find(
            (seasonTranslation) => {
              return seasonTranslation.language_id === 1;
            }
          );
        if (existingTranslation) {
          setResPayload({
            ...resPayload,
            ...{
              season_name: existingTranslation?.name
                ? existingTranslation?.name
                : "",
              language_id: langInfo?.language_id,
            },
          });
        }
        setupSeasonDetails();
      }
    }
  }, [availableLanguagesFromSelector]);

  // i18n.on("languageChanged", (selectedLang) => {});

  const setupEditSeasonPrizeTemplate = (
    seasonTemplate,
    languageId,
    defaultLangId
  ) => {
    const pricingArray = [];
    if (roomCategoryList) {
      roomCategoryList?.forEach((categoryParentInfo, parentIndex) => {
        const dummyPricingArray = [];
        roomCategoryList.forEach((categoryChildInfo, childIndex) => {
          const amountInfo = seasonTemplate.find((templateInfo) => {
            return (
              templateInfo.source_category_id === categoryChildInfo.id &&
              templateInfo.target_category_id === categoryParentInfo.id
            );
          });
          dummyPricingArray.push({
            source_category_id: categoryChildInfo.id,
            target_category_id: categoryParentInfo.id,
            upsell: amountInfo?.upsell || 0,
          });
        });
        const categoryName = categoryParentInfo.category_ts.filter(
          (categoryTranslation) => {
            return categoryTranslation.language_id === languageId;
          }
        )[0]?.name;

        pricingArray.push({
          name:
            categoryName ||
            (categoryParentInfo.category_ts &&
            categoryParentInfo.category_ts?.length
              ? categoryParentInfo.category_ts[0]?.name
              : categoryParentInfo.category_ts[0]?.name),
          pricing: dummyPricingArray,
        });
      });
    }
    return pricingArray;
  };

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.Room pricing (edit).Edit season")}</h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        {!isLoading ? (
          <>
            {resPayload && (
              <RoomPricingForm
                isForEdit={true}
                roomCategoryList={roomCategoryList}
                payload={resPayload}
                extraSeasons={extraSeasons}
                hotelId={hotelId}
                seasonId={seasonId}
              />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <ConfirmWindow
        show={showModal}
        onHide={()=>setShowModal(false)}
        isoverride={()=>setShowModal(false)}
        isnotoverride={()=>setShowModal(false)}
        header={""}
        yesButtonLable={null}
        noButtonLable={"Close"}
        body={defaultError?.msg}
      ></ConfirmWindow>
    </React.Fragment>
  );
};
