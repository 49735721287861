import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";
import PaginationComponent from "./../Common/PaginationComponent";

export const DealsTranslation = ({
  data,
  languageIds,
  languageNames,
  hotelId,
  filter,
  totalCount,
  onPageChange,
  pageNo,
}) => {
  const defaultLimit = 10;
  const [forHeader, setForHeader] = useState([
    {
      header: "Name",
    },
    {
      header: "Field",
    },
  ]);
  const { t } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [allowAccess, setAllowAccess] = useState(true);
  const [dealsEditorData, setDealsEditorData] = useState({
    translation: [],
  });

  const formInitialValue = {
    translation: {},
  };

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const finalData = Utils.refactorCategoryTranslationData(
        data,
        "h_offers_and_services_ts"
      );
      const filteredData = Utils.filterCategoryTranslation(
        finalData,
        [...languageIds],
        "Name",
        "Description"
      );
      setDealsEditorData({
        translation: filteredData,
      });
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);

  const updateDealsTranslation = (formValue) => {
    const dealsData = formValue;
    dealsData.forEach((dealsInfo) => {
      delete dealsInfo.descriptionLabel;
      delete dealsInfo.nameLabel;
      delete dealsInfo.name;
      dealsInfo.h_offers_and_services_ts = dealsInfo.fields;
      // delete categoryInfo.fields;
      // categoryInfo.hotelId = hotelId;
    });
    dispatch(
      actions.updateTranslation(
        { offer: dealsData },
        hotelId,
        filter,
        pageNo,
        defaultLimit
      )
    );
  };

  const updatePagination = (pageNo) => {
    onPageChange({ page: +pageNo });
  };

  return (
    <div className="row-vertical-scroll">
      <Row className="mb-3">
        {forHeader?.map((headerInfo, index) => (
          <Col key={`header-${index}`}>
            <strong>{headerInfo?.header}</strong>
          </Col>
        ))}
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={{ ...formInitialValue }}
        onSubmit={(values) => {
          const formValue = dealsEditorData?.translation;
          updateDealsTranslation(formValue);
        }}
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <>
            <FieldArray name="translations">
              {() => {
                const translationArray = values?.translation;
                return (
                  <>
                    {translationArray && translationArray.length ? (
                      <>
                        <Row>
                          {translationArray?.map((categoryInfo, index) => (
                            <React.Fragment key={`form-${index}`}>
                              <Col
                                key={`form-col-name-${index}`}
                                className="full-wrap"
                              >
                                <bootstrapForm.Control
                                  key={`season-label-form-${index}`}
                                  className="mt-3 disable-form"
                                  disabled={true}
                                  value={categoryInfo?.name || "NA"}
                                />
                              </Col>
                              <Col key={`form-label-col-${index}`}>
                                <div key={`form-label-name-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    value={categoryInfo.nameLabel}
                                    disabled={true}
                                    className="mt-3"
                                  />
                                </div>
                                <div key={`form-label-description-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    disabled={true}
                                    value={categoryInfo.descriptionLabel}
                                    className="mt-3"
                                  />
                                </div>
                              </Col>
                              {categoryInfo?.fields.map(
                                (translationValue, tagIndex) => (
                                  <Col key={`form-${tagIndex}-${index}`}>
                                    <div key={`form-div-${tagIndex}-${index}`}>
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.name}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.name`}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.trim().length > 0
                                          ) {
                                            dealsEditorData.translation[
                                              index
                                            ].fields[tagIndex].body =
                                              event.target.value;
                                            handleChange(event);
                                          } else if (
                                            event.target.value.trim().length < 1
                                          ) {
                                            dealsEditorData.translation[
                                              index
                                            ].fields[tagIndex].name = "";
                                            setFieldValue(
                                              `translation.${index}.fields.${tagIndex}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mt-3"
                                      key={`form-div-2-${tagIndex}-${index}`}
                                    >
                                      {/* <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.body}
                                        name={`translation.${index}.fields.${tagIndex}.body`}
                                        onChange={handleChange}
                                      /> */}
                                      <CKEditorComponent
                                        className="mt-3"
                                        editorState={translationValue?.body}
                                        newContent={translationValue?.body}
                                        setEditorState={(updateContent) => {
                                          if (
                                            !(
                                              index in
                                              dealsEditorData.translation
                                            )
                                          ) {
                                            dealsEditorData.translation[index] =
                                              {
                                                fields: [],
                                              };
                                            dealsEditorData.translation[
                                              index
                                            ].fields[tagIndex] = {};
                                          }

                                          dealsEditorData.translation[
                                            index
                                          ].fields[tagIndex].body =
                                            updateContent;
                                          // setFieldValue(
                                          //   `translation.${index}.fields.${tagIndex}.body`,
                                          //   updateContent
                                          // );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                              <span className="mt-2 mb-2">
                                <hr />
                              </span>
                            </React.Fragment>
                          ))}
                          {/* } */}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            </FieldArray>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={+pageNo}
                  totalCount={Math.ceil(totalCount / 10) || 1}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>

            {allowAccess ? (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
