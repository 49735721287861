export const FETCH_HOTELS_BASIC_DETAILS = "FETCH_HOTELS_BASIC_DETAILS";
export const FETCH_HOTELS_BASIC_DETAILS_SUCCESS =
  "FETCH_HOTELS_BASIC_DETAILS_SUCCESS";
export const FETCH_HOTELS_BASIC_DETAILS_FAILED =
  "FETCH_HOTELS_BASIC_DETAILS_FAILED";

export const FETCH_HOTELS_CONFIGURATION = "FETCH_HOTELS_CONFIGURATION"
export const FETCH_HOTELS_CONFIGURATION_SUCCESS = "FETCH_HOTELS_CONFIGURATION_SUCCESS"
export const FETCH_HOTELS_CONFIGURATION_FAILED = "FETCH_HOTELS_CONFIGURATION_FAILED"


export const FETCH_HOTEL_TAGS_SUCCESS = "FETCH_HOTEL_TAGS_SUCCESS";
export const FETCH_HOTEL_TAGS = "FETCH_HOTEL_TAGS";
export const FETCH_HOTEL_TAGS_FAILED = "FETCH_HOTEL_TAGS_FAILED";
export const ADD_HOTEL_TAGS = "ADD_HOTEL_TAGS";
export const ADD_HOTEL_TAGS_SUCCESS = "ADD_HOTEL_TAGS_SUCCESS";
export const ADD_HOTEL_TAGS_FAILED = "ADD_HOTEL_TAGS_FAILED";

export const REMOVE_HOTEL_TAGS = "REMOVE_HOTEL_TAGS";
export const REMOVE_HOTEL_TAGS_SUCCESS = "REMOVE_HOTEL_TAGS_SUCCESS";
export const REMOVE_HOTEL_TAGS_FAILED = "REMOVE_HOTEL_TAGS_FAILED";

export const INIT_UPDATE_HOTEL_DETAILS = "INIT_UPDATE_HOTEL_DETAILS";
export const UPDATE_HOTEL_DETAILS = "UPDATE_HOTEL_DETAILS";
export const UPDATE_HOTEL_DETAILS_SUCCESS = "UPDATE_HOTEL_DETAILS_SUCCESS";
export const UPDATE_HOTEL_DETAILS_FAILED = "UPDATE_HOTEL_DETAILS_FAILED";

export const FETCH_SEARCHED_HOTEL_TAGS = "FETCH_SEARCHED_HOTEL_TAGS"
export const FETCH_SEARCHED_HOTEL_TAGS_SUCCESS = "FETCH_SEARCHED_HOTEL_TAGS_SUCCESS"
export const FETCH_SEARCHED_HOTEL_TAGS_FAILED = "FETCH_SEARCHED_HOTEL_TAGS_FAILED"

export const getHotelBasicDetails = (id) => {
  return {
    type: FETCH_HOTELS_BASIC_DETAILS,
    id,
  };
};

export const getHotelBasicConfiguration = (id) => {
  return {
    type: FETCH_HOTELS_CONFIGURATION,
    id,
  };
};

export const getHotelTags = (id) => {
  return {
    type: FETCH_HOTEL_TAGS,
    id,
  };
};

export const searchTags = (id, searchKey,defaultSelectedLanguage) => {
  return {
    type: FETCH_SEARCHED_HOTEL_TAGS,
    id,
    searchKey,
    defaultSelectedLanguage
  };
};



export const addHotelTags = (payload) => {
  return {
    type: ADD_HOTEL_TAGS,
    payload,
  };
};

export const removeHotelTags = (payload) => {
  return {
    type: REMOVE_HOTEL_TAGS,
    payload,
  };
};

export const hotelDetailUpdate = (payload, hotelId) => {
  return {
    type: UPDATE_HOTEL_DETAILS,
    payload,
    hotelId
  };
}
