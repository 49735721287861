import React, { useEffect, useState } from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { Utils } from "../../utils/utils";
import { ReactComponent as CloseIcon } from "../Common/icons/close.svg";
import { ToastService } from "./../../services/toast.service";

export const AddTags = ({
  updateTags,
  removeTags,
  existingTags,
  setNewTags,
  searchTags,
  tagsApiId,
  setFieldValue,
  values,
  errors,
  touched,
  path,
  initialValues={},
  disabled=false,
  defaultSelectedLanguage
}) => {
  const { t } = useTranslation();
  const [inputTag, setInputTag] = useState("");
  const toastService = new ToastService();

  useEffect(() => {
    if (initialValues?.hotel_tags && (path==='update') ){
      
        const tags = []
        initialValues?.hotel_tags?.map((tagInfo) => {
          
          if (!updateTags.tags.includes(Utils.langValueGenerator(tagInfo.tag_tag?.tags_ts, 'name', defaultSelectedLanguage))){

            tags.push(Utils.langValueGenerator(tagInfo.tag_tag?.tags_ts, 'name', defaultSelectedLanguage));
          }
        });
       
        updateTags.setTags([...updateTags.tags, ...tags]);
    }
   
  }, [initialValues]);
 // ********** To Do The Validation of Tags *******
  useEffect(()=>{
      
    setFieldValue('tagsString', updateTags.tags.toString())
  },[updateTags.tags])

  const softDeleteTag = (tags, tagName) => {
    updateTags.setTags(tags.filter((tag, i) => tag !== tagName));
  };

  const softDeleteTagId = (tags, tagId)=>{
      tagsApiId.settagsInApi(tags.filter((tagid,i)=> tagid.name !== tagId))
  }

  const removeTag = (tagName) => {
    const isExistingTag = filterTags(existingTags, true, tagName);

    if (isExistingTag && isExistingTag?.id) {
      removeTags.setDeletedTags(value=>([...value, isExistingTag?.name]));
      softDeleteTag(updateTags.tags, tagName);
      softDeleteTagId(tagsApiId.tagsInApi, isExistingTag.name)
    } else {
      softDeleteTag(updateTags.tags, tagName);
      softDeleteTagId(tagsApiId.tagsInApi, tagName)
      
    }

    const isNewTagExist = setNewTags.newTags.find((tagInfo) => {
      return tagName.toLowerCase().trim() === tagInfo.toLowerCase().trim();
    });
    if (isNewTagExist) {
      const newTags = setNewTags.newTags;
      const existingIdInTagIndex = newTags.indexOf(isNewTagExist);
      // removing from delete if already exist
      if (existingIdInTagIndex !== -1) {
        newTags.splice(existingIdInTagIndex, 1);
        setNewTags.setNewTags([...newTags]);
      }
    }
    
    
  };
    
  // finding filter tag and returning existing one
  const filterTags = (tagArray, isByName = false, tagName) => {
    
    const isExist = tagArray.find((tagInfo) => {
      const filterValue = isByName
        ? tagInfo.name.toLowerCase().trim()
        : tagInfo.toLowerCase().trim();

      return tagName.toLowerCase().trim() === filterValue;
    });
    
    return isExist;
  };

  const setInput =(event)=>{
    setInputTag((event?.label)?(event?.label):"")
    searchTags.setSearchKey((event?.label)?(event?.label):"")
  } 

  const addTagsOnClick = () => {
    if (inputTag.trim()) {
      
      const isTagExist = filterTags(updateTags.tags, false, inputTag.trim());
      if (!isTagExist) {
        const isActualTagExist = filterTags(
          existingTags,
          true,
          inputTag.trim()
        );
        
        if (isActualTagExist && isActualTagExist?.id) {
          const deletedTags = removeTags.deletedTags;
          const existingIdInTagIndex = deletedTags.indexOf(
            isActualTagExist?.id
          );
          // removing from delete if already exist
          if (existingIdInTagIndex !== -1) {
            deletedTags.splice(existingIdInTagIndex, 1);
            removeTags.setDeletedTags([...deletedTags]);
          }
        }

        const isTagExistInAPI = filterTags(existingTags, true, inputTag.trim());
        if (!isTagExistInAPI) {
          setNewTags.setNewTags([inputTag.trim(), ...setNewTags.newTags]);
        }
        else{
            if(isTagExistInAPI){
                tagsApiId.settagsInApi([{id:isTagExistInAPI.id, name:isTagExistInAPI.name}, ...tagsApiId.tagsInApi]);
            }
        }
        updateTags.setTags([inputTag.trim(), ...updateTags.tags]);
        setInputTag("");
      } else {
        toastService.onWarning("tag already exist");
      }
    }
  };
  const options =[]
  existingTags.forEach((tag)=>{
    options.push({value:tag?.id, label: Utils.langValueGenerator(tag?.tags_ts,'name',defaultSelectedLanguage)})
  })


  let invalidStyle = {};
  let isInvalid = touched.tagsString&&errors.tagsString;
  if (isInvalid) {
    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: "1px solid red",
        "&:hover": { borderColor: "red" },
        boxShadow: "none",
      }),
    };
  }
  return (
    <React.Fragment>
      <Row >
        <Col md={3} xs={6}>
          <CreatableSelect
            name="tagsString"
            options={options}
            className={`basic-multi-select}`}
            classNamePrefix="select"
            isClearable={true}
            onChange={setInput}
            styles={invalidStyle}
            isDisabled={disabled}
          ></CreatableSelect>
        </Col>
        <Col md={3} xs={3}>
          <Button variant="primary" disabled={disabled} onClick={addTagsOnClick}>
            {t("Modules.Rooms List.Add room feature")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-3 noPadding">
          {/* for new tags */}
          {updateTags.tags?.map((tagInfo, index) => {
            return (
              <InputGroup className="mb-3 tags-inline z-index-0 " key={`tags-${index}`}>
                <InputGroup.Text
                  onClick={() => {
                    removeTag(tagInfo);
                  }}
                >
                  <CloseIcon className="pointer" />
                </InputGroup.Text>
                <Button variant="light">{tagInfo}</Button>
              </InputGroup>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default AddTags;
