import axios from "../authrInstance";
class Authr {
    getRoles = async (hotelGroupId) => {
        try {
            const response = await axios.get(`/roles/${hotelGroupId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }
    getUserById = async (userId) => {
        try {
            const response = await axios.get(`/user/${userId}`);
            return response;
        } catch (error) {
            throw error;
        }
    };
    UpdateUser = async (userId, payload) => {
        try {
            const response = await axios.put(`/update-user/${userId}`,payload);
            return response;
        } catch (error) {
            throw error;
        }
    };
    getUsers = async (hotelGroupId,payload) => {
        try {
          const params = {
            ...payload,
          };
          const response = await axios.get(`/hotel-group/${hotelGroupId}/users`,{ params });
          return response;
        } catch (error) {
          throw error;
        }
      };
      createUser = async (payload) => {
        try {
          const response = await axios.post("/create-user", payload);
          return response;
        } catch (error) {
          throw error;
        }
      };
      deactivateUser = async(payload)=>{
        try {
            const response = await axios.post('/disable-user',payload);
            return response;
        }catch (error) {
            throw error;
        }
    };
    activateUser = async(payload)=>{
      try {
          const response = await axios.post('/enable-user',payload);
          return response;
      }catch (error) {
          throw error;
      }
  };


}

export default Authr;