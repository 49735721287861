import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from './storage.service';

    const storageService = new StorageService();

    const getToken = () => {
        return `${storageService.getItem('hotel-group-token')}` || false;
    };
    const getSessionToken = () => {
        return `${storageService.getItem('session-token')}` || false;
    };
    const getAccessToken = ()=>{
        return `${storageService.getItem(STORAGE_KEYS.AccessToken)}` || false;
    }
    const getDashboardLink = ()=>{
        return `${storageService.getItem(STORAGE_KEYS.dashboardLinks)}` || false;
    }
    const getTokens = () => {
        const tokens = {
            AccessToken:storageService.getItem(STORAGE_KEYS.AccessToken),
            RefreshToken:storageService.getItem(STORAGE_KEYS.RefreshToken)
        }
        return tokens || false;
    };
    const removeToken = () => {
        storageService.removeItem(STORAGE_KEYS.token)
        storageService.removeItem(STORAGE_KEYS.IdToken)
        storageService.removeItem(STORAGE_KEYS.RefreshToken)
        storageService.removeItem(STORAGE_KEYS.AccessToken)
        storageService.removeItem(STORAGE_KEYS.allowedPages)
        storageService.removeItem("user-first-name")
        storageService.removeItem(STORAGE_KEYS.hotelGroupId)
        storageService.removeItem(STORAGE_KEYS.hotelId)
        storageService.removeItem(STORAGE_KEYS.dashboardLinks)
        return storageService.removeItem(STORAGE_KEYS.hotelGroupToken);
    }
    const removeSessionToken = () => {
        return storageService.removeItem('session-token');
    }

export { getToken, removeToken, removeSessionToken, getTokens, getAccessToken, getDashboardLink };

