import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LANGUAGE, STORAGE_KEYS } from "../../../helper/constants";
import { StorageService } from "./../../../services/storage.service";
import { useDispatch, useSelector } from "react-redux";
import  actions  from "../../../store/actions/actions"

export const AuthHeader = ({
  HotelLogo = null,
  availableFilteredLanguages = [],
}) => {
  const storageService = new StorageService();
  const defaultLangId = useSelector((state)=>state?.localLang?.language_id); 
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    defaultLangId
  );

  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const { i18n } = useTranslation();
  const valueAsKey = false
  const extraClasses = "webkit-auto form-control"
  const dispatch = useDispatch()

  useEffect(()=>{
    if(defaultLangId){
      setDefaultLanguage(defaultLangId)
    }
  },[defaultLangId])

  useEffect(() => {
    if (i18next.language && availableFilteredLanguages.length) {

      const activeLang = availableFilteredLanguages.find((langInfo) => {
        return +langInfo.id === +defaultSelectedLanguage;
      });
      if (activeLang && activeLang?.code) {
        i18n.changeLanguage(LANGUAGE[activeLang.code.toLowerCase()]);
      }
    }
  }, [i18next.language]);
  useEffect(() => {
    if (defaultSelectedLanguage && availableFilteredLanguages.length > 1) {
      const selectedLanguage = availableFilteredLanguages.find((languageInfo) => {
        return +languageInfo?.id === +defaultSelectedLanguage;
      });
      i18n.changeLanguage(LANGUAGE[selectedLanguage?.code?.toLowerCase()]);
    }
  }, [availableFilteredLanguages]);

  const onValueChange = (languageId) => {
    storageService.setItem(STORAGE_KEYS.languageId, languageId);
    const selectedLanguage = availableFilteredLanguages.find((languageInfo) => {
      return +languageInfo?.id === +languageId;
    });
    setDefaultLanguage(selectedLanguage?.id);
    setSelectedLanguage(selectedLanguage)
    i18n.changeLanguage(LANGUAGE[selectedLanguage.code.toLowerCase()]);
    dispatch(actions.setLangAction(selectedLanguage?.id))
  };

  const selectLanguage = () => {
    if (availableFilteredLanguages.length) {
      return (
        <div
          className="set-absolute pt-3"
          style={{ position: "left", top: "1px", right: "1px" }}
        >
          <Form.Group>
            <Dropdown
              onChange={(e) => {
                onValueChange(e.target.value);
              }}
              className={extraClasses}
              as="select"
              defaultValue={defaultSelectedLanguage}
              required
            >
              {availableFilteredLanguages?.map((languageInfo, index) => {
                return (
                  <option key={index} value={+languageInfo?.id}>
                    {valueAsKey
                      ? languageInfo?.key?.toUpperCase()
                      : languageInfo?.code}
                  </option>
                );
              })}
            </Dropdown>
          </Form.Group>
        </div>
      );
    }
  };
  return (
    <>
      {/* <div className="logo mt-3">
        <span>
          {HotelLogo ? (
            <img
              alt="expect-me-logo"
              src={HotelLogo}
              width="40px"
              height="40px"
            />
          ) : (
            <></>
          )}
        </span>
        <span>
          <img alt="expect-me-logo" src={poweredby} />
        </span>
        <span>
          <img alt="expect-me-logo" src={Expectme} />
        </span>
      </div> */}
      {/* lang dropdown */}
      {selectLanguage()}
    </>
  );
};
