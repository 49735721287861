export const FETCH_ROOM_CATEGORIES = "FETCH_ROOM_CATEGORIES";
export const FETCH_ROOM_CATEGORIES_SUCCESS = "FETCH_ROOM_CATEGORIES_SUCCESS";
export const FETCH_ROOM_CATEGORIES_FAILED = "FETCH_ROOM_CATEGORIES_FAILED";

export const FETCH_PMS_ROOM_TYPES = "FETCH_PMS_ROOM_TYPES";
export const FETCH_PMS_ROOM_TYPES_SUCCESS = "FETCH_PMS_ROOM_TYPES_SUCCESS";
export const FETCH_PMS_ROOM_TYPES_FAILED = "FETCH_PMS_ROOM_TYPES_FAILED";

export const FETCH_ROOM_CATEGORY_BY_ID = "FETCH_ROOM_CATEGORY_BY_ID";
export const FETCH_ROOM_CATEGORY_BY_ID_SUCCESS = "FETCH_ROOM_CATEGORY_BY_ID_SUCCESS";
export const FETCH_ROOM_CATEGORY_BY_ID_FAILED = "FETCH_ROOM_CATEGORY_BY_ID_FAILED";

export const ADD_ROOM_CATEGORIES = "ADD_ROOM_CATEGORIES";
export const ADD_ROOM_CATEGORIES_SUCCESS = "ADD_ROOM_CATEGORIES_SUCCESS";
export const ADD_ROOM_CATEGORIES_FAILED = "ADD_ROOM_CATEGORIES_FAILED";

export const UPDATE_ROOM_CATEGORY = "UPDATE_ROOM_CATEGORY";
export const UPDATE_ROOM_CATEGORY_SUCCESS = "UPDATE_ROOM_CATEGORY_SUCCESS";
export const UPDATE_ROOM_CATEGORY_FAILED = "UPDATE_ROOM_CATEGORY_FAILED";
export const RESET_ROOM_CATEGORIES = "RESET_ROOM_CATEGORIES"

export const getRoomCategories = (hotelId) => {
  
    return {
      type: FETCH_ROOM_CATEGORIES,
      hotelId,
    };
  };

  export const getPMSRoomsTypes = (hotelId) => {
    return {
      type: FETCH_PMS_ROOM_TYPES,
      hotelId
    };
  };

  export const getRoomCategoryById = (categoryId,hotelId) => {
    return {
      type: FETCH_ROOM_CATEGORY_BY_ID,
      categoryId,
      hotelId,
    };
  };


  export const addRoomCategories = (data) => {
    return {
      type: ADD_ROOM_CATEGORIES,
      data,
    };
  };


  export const updateRoomCategories = (categoryId,data) => {
    return {
      type: UPDATE_ROOM_CATEGORY,
      categoryId,
      data,
    };
  };

  export const resetRoomCategories = () => {
    return{
      type:RESET_ROOM_CATEGORIES
    }
  }