import actions from "../actions/actions";

const initialState = {
    language_id:1
}

export const setLangReducer = (state=initialState, action)=>{
    switch (action.type){
        case actions.SET_LANGUAGE:{
            return {
                language_id: parseInt(action.language_id)
            }
        }

        default:{
            return state
        }
    }

}