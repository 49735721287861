export const FETCH_OFFERS_LIST = "FETCH_OFFERS_LIST";
export const FETCH_OFFERS_LIST_SUCCESS = "FETCH_OFFERS_LIST_SUCCESS";
export const FETCH_OFFERS_LIST_FAILED = "FETCH_OFFERS_LIST_FAILED";

export const FETCH_OFFERS_BY_ID = "FETCH_OFFERS_BY_ID";
export const FETCH_OFFERS_BY_ID_SUCCESS = "FETCH_OFFERS_BY_ID_SUCCESS";
export const FETCH_OFFERS_BY_ID_FAILED = "FETCH_OFFERS_BY_ID_FAILED";

export const UPDATE_OFFER = "UPDATE_OFFER";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILED = "UPDATE_OFFER_FAILED";

export const ADD_OFFER = "ADD_OFFER";
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const ADD_OFFER_FAILED = "ADD_OFFER_FAILED";

export const SEARCH_OFFERS = "SEARCH_OFFERS";

export const SET_OFFER_SORT = "SET_OFFER_SORT"
export const SET_OFFER_PAGE_NO = "SET_OFFER_PAGE_NO"
export const CLEAR_OFFER_BY_ID = "CLEAR_OFFER_BY_ID"

export const SET_SEARCH_OFFER_LIST = "SET_SEARCH_OFFER_LIST"

export const getOffers = (payload) => {
    return {
      type: FETCH_OFFERS_LIST,
      payload
    };
  };

  export const getOffersById = (hotelId,offerId) => {
    return {
      type: FETCH_OFFERS_BY_ID,
      hotelId,
      offerId,
    };
  };

  export const addOffer = (data) => {
    return {
      type: ADD_OFFER,
      data,
    };
  };
  export const updateOffer = (offerId,data) => {
    return {
      type: UPDATE_OFFER,
      offerId,
      data,
    };
  };
  export const searchOffers = (hotelId,searchKey) =>{
    return {
        type : SEARCH_OFFERS,
        hotelId,
        searchKey
    }
  }

  export const setSearchOfferList = (payload) => {
    return{
      type: SET_SEARCH_OFFER_LIST,
      payload
    }
  }