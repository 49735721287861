import React from "react";
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MOBILE_SCREENS, PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import { ReactComponent as Arrowright } from "./SidebarSvg/Arrowright.svg";
import { ReactComponent as DashboardIcon } from "./SidebarSvg/Dashboard.svg";
import { ReactComponent as HomeIcon } from "./SidebarSvg/Home.svg";
import { ReactComponent as HotelIcon } from "./SidebarSvg/Hotel.svg";
import { ReactComponent as LogoIcon } from "./SidebarSvg/Logo.svg";
import { ReactComponent as QuestionIcon } from "./SidebarSvg/Question.svg";
import { ReactComponent as TrueIcon } from "./SidebarSvg/Subtract.svg";
import { ReactComponent as UsersIcon } from "./SidebarSvg/Users.svg";
import { ReactComponent as VectorIcon } from "./SidebarSvg/Vector.svg";

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const locationRef = useLocation();
  const showSidebar = () => {
    props.onChangeSideBar(!props.sidebar);
  };
  const storageService = new StorageService();

  const checkActiveLinks = (sidebarType) => {
    return locationRef.pathname.split("/").includes(sidebarType)
      ? "active"
      : "";
  };
  const dashboard = useSelector(
    (state) => state?.login?.result?.dashboardLinks
  );
  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };

  const handleSidebarForMobile = () => {
    // eslint-disable-next-line no-restricted-globals
    if (+MOBILE_SCREENS.MAX_WIDTH > +screen.width) {
      showSidebar();
    }
  }
  const urlReturn = () => {
    if (
      dashboard == "undefined" ||
      dashboard == undefined ||
      dashboard == null ||
      dashboard?.length < 1
    ) {
      return "";
    } else {
      return JSON.parse(dashboard)?.[0]?.dashboard_link;
    }
  };
  const redirectToDashboard = ()=>{
    if(storageService.getItem("AccessToken")){
      if(dashboard){
        const dashboard_url = urlReturn()
        if(dashboard_url.length>1){
          window.open(dashboard_url);
        }
      }
    }
    else{
      dispatch(actions.clearToken());
    }
  }
  return (
    <Nav
      activeKey={locationRef.pathname}
      className={`${
        props.sidebar ? "flex-column active-sidebar" : "flex-column"
      } sidebar-scroll`}
    >
      <Link className="sidebar__list-item--logo" to="/home">
        <LogoIcon />
      </Link>
      <div className="sidebar-content">
        {hiddenIconAsPerRole(PRIVATE_ROUTES.Hotel_management) ? (
          <Link
            to={PRIVATE_ROUTES?.Hotel_management}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("home")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content" >
              <HomeIcon />
              {props.sidebar && <span className="sidebar-label">Home </span>}
            </span>
          </Link>
        ) : (
          <></>
        )}
        <Link
          to='/#'
          as={NavLink}
          className={`${
            props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
          }`}
          onClick={handleSidebarForMobile}
        >
          <span className="sidebar-content" >
            <DashboardIcon onClick={redirectToDashboard}/>
            {props.sidebar && <span className="sidebar-label">Dashboard </span>}
          </span>
        </Link>

        {hiddenIconAsPerRole(PRIVATE_ROUTES.Home) ? (
          <Link
            to={PRIVATE_ROUTES?.Home}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("hotel")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <HotelIcon />
              {props.sidebar && <span className="sidebar-label">Hotels</span>}
            </span>
          </Link>
        ) : (
          <></>
        )}

        {hiddenIconAsPerRole(PRIVATE_ROUTES.user_list) ? (
          <Link
            to={PRIVATE_ROUTES?.user_list}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("user")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <UsersIcon />
              {props.sidebar && <span className="sidebar-label">Users</span>}
            </span>
          </Link>
        ) : (
          <></>
        )}

        <Link
          to="/bookings"
          className={`${
            props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
          } ${checkActiveLinks("bookings")}`}
          onClick={handleSidebarForMobile}
        >
          <span className="sidebar-content">
            <TrueIcon />
            {props.sidebar && <span className="sidebar-label">Bookings</span>}
          </span>
        </Link>
        <div className="sidebar__list-item--expand">
          {!props.sidebar ? (
            <div>
              <Nav.Link onClick={showSidebar}>
                <VectorIcon />
              </Nav.Link>
            </div>
          ) : (
            <div>
              <Nav.Link onClick={showSidebar} className="arrow-right">
                <Arrowright />
              </Nav.Link>
            </div>
          )}
        </div>
      </div>
      <div className={"sidebar-content-last"}>
        <div className="question-icon-sidebar">
          
            <Link to="/contact-us" className="nav-link" onClick={handleSidebarForMobile}>
              <span className="sidebar-content">
                <QuestionIcon />
                {props.sidebar && (
                  <span className="sidebar-label">Contact Expect Me</span>
                )}
              </span>
            </Link>
         
        </div>
      </div>
    </Nav>
  );
};

export default Sidebar;
