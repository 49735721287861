import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getToken } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import { ReactComponent as UserSVG } from '../Common/icons/SingleUser.svg';
const LoginDropdown = () => {
    const storageService = new StorageService();
    const dispatch = useDispatch();
    const history = useHistory();
    const token = getToken()
    const userProfile = useSelector((state) => state?.user?.userProfile);
    const isProfile = useSelector((state) => state?.user?.getUserProfile);
    const accessToken = useSelector((state) => state?.login?.result?.AccessToken);

    const [profilePic, setProfilePic] = useState()
    useEffect(() => {
        if (accessToken) {
            const payload = { "accessToken": accessToken }
            dispatch(actions.getUserProfile(payload));
        }
    }, [accessToken]);

    useEffect(() => {
        if (userProfile) {
            if (userProfile.profile_picture) {
                setProfilePic(userProfile.profile_picture)
            }
            else {
                setProfilePic(null)
            }
        }

    }, [userProfile]);

    const logOut = () => {
        const accessToken = storageService.getItem("AccessToken");
        dispatch(actions.logOut({ 'accessToken': accessToken }));
    }
    const profile = () => {
        history.push("/user/profile");
    }
    return (
        <Dropdown>
            <Dropdown.Toggle size="sm" variant="white">
                {profilePic ? <img src={profilePic} width="40" height="40" /> : <UserSVG />}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => profile()}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    let state = { ...history.location.state }
                    delete state.transaction
                    history.replace({ ...history.location, state })
                    logOut()
                }}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

}

export default LoginDropdown;