import actions from "../actions/actions";

const initialState = {
  result: false,
  tags:[],
  msg: false,
  success: false,
  isLoading: false,
  configuration:false
};

export const HotelDetailsReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case actions.FETCH_HOTELS_BASIC_DETAILS_SUCCESS: {
      return {
        ...state,
        result: action?.result?.data,
        msg: action?.result?.msg,
        success: true,
        isLoading: false
      };
    }
    case actions.FETCH_HOTELS_BASIC_DETAILS_FAILED: {
      return {
        ...state,
        result: {
            msg: action?.msg,
        },
        success: false,
        isLoading: false
      };
    }

    case actions.FETCH_SEARCHED_HOTEL_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action?.result,
        msg: action?.msg,
        success: true,
        isLoading: false
      };
    }
    case actions.FETCH_SEARCHED_HOTEL_TAGS_FAILED: {
      return {
        ...state,
        tags: {
            msg: action?.msg,
        },
        success: false,
        isLoading: false
      };
    }

    case actions.UPDATE_HOTEL_DETAILS_FAILED: {
      return {
        ...state,
        success: false,
        isLoading: false
      };
    }

    case actions.INIT_UPDATE_HOTEL_DETAILS: {
      return {
        ...state,
        success: false,
        isLoading: true,
      };
    }
    case actions.FETCH_HOTELS_BASIC_DETAILS: {
      return {
        ...state,
        success: false,
        isLoading: true,
      };
    }
    case actions.FETCH_HOTEL_TAGS_SUCCESS: {
      state.result.tags = action?.result;
      return {
        ...state,
        success: false
      };
    }

    case actions.FETCH_HOTELS_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: action?.result
      };
    }

    case actions.FETCH_HOTELS_CONFIGURATION_FAILED: {
      return {
        ...state,
        configuration: false
      };
    }
    case actions.FETCH_HOTEL_TAGS_FAILED: {
      return {
        ...state,
        result: {
            msg: action?.msg,
        },
        success: false
      };
    }
    default: {
      return state;
    }
  }
};
