import React, { useEffect, useState } from "react";
import { Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import LanguageDropDown from "../Common/LanguageDropDown";
import CategoryDropDown from "../RoomCategories/CategoryDropdown";
import { StorageService } from "./../../services/storage.service";

const UserBasicInformation = (props) => {
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const hotelGroupId = +storageService.getItem("hotelGroupId");
  const id = +storageService.getItem("hotelGroupId");
  const { t } = useTranslation();
  const [HotelNameList, setHotelNameList] = useState();
  const [primaryDropdown, setPrimaryDropdown] = useState(null);

  const hotelByHotelGrouplist = useSelector(
    (state) => state.hotelmanagement.hotelslist
  );
  const [languageList, setlanguageList] = useState([]);
  const languages = useSelector(state => state.constants.languages)
  const [roleList, setRoleList] = useState();
  const roles = useSelector(state => state.authr.roles)
  useEffect(() => {
    
    // dispatch(actions.getLanguages(hotelId))
    if(id){
      dispatch(actions.getRole(id))
      dispatch(actions.getHotels(id));
    }
  }, []);

  useEffect(() => {
    if (hotelByHotelGrouplist) {
      const dropDownValue = hotelByHotelGrouplist?.reduce(
        (v, i) => {
          v.push({ id: i["id"], name: i["value"] });
          return v;
        },
        []
      );
      setHotelNameList(dropDownValue)
    }
  }, [hotelByHotelGrouplist]);
  useEffect(() => {
    if (languages) {
      const langList = languages?.map((lang) => {
        return { value: lang?.language.code, id: lang?.language?.id };
      });
      setlanguageList(langList);
    }
    if (roles) {
      const roleList = roles?.map((role) => {
        return { value: role.name, id: role.id };
      });
      setRoleList(roleList);
    }
  }, [languages, roles]);
  return (
    <div>
      <div className="header">{t("Modules.User management (+ add new).Basic Information")}</div>
      <div className="sub-header-text">
        {/* Dignissim dolor elementum duis tempus rhoncus consectetur nulla quam
        massa */}
      </div>
      <Row className="mt-3">
        <Col md={3}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>{t("Modules.User management (+ add new).First Name")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>

            <bootstrapForm.Control type="text"
              name="first_name"
              isInvalid={props.touched.first_name && props.error?.first_name}
              value={props.values?.first_name.trimStart()}
              onChange={props.change}>
            </bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
        <Col md={3}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>{t("Modules.User management (+ add new).Middle Name")}</bootstrapForm.Label>
            <bootstrapForm.Control type="text" name="middle_name" value={props.values?.middle_name ? props.values?.middle_name.trimStart() : props.values?.middle_name}
              onChange={props.change}></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
        <Col md={3}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>{t("Modules.User management (+ add new).Last Name")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
            <bootstrapForm.Control type="text"
              name="last_name"
              isInvalid={props.touched.last_name && props.error?.last_name}
              value={props.values?.last_name.trimStart()}
              onChange={props.change}
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
      </Row>
      {!props.isEditForm && (
        <>
          <Row>
            <Col md={9}>
              <bootstrapForm.Group className={primaryDropdown === 1 ? "z-index-1000" : "z-index-0"}
                onClick={() => {
                  setPrimaryDropdown(1);
                }}
              >
                <bootstrapForm.Label>{t("Modules.User management (+ add new).Role")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                <LanguageDropDown
                  val={roleList}
                  inpName={"role_id"}
                  touched={props.touched}
                  errors={props.error}
                  updateDropdown={props.updateRole}
                  values={props.selectedRole}
                ></LanguageDropDown>
              </bootstrapForm.Group>
            </Col>

            <Col md={6}>
              <bootstrapForm.Group className="z-index-0">
                <CategoryDropDown
                  lang={HotelNameList ? HotelNameList : []}
                  lable={t("Modules.User management (+ add new).Hotel Assignment (multi-select)")}
                  updateform={props.change}
                  inpName={"hotel_ids"}
                  defaultValue={props.dropdownValue}
                  clearValue={(updateContent) => {
                    props.setDropDownValue([])
                    props.setFieldValue('hotel_ids', [])
                  }}
                  touched={props.touched ? props.touched : {}}
                  errors={props.error ? props.error : {}}
                ></CategoryDropDown>
              </bootstrapForm.Group>
            </Col>

          </Row>
          {/* <Row>
            <Col md={9}>
              <bootstrapForm.Group>
                <bootstrapForm.Label>{t("Modules.User management (+ add new).Email")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                <bootstrapForm.Control
               
                  type="email"
                  name="email"
                  isInvalid={props.touched.email && props.error?.email}
                  value={props.values?.email}
                  onChange={props.change}
                ></bootstrapForm.Control>
              </bootstrapForm.Group>
            </Col>
          </Row> */}
          <Row>
            <Col md={9} className="select__control-Z-index">

              <bootstrapForm.Label>{t("Modules.User management (+ add new).Language")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
              <LanguageDropDown
                val={languageList}
                inpName={"language_id"}
                touched={props.touched}
                errors={props.error}
                updateDropdown={props.updateDropdown}
                values={props.value}
              ></LanguageDropDown>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default UserBasicInformation;
