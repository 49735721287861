import actions from "../actions/actions";

const initialState = {
    result: false,
    msg: false,
    success: false,
    isLoading: false,
    serviceList:false,
    serviceById:false,
    isAdd:false,
    isUpdate:false,
    emailService:false,
    types: []
  };
  

  export const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
      
      case actions.FETCH_SERVICE_LIST:{
        return {
            ...state,
          success: true,
          isAdd:false,
          isUpdate:false,
          serviceList: false
        }
      }
      case actions.FETCH_SERVICE_LIST_SUCCESS: {
        state.serviceList = action?.result;
        return {
          ...state,
          success: true,
          isAdd:false,
          isUpdate:false
        };
      }
      case actions.FETCH_SERVICE_LIST_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.FETCH_SERVICE_BY_ID_SUCCESS: {
        state.serviceById = action?.result;
        return {
          ...state,
          success: true,
          isAdd:false,
          isUpdate:false
        };
      }
      case actions.FETCH_SERVICE_BY_ID_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false,
          isAdd:false,
          isUpdate:false
        };
      }

      case actions.ADD_SERVICE_SUCCESS: {
        return {
          ...state,
          success: true,
          isAdd:true,
        };
      }
      case actions.ADD_SERVICE_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.UPDATE_SERVICE_SUCCESS: {
        return {
          ...state,
          success: true,
          isUpdate:true
        };
      }
      case actions.UPDATE_SERVICE_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }

      case actions.EMAIL_SERVICE_SUCCESS: {
        return{
          ...state,
          msg: action?.msg,
          success: true
        }
      }

      case actions.EMAIL_SERVICE_FAILED: {
        return{
          ...state,
          msg: action?.msg,
          success: false
        }
      }

      case actions.GET_EMAIL_SERVICE_SUCCESS: {
        return{
          ...state,
          emailService:action?.data,
          msg: action?.msg,
          success: true
        }
      }

      case actions.GET_EMAIL_SERVICE_FAILED: {
        return{
          ...state,
          msg: action?.msg,
          success: false
        }
      }
      case actions.CLEAR_SERVICE_BY_ID: {
        return{
          ...state,
          serviceById: false
        }
      }

      case actions.GET_DEAL_TYPES_SUCCESS: {
        return {
          ...state,
          types: action.data,
          result: {
            msg: action.msg,
            success: true,
          },
        };
      }

      case actions.GET_DEAL_TYPES_FAILED: {
        return{
          ...state,
          msg: action.msg,
          success: false
        }
      }

      default: {
        return state;
      }
    }
  };