import i18next from 'i18next';
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generateToolTipforCategory } from "../../helper/constants";
import ConfirmWindow from "../Common/ConfirmWindow";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from './../../utils/utils';
import { ReactComponent as SortDownSVG } from "./icons/sorting_down_arrow.svg";
import { ReactComponent as SortUpSVG } from "./icons/sorting_up_arrow.svg";
export const DragNDropTable = (tableMapper = [],
  sortColumns = [],
  currentlySorted = [],
  onSortClick,
  tableExtraActions = [],
  warningMessage,
  sortingColumnName = "",test) => {
  const [records, setRecords] = useState();
  const [draggedData, setDraggedData] = useState();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation();
  const langId = useSelector(state => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(langId.filter(option => option.language.key === i18next.language)[0]?.language?.id);
  const [TableheaderDescription,setheaderDescription] = useState({});
  useEffect(() => {
    setRecords(tableMapper?.tableMapper)
    setheaderDescription(tableMapper.headerDescription)
  }, [tableMapper?.tableMapper]);
  const dispatch = useDispatch();
  const onDrag = (result) => {
    if (!result.destination) {
      return;
    }
    const content = reorder(
      records,
      result.source.index,
      result.destination.index
    );
    setRecords(content);
    getDragedRecord(content)
  };
  const getDragedRecord = (content)=>{
      setDraggedData(content)
      setModalShow(true)
  }
  i18next.on("languageChanged", (selectedLanguage) => {
    if(selectedLanguage){
      const langFiltered = langId.filter(option => option.language.key === selectedLanguage)
      onLanguageSelect(langFiltered[0]?.language?.id)
    }
  })
  const onLanguageSelect = (selectedLanguage) => {
    if(selectedLanguage){
      setDefaultLanguage(selectedLanguage);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const removeHeaders = ["id"];
  const isNotOverride = () => {
       setModalShow(false);
       window.location.reload();
  };
  const isOverride = () => {
    const Drageddata = Utils.getDraggedContent(draggedData);
    dispatch(actions.sortCategories(hotelId,Drageddata));
    setModalShow(false);
  };
  const setupSortingHeaders = (headerInfo) => {
    return (
      <>
        <span
          onClick={(event) => {
            onSortClick(event, headerInfo);
          }}
          className="pointer noWrap"
        >
          {headerInfo}
          {headerInfo === sortingColumnName && (
            <span>
              {currentlySorted !== "" && currentlySorted[headerInfo] === true && (
                <span className="p-2">
                  <SortUpSVG />
                </span>
              )}
              {currentlySorted !== "" && currentlySorted[headerInfo] === false && (
                <span className="p-2">
                  <SortDownSVG />
                </span>
              )}
            </span>
          )}
        </span>
     
      </>
    );
  };
  const setupHeaders = (headerInfo) => {
    const headerDescription = TableheaderDescription?TableheaderDescription:{}
    let genToolTip = generateToolTipforCategory((headerInfo in headerDescription)?(`${headerDescription[headerInfo]}`):(""))
    return tableMapper.sortColumns.includes(headerInfo) ? (
      <> {setupSortingHeaders(headerInfo,genToolTip)} </>
    ) : (
      <span>{headerInfo}&nbsp;{genToolTip}</span>
    );
  };
  const tableBodyContent = () => {
    return (
      <>
        {records.map((bodyContent, index) => (
          <>
          <Draggable draggableId={`draggable-${index}`} index={index} key={index}>
            {(provided) => (
              <tr key={`table-bodies-${index}`}  {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}>
                {Object.keys(bodyContent).map(
                  (bodyDetail, colIndex) =>
                    !removeHeaders.includes(bodyDetail) && (
                      <td key={`table-body-${colIndex}`}>
                        {bodyContent[bodyDetail]}
                      </td>
                    )
                )}
                {tableMapper.tableExtraActions.map((action, index) => (
                  <td
                    onClick={() => {
                      action.onClick(bodyContent?.id);
                    }}
                    key={`table-actions-${index}`}
                  >
                    {action?.icon}
                  </td>
                ))}
              </tr>
            )}
          </Draggable>
          </>
        ))}
           <ConfirmWindow
              show={modalShow}
              onHide={() => isNotOverride()}
              isoverride={isOverride}
              isnotoverride={isNotOverride}
              header={"Confirm"}
              yesButtonLable={'Yes'}
              noButtonLable={"No"}
              body={t("Modules.Room categories (main page).Category - Sequence update - Warning message")}
            />
      </>
    );
  };
  const loadTable = () => (
    <>
      <DragDropContext onDragEnd={onDrag}>
        <Table responsive striped borderless>
          <thead>
            <tr>
              {Object.keys(tableMapper.tableMapper[0]).map(
                (headerInfo, index) =>
                  !removeHeaders.includes(headerInfo) && (
                    <th key={`table-header-${index}`}>
                      {setupHeaders(headerInfo)}
                    </th>
                  )
              )}
              <th>
                {tableMapper.tableExtraActions && tableMapper.tableExtraActions.length ? "ACTION" : ""}
              </th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-table-id" >
            {(provided) => (
              <tbody ref={provided.innerRef}
                {...provided.droppableProps} className="text-capitalize">{tableBodyContent()}</tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </>
  );
  const noDataAvailable = () => (
    <>
      <div className="container content">No Records to show</div>
    </>
  );
  return tableMapper && tableMapper.tableMapper.length ? loadTable() : noDataAvailable();
};
