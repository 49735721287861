import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { STATIC_HOTEL_ID } from "../../helper/constants";
import { StorageService } from "./../../services/storage.service";
import { ToastService } from "./../../services/toast.service";
import actions from "./../../store/actions/actions";
import Spinner from "./Spinner";

export const SetupHotelId = () => {
  // setting up the default hotel id.
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const hotelGroupList = useSelector(
    (state) => state?.hotelmanagement?.hotelGroupList
  );

  const [hotelId, setHotelId] = useState(
    +storageService.getItem("hotelGroupId") || +STATIC_HOTEL_ID
  );
  const toast = new ToastService();
  const onHotelIdSave = (e) => {
    const hotelId = e.value;
    storageService.setItem("hotelGroupId", hotelId);
    dispatch(actions.getLanguages(hotelId));
    setHotelId(hotelId);
    toast.onSuccess("Hotel Id updated");
  };

  useEffect(() => {
    storageService.setItem("hotelGroupId", hotelId);
    dispatch(actions.getHotelGroupList());
  }, []);

  return (
    <div className="content mt-2">
      {hotelGroupList && hotelGroupList.length ? (
        <Select
          value={hotelGroupList.filter((option) => option.value === hotelId)}
          onChange={onHotelIdSave}
          options={hotelGroupList}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
