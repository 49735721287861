import * as authAction from "./auth.action";
import * as AuthrAction from "./authr.action";
import * as booking from "./booking.action";
import * as constantsActions from "./constants.action";
import * as floorActions from "./floor.actions";
import * as hotelDetails from "./hotelDetails.action";
import * as hotelmanagementAction from "./hotelmanagement.action";
import * as hotelRoom from "./hotelRoom.action";
import * as languageSettingActions from "./LanguageSettings.actions";
import * as networkAction from "./network.action";
import * as offerAction from "./offers.action";
import * as bookingReport from "./report.action";
import * as roomCategoriesAction from "./roomCategories.action";
import * as seasonPricing from "./seasonPricing.actions";
import * as serviceAction from "./service.action";
import * as setLanguage from "./setLanguage.action";
import * as translationTab from "./translation.action";
import * as userManagement from "./userManagement.action";

const actions = {
  ...constantsActions,
  ...hotelmanagementAction,
  ...authAction,
  ...networkAction,
  ...hotelDetails,
  ...roomCategoriesAction,
  ...floorActions,
  ...languageSettingActions,
  ...hotelRoom,
  ...serviceAction,
  ...offerAction,
  ...seasonPricing,
  ...translationTab,
  ...userManagement,
  ...bookingReport,
  ...AuthrAction,
  ...setLanguage,
  ...booking
};

export default actions;
