import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  error: false,
  isUpdate: false
};

export const HotelSeasonPricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_HOTEL_SEASON_PRICING_LIST: {
      return {
        ...state,
        result: false,
        success: false,
        error: false,
        isUpdate: false
      };
    }
    case actions.FETCH_HOTEL_SEASON_PRICING_LIST_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        msg: action?.result?.msg,
        success: true,
        error: false,
        isUpdate: false
      };
    }
    case actions.FETCH_HOTEL_SEASON_PRICING_LIST_FAILED: {
      return {
        ...state,
        success: false,
        error: false,
        isUpdate: false
      };
    }
    case actions.FETCH_HOTEL_SEASON_PRICING_DETAIL: {
      return {
        ...state,
        result: false,
        success: false,
        error: false,
        isUpdate: false
      };
    }
    case actions.FETCH_HOTEL_SEASON_PRICING_DETAIL_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        msg: action?.result?.msg,
        success: true,
        error: false,
        isUpdate: false
      };
    }
    case actions.FETCH_HOTEL_SEASON_PRICING_DETAIL_FAILED: {
      return {
        ...state,
        success: false,
        error: false,
        isUpdate: false
      };
    }
    case actions.EDIT_HOTEL_SEASON_PRICING: {
      return {
        ...state,
        success: false,
        error: false,
        isUpdate: false
      };
    }
    case actions.EDIT_HOTEL_SEASON_PRICING_SUCCESS: {
      return {
        ...state,
        success: true,
        error: false,
        isUpdate: true
      };
    }
    case actions.EDIT_HOTEL_SEASON_PRICING_FAILED: {
      return {
        ...state,
        success: false,
        error: action?.data,
        isUpdate: false
      };
    }
    case actions.CREATE_HOTEL_SEASON_PRICING: {
        return {
          ...state,
          result: false,
          success: false,
          error: false,
          isUpdate: false
        };
      }
      case actions.CREATE_HOTEL_SEASON_PRICING_SUCCESS: {
        return {
          ...state,
          result: action?.data?.data,
          msg: action?.result?.msg,
          success: true,
          error: false,
          isUpdate: false
        };
      }
      case actions.CREATE_HOTEL_SEASON_PRICING_FAILED: {
        return {
          ...state,
          success: false,
          error: action?.data,
          isUpdate: false
        };
      }
    default:
      return state;
  }
};
