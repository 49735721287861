import constanceAxios from "../constantsInstance"

class ConstantsService {
 
  fetchGetCountries() {
    return constanceAxios.get(`/countries`);
  }

  fetchGetStates(country_id) {
    return constanceAxios.get(`/states/${country_id}`)
  }

}

export default ConstantsService;