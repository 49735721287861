import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "../../store/actions/actions";
import ActiveStatusButton from "../Common/ActiveStatusButton";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as LocationSVG } from "../Common/icons/location.svg";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import SearchComponents from "../Common/SearchComponents";
import Spinner from "../Common/Spinner";
import StatusButton from "../Common/StatusButton";
import { Utils } from "./../../utils/utils";
import PaginationComponent from "./../Common/PaginationComponent";

export const HotelRoomList = (props) => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(actions.setSearchFloorList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
    dispatch(actions.setSearchUserList(""))
  },[])

  const langId = useSelector((state) => state.constants.languages);
  const { t } = useTranslation();

  const roomSearchTerm = useSelector((state)=>state?.common?.roomSearch)

  const [searchTerm, setSearchTerm] = useState(roomSearchTerm || "");
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const [headerDescription, setHeaderDescription] = useState();
  const [allowAccess, setAllowAccess] = useState(true);

  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const isLoading = useSelector((state) => state?.hotelRoomAdd?.isLoading);
  const hotelRoomList = useSelector(
    (state) => state?.hotelRoomAdd?.result?.records
  );

  //get Page no,SortBy and SortColumn from reducer
  const hotelListCommonDetails = useSelector(
    (state) => state?.common
  );
  const [pageNo, setPageNo] = useState(hotelListCommonDetails.roomListPageNo);
  const [sortingColumnName, setSortingColumnName] = useState(hotelListCommonDetails.roomColumnName);
  const [sortOrder, setSortOrder] = useState(hotelListCommonDetails.roomSortBy);

//get object from reducer it use for asc and desc arrow in list table
  const sortColumn = useSelector(
    (state) => state?.common.sortForRoom
  );
  const [sort, setSort] = useState(sortColumn);

  const hotel_id = localStorage.getItem("hotelId");
  const columnDataMap = {
    "ROOM NAME": "name",
    "ROOM NUMBER": "pms_room_number",
    "FLOOR": "floor",
    "ROOM CATEGORY": "category",
    "STATUS": "active"
  };
  const columnMapForReducer = {
    "name": "ROOM NAME",
    "pms_room_number": "ROOM NUMBER",
    "floor": "FLOOR",
    "category": "ROOM CATEGORY",
  };

  const sortColumns = [
    t("Modules.Rooms List.Room Name AZ"),
    t("Modules.Rooms List.Room Number AZ"),
    t("Modules.Rooms List.Floor AZ Column"),
    t("Modules.Rooms List.Room category  AZ"),
    t("Modules.Rooms List.Status")
  ];

  const count = useSelector((state) => state?.hotelRoomAdd?.result?.totalCount);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_create))
    if (count) {
      setTotalCount(count);
    }
  }, [count]);


  useEffect(() => {
    if (defaultSelectedLanguage) {
      getTableInfo(
        roomSearchTerm || "",
        hotelListCommonDetails.roomListPageNo ? hotelListCommonDetails.roomListPageNo : pageNo,
        hotelListCommonDetails.roomSortColumnName ? hotelListCommonDetails.roomSortColumnName : columnDataMap[sortingColumnName] || '',
        hotelListCommonDetails.roomSortBy ? hotelListCommonDetails.roomSortBy : sortOrder,
        defaultSelectedLanguage
      );
    }
  }, [defaultSelectedLanguage]);

  useEffect(() => {
    setDefaultLanguage(
      langId.filter((option) => option.language.key === i18next.language)[0]
        ?.language?.id
    );
  }, [langId]);
  useEffect(() => {
    if(hotelListCommonDetails){
      //set current page no 
      setPageNo(hotelListCommonDetails.roomListPageNo)
    }
  
  }, [hotelListCommonDetails]);


  useEffect(() => {
    if (hotelRoomList) {
      mapTableInfo();
    }
  }, [hotelRoomList, defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );

    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };

  const mapTableInfo = () => {
    const mapper = hotelRoomList.map((roomInfo, index) => {
      const tableHeader = {
        id: roomInfo.id,
      };
      tableHeader[
        `${t("Modules.Rooms List.Room Name AZ")}`
      ] = roomInfo?.room_name.trim().length > 0 ? (roomInfo?.room_name) : (Utils.langValueGenerator(
        roomInfo?.rooms_ts_en,
        "name",
        defaultSelectedLanguage
      ));
      tableHeader[`${t("Modules.Rooms List.Room Number AZ")}`] =
        roomInfo?.pms_room?.name; // pms_id
      tableHeader[
        `${t("Modules.Rooms List.Floor AZ Column")}`
      ] = roomInfo?.floor_name?.trim().length > 0 ? (roomInfo?.floor_name) : (Utils.langValueGenerator(
        roomInfo?.floor?.h_floor_ts_en,
        "name",
        defaultSelectedLanguage
      ));
      tableHeader[
        `${t("Modules.Rooms List.Room category  AZ")}`
      ] = roomInfo?.category_name?.trim().length > 0 ? (roomInfo?.category_name) : (Utils.langValueGenerator(
        roomInfo?.category?.category_ts_en,
        "name",
        defaultSelectedLanguage
      ));
      tableHeader[`${t("Modules.Rooms List.Status")}`] = (
        <StatusButton pending={roomInfo?.status}></StatusButton>
      );
      tableHeader[`${t("Modules.Rooms List.Active")}`] = (
        <ActiveStatusButton pending={roomInfo?.is_active}></ActiveStatusButton>
      );
      return tableHeader;
    });

    const tableHeaderDesc = {
      [`${t("Modules.Rooms List.Status")}`]: t(
        "Modules.Rooms List.Status - Question mark"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };

  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      defaultSelectedLanguage
    );
  };

  const redirectToLocation = (event) => {
    let room_id_selected = event;
    let floor_id_selected = hotelRoomList.filter((data) => data.id === event)[0]
      .floor_id;
    //props.history.push(`/assign-room/${floor_id_selected}/${room_id_selected}`)
    props.history.push({
      pathname: `/assign-room/${floor_id_selected}/${room_id_selected}`,
      state: {
        RoomName: hotelRoomList?.filter((data) => data.id === event)[0]
          ?.rooms_ts[0]?.name,
      },
    });
  };

  const onEditRow = (roomId) => {
    props.history.push("/update-room/" + roomId);
  };

  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
    {
      icon: <LocationSVG className={"svg-s25"} />,
      onClick: redirectToLocation,
    },
  ];

  const onSearchEvent = (searchTxt = "") => {
    dispatch(actions.setSearchHotelRoomList(searchTxt))
    setPageNo(1);
    setSearchTerm(searchTxt);
    getTableInfo(
      searchTxt || "",
      1,
      columnDataMap[sortingColumnName] || "",
      sortOrder || "",
      defaultSelectedLanguage
    );
  };

  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    selectedLang = defaultSelectedLanguage
  ) => {
    const payload = {
      searchKey: searchTerm,
      hotel_id: hotel_id,
    };
    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }

    if (selectedLang) {
      payload.selectedLang = selectedLang;
    }

    dispatch(
      actions.getRoomList(
        payload.page,
        10,
        payload.hotel_id,
        payload.searchKey,
        payload.sortBy,
        payload.sort,
        0,
        payload.selectedLang
      )
    );
  };

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[sortingColumnName],
      sortOrder,
      defaultSelectedLanguage
    );
  };
  return (
    <React.Fragment>
      <div className="content mt-3">
        <Row>
          <Col>
            <SearchComponents
              buttonLabel={t("Modules.Rooms List.Add new room")}
              addBtn={allowAccess}
              search={onSearchEvent}
              path={"/add-room"}
              commonSearchTerm={roomSearchTerm}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              sortColumns={sortColumns}
              currentlySorted={sort}
              //get sorting column name from reducer state
              sortingColumnName={sortingColumnName}
              onSortClick={sortingClick}
              tableExtraActions={allowAccess ? tableExtraActions : []}
              tableMapper={tableMapper}
              isLoading={isLoading}
              headerDescription={headerDescription}
              component={"Rooms List"}
            />
          </Col>
        </Row>
        {!isLoading ? (
          <>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={pageNo}
                  totalCount={Math.ceil(totalCount / 10)}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </React.Fragment>
  );
};

export default HotelRoomList;
