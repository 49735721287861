import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
const ConfirmWindow = (props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className={props.className ? props.className : ""}
      >
        <div className="mt-3">
          <Row>
            <Col md={2}></Col>
            <Col md={8} className="alignCenter"></Col>
            <Col md={2} className="pl-3">
              <span onClick={props.onHide} className="pointer">
                <CloseIcon />
              </span>
            </Col>
          </Row>
        </div>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
          {props.yesButtonLable && (
            <Button onClick={props.isoverride}>{props.yesButtonLable}</Button>
          )}
          <Button onClick={props.isreplaceAll}>
            {props.replaceButtonLable}
          </Button>
          <Button onClick={props.isnotoverride}>{props.noButtonLable}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmWindow;
