export const GET_CONSTANTS = "GET_CONSTANTS";
export const FETCH_CONSTANTS_SUCCESS = "FETCH_CONSTANTS_SUCCESS";
export const FETCH_CONSTANTS_FAILED = "FETCH_CONSTANTS_FAILED";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_FAILED = "FETCH_LANGUAGES_FAILED";

export const GET_CURRENCIES = "GET_CURRENCIES";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILED = "FETCH_CURRENCIES_FAILED";

export const GET_PMS_HOTEL = "GET_PMS_HOTEL";
export const GET_PMS_HOTEL_SUCCESS = "GET_PMS_HOTEL_SUCCESS";
export const GET_PMS_HOTEL_FAILED = "GET_PMS_HOTEL_FAILED";

export const GET_HOTEL_ROOM_CATEGORY = "GET_HOTEL_ROOM_CATEGORY";
export const GET_HOTEL_ROOM_CATEGORY_SUCCESS =
  "GET_HOTEL_ROOM_CATEGORY_SUCCESS";
export const GET_HOTEL_ROOM_CATEGORY_FAILED = "GET_HOTEL_ROOM_CATEGORY_FAILED";

export const GET_HOTEL_FLOOR_ASSIGN = "GET_HOTEL_FLOOR_ASSIGN";
export const GET_HOTEL_FLOOR_ASSIGN_SUCCESS = "GET_HOTEL_FLOOR_ASSIGN_SUCCESS";
export const GET_HOTEL_FLOOR_ASSIGN_FAILED = "GET_HOTEL_FLOOR_ASSIGN_FAILED";

//
export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILED =  'GET_COUNTRY_SUCCESS'

export const GET_STATES = 'GET_STATES'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAILED =  'GET_STATES_FAILED'

export const GET_HOTEL_LIST_HOTELGROUP = "GET_HOTEL_LIST_HOTELGROUP"
export const GET_HOTEL_LIST_HOTELGROUP_SUCCESS = "GET_HOTEL_LIST_HOTELGROUP_SUCCESS"
export const GET_HOTEL_LIST_HOTELGROUP_FAILED = "GET_HOTEL_LIST_HOTELGROUP_FAILED"

export const getLanguages = (hotelId) => {
  return {
    type: GET_LANGUAGES,
    hotelId,
  };
};

export const getCurrencies = () => {
  return {
    type: GET_CURRENCIES,
  };
};

export const getConstantsData = (fields) => {
  return {
    type: GET_CONSTANTS,
    fields,
  };
};

export const pmsHotelId = (hotelId) => {
  return {
    type: GET_PMS_HOTEL,
    hotelId,
  };
};

export const hotelRoomCategory = (hotelId, defaultSelectedLanguage) => {
  return {
    type: GET_HOTEL_ROOM_CATEGORY,
    hotelId,
    defaultSelectedLanguage
  };
};

export const assignToFloor = (hotelId) => {
  return {
    type: GET_HOTEL_FLOOR_ASSIGN,
    hotelId,
  };
};

//
export const getCountries = () =>{
  return {
      type: GET_COUNTRY
  }
}

export const getStates = (country_id)=>{
  return {
      type : GET_STATES,
      country_id
  }
}


export const getHotelFromHotelGroup = (hotelGroupId)=>{
  return{
    type: GET_HOTEL_LIST_HOTELGROUP,
    hotelGroupId
  }
}