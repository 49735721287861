import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import { Utils } from "../../utils/utils";
import ActiveStatusButton from "../Common/ActiveStatusButton";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import Spinner from "../Common/Spinner";
import actions from "./../../store/actions/actions";
import Breadcrumbs from "./../Common/Breadcrumbs";
import PaginationComponent from "./../Common/PaginationComponent";
import SearchComponents from "./../Common/SearchComponents";

const UserList = (props) => {
  //New
  const { t, i18n } = useTranslation();
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const [allowAccess, setAllowAccess] = useState(true);
  const storageService = new StorageService();
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
  },[])

  const userSearchTerm = useSelector((state)=>state?.common?.userSearch)

  const [searchTerm, setSearchTerm] = useState(userSearchTerm || "");
  const [searchKey, setSearchKey] = useState("");
  const userList = useSelector((state) => state.user.userList);
  const breadcrumbs = [{ path: "/user/list", title: "User management" }];
  const hotelGroupId = +storageService.getItem("hotelGroupId");
  const columnDataMap = {
    USERNAME: "username",
    EMAIL: "email",
    ROLE: "role",
    LANGUAGE: "code",
  };
  const sortColumns = [
    t("Modules.User Management.Username"),
    t("Modules.User Management.Email"),
    t("Modules.User Management.Role"),
    t("Modules.User Management.Language")
  ];
  const onSearchEvent = (searchTxt = "") => {
    dispatch(actions.setSearchUserList(searchTxt))
    setSearchTerm(searchTxt);
    const sortBy = columnDataMap[sortingColumnName];
    setPageNo(1);
    getTableInfo(searchTxt, 1, sortBy, sortOrder, "");
  };
  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(searchTerm, pageNo, columnDataMap[headerInfo], sortOrder);
  };
  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = ""
  ) => {
    const payload = {
      searchKey: searchTerm,
    };

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }
    dispatch(actions.getUsers(hotelGroupId,{ ...payload }));
  };
  const onEditRow = (categoryId) => {
    history.push("/update-user/" + categoryId);
  };
  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  // redirecting to update user
  const edit_row = (event) => {
    props.history.push("/user/update/" + event.target.id);
  };

  const mapTableInfo = (userList) => {
    const mapper = userList?.map((user, index) => {
      const tableHeader = {
        id: user.id,
      };
      const userName =
        `${user.first_name}` +
        ` ${user.middle_name ? user.middle_name : ""}` +
        ` ${user.last_name}`;

      tableHeader[`${t("Modules.User Management.Username")}`] = userName;
      tableHeader[`${t("Modules.User Management.Email")}`] = user?.email;
      tableHeader[`${t("Modules.User Management.Role")}`] = user['h_user_roles.role.name'];
      tableHeader[`${t("Modules.User Management.Language")}`] = user?.code
      tableHeader[`${t("Modules.User Management.Status")}`] = <ActiveStatusButton pending={user?.is_active}></ActiveStatusButton>
      return tableHeader;
    });
    setTableMapper(mapper);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

    //get Page no,SortBy and SortColumn from reducer
    const userListCommonDetails = useSelector(
      (state) => state?.common
    );
  
    const [pageNo, setPageNo] = useState(userListCommonDetails.userListPageNo);
    const [sortOrder, setSortOrder] = useState(userListCommonDetails.userSortBy);
    const [sortingColumnName, setSortingColumnName] = useState(userListCommonDetails.userColumnName);
  
    const sortColumn = useSelector(
      (state) => state?.common.sortForUser
    );
    const [sort, setSort] = useState(sortColumn);

    useEffect(() => {
      if (userListCommonDetails) {
        setPageNo(userListCommonDetails.userListPageNo)
      }
    }, [userListCommonDetails]);  
  useEffect(() => {
    getTableInfo(userSearchTerm || "",
    userListCommonDetails.userListPageNo ? userListCommonDetails.userListPageNo : pageNo,
    userListCommonDetails.userSortColumnName ? userListCommonDetails.userSortColumnName : columnDataMap[sortingColumnName],
    userListCommonDetails.userSortBy ? userListCommonDetails.userSortBy : sortOrder,
    "");
  }, []);

  useEffect(() => {
    userList.records ? mapTableInfo(userList.records) : mapTableInfo([]);
        setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.user_create))

    setTotalCount(userList?.totalCount);
  }, [userList]);

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[sortingColumnName],
      sortOrder
    );
  };

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.User Management.User Management")}</h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col>
            <Row>
              <Col>
                <SearchComponents
                  buttonLabel={t("Modules.User Management.+ Add new user")}
                  addBtn={allowAccess}
                  path={"/user/create"}
                  search={onSearchEvent}
                  commonSearchTerm={userSearchTerm}
                />
              </Col>
            </Row>
            {userList ? ( <Row>
              <Col>
                <BootstrapTable
                  sortColumns={sortColumns}
                  currentlySorted={sort}
                  sortingColumnName={sortingColumnName}
                  onSortClick={sortingClick}
                  tableExtraActions={allowAccess?tableExtraActions:[]}
                  component={"User Management"}
                  tableMapper={tableMapper}
                />
              </Col>
            </Row> ) : (
            <Spinner />
          )}
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={pageNo}
                  totalCount={Math.ceil(totalCount / 10)}
                  onChange={updatePagination}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UserList;
