import { call, put, takeLatest } from "redux-saga/effects";
import {
  removeSessionToken,
  removeToken as removeTokenFunction
} from "../../services/auth.service";
import Authr from "../../services/Authr/index";
import Login from "../../services/Login/index";
import { StorageService } from "../../services/storage.service";
import actions from "../actions/actions";
const storageService = new StorageService();

const login = async (credential) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.login(credential);
    return response;
  } catch (err) {
    throw err;
  }
};

const logOut = async (accessToken) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.signOut(accessToken);
    return response;
  } catch (err) {
    throw err;
  }
};

const forgotPassword = async (emailId) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.forgotPassword(emailId);
    return response;
  } catch (err) {
    throw err;
  }
};
const resetPassword = async (emailId) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.resetPassword(emailId);
    return response;
  } catch (err) {
    throw err;
  }
};
const confirmPassword = async (payload) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.confirmPassword(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const setPassword = async (payload) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.setPassword(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const changePassword = async (payload) => {
  try {
    let LoginServe = new Login();
    const response = await LoginServe.changePassword(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const deactivateUser = async (payload) => {
  try {
    let AuthrServe = new Authr();
    const response = await AuthrServe.deactivateUser(payload);
    return response;
  } catch (err) {
    throw err;
  }
};
const activateUser = async (payload) => {
  try {
    let AuthrServe = new Authr();
    const response = await AuthrServe.activateUser(payload);
    return response;
  } catch (err) {
    throw err;
  }
};


function* dispatchLogin(action) {
  try {
    const response = yield call(login, action.credential);
    const email_id = action.credential.email;
    if (response.data.success) {
      removeSessionToken();
      // setting up the token
      yield put({
        type: actions.RESET_SORT_BY,
      });
      yield put({
        type: actions.LOGIN_SUCESS,
        msg: response.data.msg,
        token: response.data?.data,
      });
      
      // storageService.setItem(
      //   "user-first-name",
      //   response.data?.data?.userDetails?.first_name
      // );
      if (response.data?.data?.AuthenticationResult?.AccessToken) {
        yield put({
          type: actions.SET_TOKEN,
          token: response.data.data.AuthenticationResult.AccessToken,
          authTokens: response.data.data.AuthenticationResult,
          pages: response.data.data.userDetails.pages,
          userDetails: response.data.data.userDetails,
          dashboardLinks: JSON.stringify(response.data.data.userDetails?.dashboard_link)
        });
        yield put({
          type: actions.API_SUCCESS,
          data: response,
        });
      } else {
        yield put({
          type: actions.SET_SESSION_TOKEN,
          token: response.data?.data?.Session,
        });
        yield put({
          type: actions.SET_EMAIL,
          msg: response.data.msg,
          emailId: email_id,
        });
        yield put({ type: actions.API_SUCCESS });
      }
    } else {
      yield put({
        type: actions.LOGIN_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.LOGIN_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchLogOut(action) {
  try {
    const response = yield call(logOut, action.accessToken);
    if (response.data.success) {
      yield put({ type: actions.API_FETCHING });
      yield put({
        type: actions.CLEAR_FLOOR_STATE,
      });
      yield put({
        type: actions.CLEAR_ROOM_LIST_STATE,
      });
      yield put({
        type: actions.CLEAR_USER_STATE,
      });
      yield put({
        type: actions.RESET_SORT_BY,
      });
      yield put({
        type: actions.LOGOUT_SUCESS,
        msg: response.data.msg,
      });
    
      removeTokenFunction();
      
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      yield put({ type: actions.RESET_HOTEL_MANAGEMENT_STATE});
    } else {
      yield put({
        type: actions.LOGOUT_FAILED,
        msg: response?.data.msg,
      });
      removeTokenFunction();
      yield put({ type: actions.API_FAILED });
      yield put({ type: actions.RESET_HOTEL_MANAGEMENT_STATE});
    }
  } catch (error) {
    yield put({
      type: actions.LOGOUT_FAILED,
      msg: error?.response?.data.msg,
    });
    removeTokenFunction();
    yield put({ type: actions.RESET_HOTEL_MANAGEMENT_STATE});
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchforgotPassword(action) {
  const email_id = action.emailId;
  try {
    const response = yield call(forgotPassword, action.emailId);
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({
        type: actions.FORGOT_PASSWORD_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      yield put({
        type: actions.SET_EMAIL,
        msg: response.data.msg,
        emailId: email_id.email,
      });
    } else {
      yield put({
        type: actions.FORGOT_PASSWORD_FAILED,
        msg: response.data.msg,
      });
      yield put({ type: actions.API_FAILED });
    }
  } catch (error) {
    yield put({
      type: actions.FORGOT_PASSWORD_FAILED,
      msg: error.response.data.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchResetPassword(action) {
  try {
    const response = yield call(resetPassword, action.emailId);
    if (response.data.success) {
      yield put({
        isResetPasswod: response.data.success,
        type: actions.RESET_PASSWORD_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: actions.RESET_PASSWORD_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.RESET_PASSWORD_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}
function* dispatchSetPassword(action) {
  try {
    const response = yield call(setPassword, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.SET_PASSWORD_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      removeSessionToken();
    } else {
      yield put({
        type: actions.SET_PASSWORD_SUCESS_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SET_PASSWORD_SUCESS_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchconfirmPassword(action) {
  try {
    const response = yield call(confirmPassword, action.payload);
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({
        type: actions.CONFIRM_PASSWORD_SUCESS,
        msg: response.data.msg,
      });
      yield put({ type: actions.API_SUCCESS, data: response});
    } else {
      yield put({
        type: actions.CONFIRM_PASSWORD_FAILED,
        msg: response.data.msg,
      });
      yield put({ type: actions.API_FAILED });
    }
  } catch (error) {
    yield put({
      type: actions.CONFIRM_PASSWORD_FAILED,
      msg: error?.response?.data.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}
function* dispatchChangePassword(action) {
  try {
    const response = yield call(changePassword, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.CHANGE_PASSWORD_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });

      removeTokenFunction();
    } else {
      yield put({
        type: actions.CHANGE_PASSWORD_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.CHANGE_PASSWORD_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}
function* dispatchDeactivateUser(action) {
  try {
    const response = yield call(deactivateUser, action.payload);
    
    
    const userId = action?.payload?.id
    if (response.data.success) {
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      yield put({
        type: actions.DEACTIVATE_USER_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type:actions.GET_USER_BY_ID,
        userId:userId
      })
     
    }else{
      yield put({
        type: actions.DEACTIVATE_USER_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.DEACTIVATE_USER_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchActivateUser(action) {
  try {
    const response = yield call(activateUser, action.payload);
    const userId = action?.payload?.id
    if (response.data.success) {
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      yield put({
        type: actions.ACTIVATE_USER_SUCESS,
        msg: response.data.msg,
      });
      yield put({
        type:actions.GET_USER_BY_ID,
        userId:userId
      })
    } else {
      yield put({
        type: actions.ACTIVATE_USER_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ACTIVATE_USER_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}




export function* listenLogin() {
  yield takeLatest(actions.LOGIN, dispatchLogin);
}

export function* listenLogOut() {
  yield takeLatest(actions.LOGOUT, dispatchLogOut);
}

export function* listenforgotPassword() {
  yield takeLatest(actions.FORGOT_PASSWORD, dispatchforgotPassword);
}

export function* listenconfirmPassword() {
  yield takeLatest(actions.CONFIRM_PASSWORD, dispatchconfirmPassword);
}

export function* listenResetPassword() {
  yield takeLatest(actions.RESET_PASSWORD, dispatchResetPassword);
}

export function* listenSetPassword() {
  yield takeLatest(actions.SET_PASSWORD, dispatchSetPassword);
}

export function* listenChangePassword() {
  yield takeLatest(actions.CHANGE_PASSWORD, dispatchChangePassword);
}

export function* listenDeactivateUser() {
  yield takeLatest(actions.DEACTIVATE_USER, dispatchDeactivateUser);
}

export function* listenActivateUser() {
  yield takeLatest(actions.ACTIVATE_USER, dispatchActivateUser);
}

