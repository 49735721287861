import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ExtraServicesList from '../ExtraServices/ExtraServicesList';
import SpecialOffersList from '../SpecialOffres/SpecialOffersList';
export const DealsAndOffer = (props) => {
    return (
        <React.Fragment>
            <div className="content mt-3">
                <Row>
                    <Col>
                        <ExtraServicesList />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Row>
                        <Col>
                            <SpecialOffersList />
                        </Col>
                    </Row>
                </Row>
            </div>

        </React.Fragment>
    )
}