import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../Common/icons/close.svg";
import { ToastService } from "./../../services/toast.service";
import { Utils } from "./../../utils/utils";

export const AddSingleTags = ({
  updateTags,
  removeTags,
  existingTags,
  setNewTags,
  selectedLanguage,
  allowAccess = true,
}) => {
  const { t } = useTranslation();
  const [inputTag, setInputTag] = useState("");
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  const toastService = new ToastService();

  useEffect(() => {
    let tags = [];
    // if language exist then
    if (+selectedLanguage) {
      tags = availableTags(selectedLanguage);
      
      if (!tags.length) {
        
        tags = availableTags(availableFilteredLanguages[0]?.language?.id);
      }
      tags = [].concat.apply([], tags);
      
    } else {
      tags = existingTags?.map((tagInfo) => {
        return tagInfo.name;
      });
    }
    const appendTags = updateTags.tags?.length ? [] : updateTags.tags;
    updateTags.setTags([...appendTags, ...tags]);
  }, [existingTags, selectedLanguage]);

  const availableTags = (langId) => {
    const availableTags = existingTags?.map((tagInfo) => {
      /* return tagInfo?.tags_ts
        ?.filter((translatedTag) => {
          let flagFound = false
          if(+translatedTag.language_id === +langId &&
            translatedTag?.name?.trim().length){
              flagFound=true
              return (
                +translatedTag.language_id === +langId &&
                translatedTag?.name?.trim().length
              );
            }
        })
        .map((info) => {
          if (info) {
            return info.name;
          }
        }); */

        return Utils.langValueGenerator(tagInfo?.tags_ts, "name", +langId )
    });
    return availableTags.filter((value) => value.length);
  };

  const softDeleteTag = (tags, tagName) => {
    updateTags.setTags(tags.filter((tag, i) => tag !== tagName));
  };

  const removeTag = (tagName) => {
    const isExistingTag = filterTagsRemove(existingTags, true, tagName);

    if (isExistingTag && isExistingTag?.id) {
      removeTags.setDeletedTags([...removeTags.deletedTags, isExistingTag?.id]);
      softDeleteTag(updateTags.tags, tagName);
     
    } else {
      softDeleteTag(updateTags.tags, tagName);
    }

    const isNewTagExist = setNewTags.newTags.find((tagInfo) => {
      return tagName.trim() === tagInfo.trim();
    });

    if (isNewTagExist) {
      const newTags = setNewTags.newTags;
      const existingIdInTagIndex = newTags.indexOf(isNewTagExist);
      // removing from delete if already exist
      if (existingIdInTagIndex !== -1) {
        newTags.splice(existingIdInTagIndex, 1);
        setNewTags.setNewTags([...newTags]);
      }
    }
  };

  // finding filter tag and returning existing one
  const filterTags = (tagArray, isByName = false, tagName) => {
    const isExist = tagArray.find((tagInfo) => {
      const baseTag = (tagInfo?.tags_ts || []).filter(
        (tag) => tag.language_id === 1
      );
      let compareValue = "";
      if (isByName) {
        compareValue = baseTag[0]?.name.toLowerCase().trim();
      } else {
        compareValue = tagInfo.toLowerCase().trim();
      }

      return tagName.toLowerCase().trim() === compareValue;
    });

    return isExist;
  };

  const filterTagsRemove = (tagArray, isByName = false, tagName) => {
    const isExist = tagArray.find((tagInfo) => {
      const baseTag = (tagInfo?.tags_ts || []).filter(
        (tag) => tag.language_id === 1
      );
      let compareValue = "";
      if (isByName) {
        compareValue = baseTag[0]?.name.trim();
      } else {
        compareValue = tagInfo.trim();
      }

      return tagName.trim() === compareValue;
    });

    return isExist;
  };


  const addTagsOnClick = () => {
    if (inputTag.trim()) {
      const isTagExist = filterTags(updateTags.tags, false, inputTag.trim());
      if (!isTagExist) {
        const isActualTagExist = filterTagsRemove(
          existingTags,
          true,
          inputTag.trim()
        );
        if (isActualTagExist && isActualTagExist?.id) {
          const deletedTags = removeTags.deletedTags;
          const existingIdInTagIndex = deletedTags.indexOf(
            isActualTagExist?.id
          );
          // removing from delete if already exist
          if (existingIdInTagIndex !== -1) {
            deletedTags.splice(existingIdInTagIndex, 1);
            removeTags.setDeletedTags([...deletedTags]);
          }
        }

        const isTagExistInAPI = filterTagsRemove(existingTags, true, inputTag.trim());
        if (!isTagExistInAPI) {
          setNewTags.setNewTags([inputTag.trim(), ...setNewTags.newTags]);
        }
        updateTags.setTags([inputTag.trim(), ...updateTags.tags]);
        setInputTag("");
      } else {
        toastService.onWarning("tag already exist");
      }
    }
  };

  return (
    <React.Fragment>
      {allowAccess && (
        <Row>
          <Col md={3} xs={6}>
            <Form.Group>
              <Form.Control
                type="text"
                name="tags"
                onChange={(e) => setInputTag(e.target.value)}
                value={inputTag}
                //placeholder={t("Modules.Basic information.Type your tag")}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={2} xs={3}>
            <Button variant="primary" onClick={addTagsOnClick}>
              {t("Modules.Basic information.Add room feature")}
            </Button>
          </Col>
          <Col md={2} xs={3} className="pt-1">
            <Link to="/hotel-translation">
              <strong>
                {t("Modules.Basic information.Manage translations")}
              </strong>
            </Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12} className="mt-3 noPadding">
          {/* for new tags */}
          {updateTags.tags?.map((tagInfo, index) => {
            return (
              <InputGroup className="mb-3 tags-inline" key={`tags-${index}`}>
                {allowAccess ? (
                  <>
                    <InputGroup.Text
                      onClick={() => {
                        removeTag(tagInfo);
                      }}
                    >
                      <CloseIcon className="pointer" />
                    </InputGroup.Text>
                  </>
                ) : (
                  <></>
                )}
                <Button variant="light">{tagInfo}</Button>
              </InputGroup>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddSingleTags;
