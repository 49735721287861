import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <>
        <div className="m-2 header"> {t('Modules.Contact Us.Contact Us content - Title')}</div>
        <div className="m-3 content">
            {t('Modules.Contact Us.Contact Us content')}
            <div>
               
                <Button className={'m-2'} onClick={()=>{
                    window.open('mailto:support@expect-me.com')
                }}>{t('Modules.Contact Us.Contact Us content - Button')}</Button>
            </div>
        </div>
    </>
  );
};

export default ContactUs;
