import axios from "../services/commonInstance";

class CommonService {

  getLanguage = async () => {
    try {
      const response = await axios.get(`/languages`,{ headers: { client_secret:"879979wIthuoutLOgiNvErIFicAtion9898032"}});
      return response;
    } catch (error) {
      throw error;
    }
  };
    
  fetchHotelList = async (hotelGroupId) => {
    try {
      let response = await axios.get(`/hotels/list?hotel_group_id=${hotelGroupId}`)
      return response
    } catch (error) {
      throw error
    }
  }
}

export default CommonService;
