import React, { useEffect, useState } from "react";
import { Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LanguageDropDown from "../Common/LanguageDropDown";

const UserAccountInformation = (props) => {
    const [languageList, setlanguageList] = useState([]);
    const languages = useSelector(state => state.constants.languages)
    const roles = useSelector(state => state.authr.roles)
    const [roleList, setRoleList] = useState();

    const { t } = useTranslation();
    useEffect(() => {
        if (languages) {
            const langList = languages?.map((lang) => {
                return { value: lang?.language.code, id: lang?.language?.id };
            });
            setlanguageList(langList);
        }
        if(roles){
            const roleList = roles?.map((role) => {
                return { value: role.name, id: role.id };
              });
              setRoleList(roleList);
        }
    }, [languages,roles]);
    return (
        <React.Fragment>
            <div>
                <div className="header">
                    {t("Modules.User management (+ add new).Account information")}
                </div>
                {/* <div className="sub-header-text">
                    Dignissim dolor elementum duis tempus rhoncus consectetur nulla quam massa
                </div> */}
                <Row className="mt-3">
                    <Col md={9} xs={12}>
                        <bootstrapForm.Group>
                            <bootstrapForm.Label> {t("Modules.User management (+ add new).Email")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                            <bootstrapForm.Control
                                type="email"
                                name="email"
                                isInvalid={props.touched.email && props.error?.email}
                                value={props.values?.email}
                                onChange={props.change}
                                disabled
                            ></bootstrapForm.Control>
                        </bootstrapForm.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} xs={12}>
                        <bootstrapForm.Group>
                            <bootstrapForm.Label>{t("Modules.User management (edit).Password")}<span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                            <bootstrapForm.Control
                                type="password"
                                placeholder="**********"
                                name="password"
                                isInvalid={props.touched.password && props.error?.password}
                                value={props.values?.password}
                                onChange={props.change}
                                disabled
                            ></bootstrapForm.Control>
                        </bootstrapForm.Group>
                    </Col>
                    <Col className="reset-password-label">
                        <span onClick={props.resetPassword}> {t("Modules.User management (edit).Change password")}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} xs={12}>
                        <bootstrapForm.Group>
                            <bootstrapForm.Label>{t("Modules.Edit User Information.Role")} <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                            {/* <LanguageDropDown
                                val={roleList}
                                inpName={"role_id"}
                                touched={props.touched}
                                errors={props.error}
                                updateDropdown={props.updateRole}
                                values={props.selectedRole}
                                disabled
                            ></LanguageDropDown> */}
                            <bootstrapForm.Control as="select" defaultValue="Editor" disabled>
                                <option disabled></option>
                                <option value="admin">{props?.selectedRole?.label?props?.selectedRole?.label:''}</option>
                            </bootstrapForm.Control>
                        </bootstrapForm.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} xs={12}>
                        <bootstrapForm.Group>
                            <bootstrapForm.Label>Language <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                            <LanguageDropDown
                                val={languageList}
                                inpName={"language_id"}
                                touched={props.touched}
                                errors={props.error}
                                updateDropdown={props.updateDropdown}
                                values={props.value}
                            ></LanguageDropDown>
                        </bootstrapForm.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default UserAccountInformation;