import React from "react";
import Select from "react-select";
import Spinner from "./Spinner";

const CurrencyDropdown = ({ updateDropdown, val, inpName, selected, allowAccess = true }) => {
  const sendDropdownValue = (value) => {
    updateDropdown(value);
  };

  const invalidStyle = {};

  if (val !== undefined) {
    const options = [];
    val.forEach((v, i) => {
      options.push({ value: v["id"], label: v["value"] });
    });
    return (
      <div>
        <Select
          styles={invalidStyle}
          name={inpName}
          isDisabled={!allowAccess}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={sendDropdownValue}
          isClearable={true}
          value={options.filter((option) => option.value === selected)}
        />
      </div>
    );
  } else {
    return <Spinner></Spinner>;
  }
};

export default CurrencyDropdown;
