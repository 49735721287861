import i18next from 'i18next';
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { Utils } from '../../utils/utils';
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import { ReactComponent as RoomcatDragDrop } from "../Common/icons/RoomcatDragDrop.svg";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { DragNDropTable } from "./../Common/DragNDropTable";
import SearchComponents from "./../Common/SearchComponents";
import Spinner from "./../Common/Spinner"

export const HotelRoomCategories = (props) => {
  const storageService = new StorageService();
  const [allowAccess, setAllowAccess] = useState(true);

  const hotelId = +storageService.getItem("hotelId");
  const { t } = useTranslation();
  const isLoading = useSelector((state)=>state?.network?.isLoading)
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchFloorList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
    dispatch(actions.setSearchUserList(""))
  },[])

  const [sort, setSort] = useState("");
  const [tableMapper, setTableMapper] = useState([]);
  const roomCategoryList = useSelector((state) => state.roomCategories.roomCategories);
  const sortColumns = [];
  const [headerDescription, setHeaderDescription] = useState();
  const langId = useSelector(state => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(langId.filter(option => option.language.key === i18next.language)[0]?.language?.id);
  
  const mapTableInfo = (roomCategoryList) => {
    const mapper = roomCategoryList.map((roomCategoryInfo, index) => {
      const tableHeader = {
        id: roomCategoryInfo.id,
      };
      tableHeader[``] = <RoomcatDragDrop/>;
      tableHeader[`${ t("Modules.Room categories (main page).Category")}`] = <b>{Utils.langValueGenerator(roomCategoryInfo?.category_ts,'name',defaultSelectedLanguage)}</b>
      tableHeader[`${ t("Modules.Room categories (main page).PMS Room Types")}`] = roomCategoryInfo.category_mappings
      return tableHeader;
    });
    const tableHeaderDesc = {
      [`${t("Modules.Room categories (main page).Category")}`]: t(
        "Modules.Room categories (main page).Category - information box"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };
 

  const onEditRow = (categoryId) => {
    props.history.push("/hotel-room-categories/edit/" + categoryId);
  };
  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_category_create))
    dispatch(actions.getRoomCategories(hotelId));
  }, []);
  useEffect(() => {
      roomCategoryList?.length ? mapTableInfo(roomCategoryList) : mapTableInfo([]);
  }, [roomCategoryList,defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    if(selectedLanguage){
      const langFiltered = langId.filter(option => option.language.key === selectedLanguage)
      onLanguageSelect(langFiltered[0]?.language?.id)
    }
  })
  const onLanguageSelect = (selectedLanguage) => {
    if(selectedLanguage){
      setDefaultLanguage(selectedLanguage);
    }
  };

  return (
   <React.Fragment>
      <div className="content mt-3">
        <Row>
          <Col>
            <Row className="hide-search">
              <Col>
                <SearchComponents
                  buttonLabel={t("Modules.Room categories (main page).+ Add a new room category")}
                  addBtn={allowAccess}
                  path={PRIVATE_ROUTES.room_category_create}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DragNDropTable sortColumns={sortColumns}
                  currentlySorted={sort}
                  tableExtraActions={allowAccess?tableExtraActions:[]}
                  tableMapper={tableMapper}
                  headerDescription={headerDescription}
                  allowAccess={allowAccess}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default HotelRoomCategories;
