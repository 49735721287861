import axios from "../servicesInstance";
import { Utils } from './../../utils/utils';


class ExtraServices {
    getService = async (payload) => {
        try {
            const params = {
                ...payload,
              };
            const response = await axios.get(`services`,{ params });
            return response;
        } catch (error) {
            throw error
        }
    }
    getSearchService = async (hotelId,searchKey) => {
        try {
            const response = await axios.get(`/services?hotel_id=${hotelId}&searchKey=${searchKey}`);
            return response;
        } catch (error) {
            throw error
        }
    }
    addService = async (payload) => {
        const bodyFormData = Utils.appendDataServiceAndOffers(payload);
        try {
            const response = await axios.post(
                `/service`,
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return response;
        } catch (error) {
            throw error
        }
    }
    getServiceById = async (hotelId,serviceId) => {
        try {
            const response = await axios.get(`/service/${serviceId}?hotel_id=${hotelId}`);
            return response;
        } catch (error) {
            throw error
        }
    }
    updateService = async (categoryId,payload) => {
        const bodyFormData = Utils.appendDataServiceAndOffers(payload);
        try {
            const response = await axios.put(
                `/service/${categoryId}`,
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return response;
        } catch (error) {
            throw error
        }
    }

    postEmailService = async (payload) =>{
        try {
            const response = await axios.put(`/hotel/${payload?.hotelId}/email_setting`,{number_of_days:payload?.noOfDays, is_ses_enabled: payload?.is_ses_enabled})
            return response
        } catch (error) {
            throw error
        }
    }

    getEmailService = async (hotelId) =>{
        try {
            const response = await axios.get(`/hotel/${hotelId}/email_setting`)
            return response
        } catch (error) {
            throw error
        }
    }
    getBookingStatus = async(payload)=>{
        try{
            const response = await axios.post(`booking/test`,payload)
            return response
        }catch(error){
            throw error
        }
    }

    getDealTypes = async() => {
        try {
            const response = await axios.get(`service/list/types`)
            return response
        } catch (error) {
            throw error
        }
    }
}

export default ExtraServices;