import actions from '../actions/actions'

const initialState = {
    result: false,
    
}

export const bookingReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case actions.GET_TEST_BOOKING_SUCCESS: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }
        
        case actions.GET_TEST_BOOKING_FAILED: {
            return {
                ...state,
                result: false
            }
        }
        default: {
            return state
        }
    }
}