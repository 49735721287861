import { call, put, takeLatest } from "redux-saga/effects";
import Authr from "../../services/Authr/index";
import actions from "../actions/actions";

const getRole =  async (hotelGroupId) =>{
    try{
        let AuthrServe = new Authr();
        const response = await AuthrServe.getRoles(hotelGroupId)
        return response
    }
    catch (err) {
        throw err;
      }
}




function* dispatchGetRoles(action) {
    try {
        const response = yield call(getRole,action.hotelGroupId)
        if (response.data.success) {
            yield put({
                type: actions.GET_ROLE_SUCCESS,
                data: response.data.data.records,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.GET_ROLE_FAILED,
                msg: response?.data?.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_ROLE_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
          })
    }
}


export function* listengetRoles() {
    yield takeLatest(actions.GET_ROLE, dispatchGetRoles)
  }