import axios from "../axiosInstance";

class TranslationService {
  getTranslationForTab = async (payload) => {
    try {
      const { hotelId, filter, limit, page } = payload;
      let params = `entity=${filter}`;

      if (limit && page) {
        params += `&limit=${limit}&page=${page}`;
      }
      const response = await axios.get(
        `hotel/${hotelId}/translations?${params}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveTranslation = async (payload, hotelId) => {
    try {
      const response = await axios.post(
        `hotel/${hotelId}/translations`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default TranslationService;
