import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Col, Form as bootstrapForm,
  Row
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Spinner from "./../Common/Spinner";


export const HotelTranslationForm = () => {
  // need to make hotel ID dynamic
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const languageTags = useSelector(
    (state) => state?.hotelLanguageSettings?.result
  );
  const [allowAccess, setAllowAccess] = useState(true);

  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const formInitialValue = {
    translation: [],
  };

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create))

    fetchLanguageTags();
  }, []);

  const fetchLanguageTags = () => {
    dispatch(actions.getHotelLanguageSettings(hotelId));
  };

  const updateLanguageTags = (payload) => {
    payload.hotelId = hotelId;
    dispatch(actions.storeHotelLanguageSettings(payload));
  };

  const fetchInitValues = (tags) => {
    const filteredLanguages = availableFilteredLanguages.map((languageInfo) => {
      return {
        languageId: languageInfo?.language?.id,
        name: languageInfo?.language?.code,
      };
    });
    /**
     * temporory logic to add missing language entries
     * to make text box appear on tags translations
     */
    if (tags) {
      let tagsT = tags.map((tagData) => {
        if (filteredLanguages?.length === tagData.tags_ts?.length) {
          return tagData
        } else {
          const tagsLanguages = tagData.tags_ts?.reduce((acc, curr) => {
            acc[curr.language_id] = curr.tag_id
            return acc
          }, {})
          const missingData = []
          filteredLanguages.forEach((lang) => {
            if (!Object.keys(tagsLanguages).includes(lang.languageId+"")) {
              missingData.push({
                name: "",
                language_id: lang.languageId,
                tag_id: tagData.id
              })
            }
          })
          tagData.tags_ts = [...tagData.tags_ts, ...missingData]
          return tagData
        }
      })
      const filterArray = Utils.refactorTranslationTags(filteredLanguages, tagsT);
      return { translation: filterArray };
    }
    return { translation: [] };

  };

  return (
    <React.Fragment>
      <div>
        <div className="sub-header">
          {t("Modules.Translations.Tags translation")}
        </div>
        <div className="sub-header-text">
          {t(
            "Modules.Translations.Tags translation Description"
          )}
        </div>
        <div>
          {!isLoading ? (
            <>
              {!(languageTags && languageTags?.length) && (
                <>
                  <strong>
                    {/* TODO: translation pending */}
                    Please add tags for this hotel.
                  </strong> <br />
                </>
              )}
              <Formik
                innerRef={formikRef}
                initialValues={{
                  ...formInitialValue,
                  ...fetchInitValues(languageTags),
                }}
                onSubmit={(values) => {
                  const filteredTagArray = Utils.refactorTranslationReqPayload(
                    values.translation
                  );
                  updateLanguageTags(filteredTagArray);
                }}
              >
                {({ handleChange, values, submitForm, setFieldValue }) => (
                  <>
                    <FieldArray name="translation">
                      {() => {
                        const translationArray = values?.translation;
                        return (
                          <>
                            {languageTags &&
                              languageTags.length &&
                              translationArray &&
                              translationArray.length ? (
                              <>
                                <Row className="row-vertical-scroll">
                                  {translationArray.map(
                                    (translationValue, index) => (
                                      <Col
                                        md={3}
                                        sm={4}
                                        xs={6}
                                        key={`translation-index-${index}`}
                                        className="mt-3 mb-3"
                                      >
                                        <div
                                          key={`translation-index-div-${index}`}
                                        >
                                          <strong>
                                            {translationValue?.language}
                                          </strong>
                                        </div>
                                        <div
                                          key={`translation-div-tags-${index}`}
                                        >
                                          {translationValue.tags.map(
                                            (translationTagValue, tagIndex) => (
                                              <bootstrapForm.Control
                                                key={`translation-form-${tagIndex}`}
                                                type="text"
                                                value={
                                                  translationTagValue?.name
                                                }
                                                className="mt-3"
                                                name={`translation.${index}.tags.${tagIndex}.name`}
                                                onChange={(event)=>{
                                                  if(event.target.value.trim().length > 0){
        
                                                    handleChange(event)
                                                  }else if (event.target.value.trim().length < 1){
                                                    setFieldValue(`translation.${index}.tags.${tagIndex}.name`,"")
                                                  }
                                                }}
                                              />
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }}
                    </FieldArray>
                    {allowAccess?<Button
                      className="mt-3"
                      variant="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {t("Common Translations.Save")}
                    </Button>:<></>}
                  </>
                )}
              </Formik>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
