import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Spinner from "./../Common/Spinner";
import { HotelInformation } from "./HotelInformation";

export const ConfigureHotel = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchFloorList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
    dispatch(actions.setSearchUserList(""))
  },[])

  const { i18n } = useTranslation();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const isLoading = useSelector((state) => state?.hotelDetails?.isLoading);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(null);
  const [cmsContent, setCMSContent] = useState(null);

  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage?.language_id);
    const selectedLangId = selectedLanguage.language_id;
    const changedCMSContent = findExistingLanguage(
      hotelDetails && hotelDetails?.cms_blocks
        ? hotelDetails?.cms_blocks[0]
        : [],
      selectedLangId
    );
    if (
      changedCMSContent &&
      changedCMSContent?.language_id &&
      changedCMSContent?.body?.trim()
    ) {
      setCMSContent(changedCMSContent);
    } else {
      // else setting up default lang
      setCMSContent(
        hotelDetails && hotelDetails?.cms_blocks.length
          ? hotelDetails?.cms_blocks[0]?.cms_blocks_ts[0]
          : ""
      );
    }
  };
  useEffect(() => {
    if (
      defaultSelectedLanguage === +availableFilteredLanguages[0]?.language?.id
    ) {
      // getting basic info for english ( base lang only )
      getBasicInfo();
    }
  }, [defaultSelectedLanguage]);

  const getBasicInfo = () => {
    dispatch(actions.getHotelBasicDetails(hotelId));
  };

  useEffect(() => {
    setStates();
  }, [hotelDetails]);

  const setStates = () => {
    if (hotelDetails) {
      const CMSContent = defaultSelectedLanguage
        ? findExistingLanguage(
            hotelDetails?.cms_blocks[0],
            defaultSelectedLanguage
          )
        : hotelDetails?.cms_blocks[0]?.cms_blocks_ts[0];
      setCMSContent(CMSContent);
    }
  };

  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  const findExistingLanguage = (cmsBlock, selectedLangId) => {
    return (cmsBlock?.cms_blocks_ts || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  useEffect(() => {
    if (i18n?.language && availableLanguagesFromSelector.length) {
      const langInfo = selectedLangId(i18n?.language?.toLowerCase());
      if (langInfo) {
        setDefaultLanguage(langInfo?.language?.id);
        onLanguageSelect({ language_id: langInfo?.language?.id });
      } else {
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
        onLanguageSelect({
          language_id: availableFilteredLanguages[0]?.language?.id,
        });
      }
    } else {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      onLanguageSelect({
        language_id: availableFilteredLanguages[0]?.language?.id,
      });
    }
  }, [i18n?.language, availableLanguagesFromSelector]);

  return (
    <React.Fragment>
      {!isLoading ? (
        <div className="content mt-3">
          <Row>
            <Col md={6}>
              {/* <div className="header">{hotelDetails?.name}</div> */}
            </Col>
            <Col md={6} className="alignContentCenter"></Col>
          </Row>
          <div>
            <HotelInformation
              cmsContent={cmsContent}
              hotelId={hotelId}
              hotelDetails={hotelDetails}
              selectedLanguage={defaultSelectedLanguage}
              baseLangId={availableFilteredLanguages[0]?.language?.id}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};
