import actions from "../actions/actions";

const initialState = {
  constants: false,
  languages: [],
  currencies: false,
  result: {},
  hotels: [],
  pmsHotel: [],
  hotelRoomCategory: [],
  hotelAssignFloor: [],
  page: 1,
  limit: 10,
  result: false,
  totalCount: 0,
  countries : [],
  states:false
};

export const constantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FETCH_CONSTANTS_SUCCESS: {
      return {
        ...state,
        constants: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.GET_PMS_HOTEL_SUCCESS: {
      return {
        ...state,
        pmsHotel: action.data,
        result: {
          msg: action.msg,
          sucess: true,
        },
      };
    }

    case actions.GET_HOTEL_ROOM_CATEGORY_SUCCESS: {
      return {
        ...state,
        hotelRoomCategory: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.GET_HOTEL_FLOOR_ASSIGN_SUCCESS: {
      return {
        ...state,
        hotelAssignFloor: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FETCH_CURRENCIES_SUCCESS: {
      return {
        ...state,
        currencies: action.data,
        result: {
          ...state.result,
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.FETCH_CURRENCIES_FAILED: {
      return {
        ...state,
        currencies: false,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
//
    case actions.GET_COUNTRY_SUCCESS:{
      return {
          ...state,
          countries:action.data,
          result: {
            ...state.result,
              msg: action.msg,
              success: true
          }
      }
  }

    case actions.GET_STATES_SUCCESS:{
        return {
            ...state,
            states: {
                ...state.states,
                [parseInt(action.country)]:action.data
            },
            result: {
              ...state.result,
                msg: action.msg,
                success: true
            }
        }
    }
    
    default: {
      return state;
    }
  }
};
