import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import actions from "../../store/actions/actions";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import dealAdd from "../Common/overlayImages/dealAdd.jpg";
import { StorageService } from "./../../services/storage.service";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import Spinner from "./../Common/Spinner";
import { ExtraServicesForm } from "./../ExtraServices/ExtraServicesForm";
import { Form, Formik } from "formik";

export const EditExtraService = () => {
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const { id } = useParams();
  const { t } = useTranslation();
  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const [cmsContent, setCMSContent] = useState(null);
  const [dealName, setDealName] = useState(null);
  const serviceById = useSelector((state) => state.extraservices.serviceById);
  const history = useHistory();
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/deals-and-offer", title: hotelName },
    { path: "/deals-and-offer", title: " Deals and offers" },
    { path: `/extra-service/edit/${id}`, title: dealName },
  ];

  const hotelRoomDetails = useSelector((state) => state?.hotelRoomAdd?.result);

  // useEffect(() => {
  //   setFormInitialValue({
  //     floor_id: hotelRoomDetails?.floor_id,
  //   });
  // })
  
//   const [formInitialValue, setFormInitialValue] = useState({
//     floor_id: hotelRoomDetails?.floor_id,
// });

  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  useEffect(() => {
    dispatch(actions.getServiceById(hotelId, id));
  }, []);

  const isUpdated = useSelector((state) => state?.extraservices?.isUpdate);
  const isLoading = useSelector((state) => state?.extraservices?.serviceById);

  useEffect(() => {
    if (isUpdated) {
      history.push("/deals-and-offer");
    }
  }, [isUpdated]);
  // const onLanguageSelect = (selectedLanguage) => {
  //   setDefaultLanguage(selectedLanguage?.language_id);
  //  };
  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);
  useEffect(() => {
    setStates();
  }, [serviceById]);
  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );
    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
    const selectedLangId = selectedLanguage;
    if (selectedLangId) {
      const changedCMSContent = findExistingLanguage(
        serviceById && serviceById?.h_offers_and_services_ts
          ? serviceById?.h_offers_and_services_ts
          : [],
        selectedLangId
      );
      if (changedCMSContent && changedCMSContent?.language_id) {
        if (changedCMSContent?.body) {
          setCMSContent(changedCMSContent?.body);
        } else {
          setCMSContent(serviceById?.h_offers_and_services_ts[0]?.body);
        }
      } else {
        setCMSContent("");
      }
    }
  };

  const setStates = () => {
    if (serviceById) {
      const CMSContent = defaultSelectedLanguage
        ? findExistingLanguage(
          serviceById?.h_offers_and_services_ts,
          defaultSelectedLanguage
        )
        : serviceById?.h_offers_and_services_ts[0]?.body;
      setDealName(
        serviceById?.h_offers_and_services_ts[defaultSelectedLanguage - 1]
          ?.name || ""
      );
      setCMSContent(CMSContent?.body);
    }
  };

  const findExistingLanguage = (cmsBlock, selectedLangId) => {
    return (cmsBlock || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };

  useEffect(() => {
    if (serviceById.id) {
      const changedDealName = findExistingLanguage(
        serviceById?.h_offers_and_services_ts,
        defaultSelectedLanguage
      );
      if (
        changedDealName &&
        changedDealName?.language_id &&
        changedDealName.name.length
      ) {
        setDealName(changedDealName?.name);
      } else {
        setDealName(serviceById?.h_offers_and_services_ts[0].name);
      }
    }
  }, [serviceById, defaultSelectedLanguage]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.Deals and offers (main page).Deals")} </h3>
          </Col>
        </Row>
      </div>
      {isLoading ? (<div className="content">
        <Row>
          <Col md={6}>
            <div className="header">
              {t("Modules.Deals and Offers (edit deal).Edit deal")}
            </div>
            <div className="sub-header-text">
              {t(
                "Modules.Deals and Offers (edit deal).Edit deal - Description"
              )}{" "}
              <span className="m-2">
                <OverlayTrigger
                  key="right"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      <Row>
                        <Col md={3}>
                          {t(
                            "Modules.Deals and Offers (edit deal).Edit deal - Question mark"
                          )}
                        </Col>
                        <Col>
                          <img
                            src={dealAdd}
                            className={"tooltip-image"}
                            alt={"greeting-overview"}
                          ></img>
                        </Col>
                      </Row>
                    </Tooltip>
                  }
                >
                  <QuestionIcon className="pointer" />
                </OverlayTrigger>
              </span>
            </div>
          </Col>
          <Col md={6} className="alignContentCenter">
            {/* <Languages
              onChange={onLanguageSelect}
              defaultValue={defaultSelectedLanguage}
              valueAsKey={true}
              extraClasses="small-dropdown"
            /> */}
          </Col>
        </Row>
        
        <div>
            <ExtraServicesForm  
              cmsContent={cmsContent} 
              isForEdit={true} 
              selectedLanguageForUpdate={defaultSelectedLanguage} 
              baseLangId={availableFilteredLanguages[0]?.language?.id} 
              serviceDetails={serviceById}
            />
        </div>
      </div>) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};
