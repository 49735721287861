import axios from "../reportInstance";

class HotelBookingReport {
  fetchBookingReport = async (
    pageNo,
    limit,
    hotelId,
    searchKey,
    sortBy,
    sort,
    languageId,
    startDate,
    endDate,
    booking_date
  ) => {
    try {
      let params = formatParams(pageNo, limit, hotelId, searchKey, sortBy,sort, languageId, startDate, endDate, booking_date)
      const response = await axios.get(`bookings?${params}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  fetchExportedFile = async (
    pageNo,
    limit,
    hotelId,
    searchKey,
    sortBy,
    sort,
    languageId,
    startDate,
    endDate,
    booking_date
  ) => {
    try {
      let params = formatParams(pageNo, limit, hotelId, searchKey, sortBy,sort, languageId, startDate, endDate, booking_date)
      const response = await axios.get(`bookings/report?${params}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

const formatParams = (
  pageNo,
  limit,
  hotelId,
  searchKey,
  sortBy,
  sort,
  languageId,
  startDate,
  endDate,
  booking_date
) => {
  try {
    let params = "";
      if (pageNo) {
        if (params.length < 1) {
          params = params + `page=${pageNo}`;
        } else {
          params = params + `&page=${pageNo}`;
        }
      }
      if (limit) {
        if (params.length < 1) {
          params = params + `limit=${limit}`;
        } else {
          params = params + `&limit=${limit}`;
        }
      }
      if (hotelId) {
        if (params.length < 1) {
          params = params + `hotel_id=${hotelId}`;
        } else {
          params = params + `&hotel_id=${hotelId}`;
        }
      }
      if (searchKey) {
        if (params.length < 1) {
          params = params + `searchKey=${searchKey}`;
        } else {
          params = params + `&searchKey=${searchKey}`;
        }
      }
      if (sortBy) {
        if (params.length < 1) {
          params = params + `sort=${sortBy}`;
        } else {
          params = params + `&sort=${sortBy}`;
        }
      }
      if (sort) {
        if (params.length < 1) {
          params = params + `sortBy=${sort}`;
        } else {
          params = params + `&sortBy=${sort}`;
        }
      }
      if (languageId) {
        if (params.length < 1) {
          params = params + `language_id=${languageId}`;
        } else {
          params = params + `&language_id=${languageId}`;
        }
      }
      if (startDate) {
        if (params.length < 1) {
          params = params + `start_date=${startDate}`;
        } else {
          params = params + `&start_date=${startDate}`;
        }
      }
      if (endDate) {
        if (params.length < 1) {
          params = params + `end_date=${endDate}`;
        } else {
          params = params + `&end_date=${endDate}`;
        }
      }
      if (booking_date) {
        if (params.length < 1) {
          params = params + `booking_date=${booking_date}`;
        } else {
          params = params + `&booking_date=${booking_date}`;
        }
      }

      return params
  } catch (error) {
     return ""
  }
};

export default HotelBookingReport;
