import { call, put, takeLatest } from 'redux-saga/effects';
import actions from '../actions/actions';
import SpacialOffers from "./../../services/Offers/index";
import { StorageService } from './../../services/storage.service';

const storageService = new StorageService();
const columnMapForReducer = {
  "name":"OFFER NAME",
  "price":"PRICE",
  "start_date":"START DATE",
  "end_date":"END DATE",
  "active":"STATUS"
}
const offerServe = new SpacialOffers();
const getOffers = async (payload) => {
  try{
    const response = await offerServe.getOffers(payload)
    return response
  }
  catch (err) {
    throw err;
  }
   
};
const getOfferById = async(hotelId,offerId)=>{
  try{
    const response = await offerServe.getOfferById(hotelId,offerId)
    return response
  }
  catch (err) {
    throw err;
  }
 
}


const createOffer = async (data)=>{
  try{
    const response = await offerServe.addOffer(data)
    return response
  }
  catch (err) {
    throw err;
  }
 
}

const updateOffer = async(categoryId,data)=>{
  try{
    const response = await offerServe.updateOffer(categoryId,data)
    return response
  }
  catch (err) {
    throw err;
  }
}
const fetchSearchOffers = async (hotelId,searchKey) => {
  try{
    const response = await offerServe.getSearchOffers(hotelId,searchKey)
    return response
  }
  catch (err) {
    throw err;
  }
}

function* dispatchOffers(action) {
    try {
      yield put({ type: actions.API_FETCHING });
      const response = yield call(getOffers, action.payload);
      if (response.data.success) {
        yield put({ type: actions.API_SUCCESS });
        yield put({
          type: actions.FETCH_OFFERS_LIST_SUCCESS,
          msg: response.data.msg,
          result: response?.data?.data,
        });
        yield put({ type: actions.CLEAR_OFFER_BY_ID });
        yield put({
          type: actions.SET_OFFER_PAGE_NO,
          page: response?.data?.data.page,
        });
        yield put({
          type: actions.SET_OFFER_SORT,
          sortBy: action.payload.sortBy?action.payload.sortBy:"ASC",
          sortName: action.payload.sort,
          sortColumnName: columnMapForReducer[action.payload.sort] ? columnMapForReducer[action.payload.sort] : "OFFER NAME",
          sort: action.payload.sortBy === "DESC" ? true : false
        });
      } else {
        yield put({ type: actions.API_WARNING, msg: response.data });
        yield put({
          type: actions.FETCH_OFFERS_LIST_FAILED,
          msg: response.data.msg,
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_FAILED,
        data: error.response,
      });
    }
}
function* dispatchOfferById(action) {
  try {
    const response = yield call(getOfferById, action.hotelId,action.offerId);
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({
        type: actions.FETCH_OFFERS_BY_ID_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({ type: actions.API_SUCCESS });
    } else {
      yield put({
        type: actions.FETCH_OFFERS_BY_ID_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
 

  function* dispatchAddOffer(action) {
    try {
      yield put({type: actions.API_FETCHING})
      const response = yield call(createOffer, action.data);
      if (response.data.success) {
        yield put({
          type: actions.ADD_OFFER_SUCCESS,
          msg: response.data.msg,
        });
        yield put({
          type: actions.API_SUCCESS,
          data: response,
        });
      } else {
        yield put({
          type: actions.ADD_OFFER_FAILED,
          msg: response.data,
        });
        yield put({
          type: actions.API_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_FAILED,
        data: error.response,
      });
    }
  }

  function* dispatchUpdateOffer(action) {
    try {
      const response = yield call(updateOffer, action.offerId,action.data);
      if (response.data.success) {
        yield put({
          type: actions.UPDATE_OFFER_SUCCESS,
          msg: response.data.msg,
        });
        yield put({
          type: actions.API_SUCCESS,
          data: response,
        });
      } else {
        yield put({
          type: actions.UPDATE_OFFER_FAILED,
          msg: response.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_FAILED,
        data: error.response,
      });
    }
  }
  function* dispatchSearchOffers(action) {
    try {
      const response = yield call(fetchSearchOffers, action.hotelId,action.searchKey);
      if (response.data.success) {
        yield put({
          type: actions.FETCH_OFFERS_LIST_SUCCESS,
          msg: response.data.msg,
          result: response?.data?.data,
        });
      } else {
        yield put({
          type: actions.FETCH_OFFERS_LIST_FAILED,
          msg: response.data.msg,
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_FAILED,
        data: error.response,
      });
    }
  }
//get Room categories
export function* listenGetOffer() {
    yield takeLatest(actions.FETCH_OFFERS_LIST, dispatchOffers)
}

//get PMS Room Types
export function* listenGetOfferById() {
  yield takeLatest(actions.FETCH_OFFERS_BY_ID, dispatchOfferById)
}

//get PMS Room Types
export function* listenaddOffer() {
  yield takeLatest(actions.ADD_OFFER, dispatchAddOffer)
}

export function* listenEditOffer() {
  yield takeLatest(actions.UPDATE_OFFER, dispatchUpdateOffer)
}

export function* listeneditSearchOffers() {
  yield takeLatest(actions.SEARCH_OFFERS, dispatchSearchOffers)
}