import { Formik } from "formik";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as BootstrapForm, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import * as yup from "yup";
import { PRIVATE_ROUTES, REGEX } from "../../helper/constants";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { DragDrop } from "./../Common/DragDrop";
import Spinner from "./../Common/Spinner";

export const FloorForm = ({
  isForEdit,
  floorDetails = null,
  hotelId = null,
  floorId = null,
  onFloorUpdate,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // TODO: make dynamic ids
  const langId = useSelector((state) => state.constants.languages);

  const formikRef = useRef();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const gotSuccess = useSelector((state) => state?.floor?.success);
  const [imageFromApi, setImageFromApi] = useState([]);
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const [path, setPath] = useState(isForEdit ? "update" : "add");
  const { t, i18n } = useTranslation();
  // const { values } = useFormikContext();
  const [fileUploaded, setFileUploaded] = useState(null);
  const [allowAccess, setAllowAccess] = useState(true);

  const formSchema = yup.object().shape({
    // name: yup.number().integer().min(0).required("Please enter valid name"),
    name: yup.string().trim().required("Please enter valid name"),
    number_of_rooms: yup
      .number()
      // .integer()
      .min(0)
      .required("Please enter valid number of rooms"),
    floor_plan: "",
    is_active: false,
  });

  const formInitialValue = {
    name: "",
    number_of_rooms: "",
    floor_plan: "",
    is_active: true,
  };

  useEffect(() => {
    if (gotSuccess && !isForEdit) {
      // removing floor plan as touched
      formikRef.current.touched.floor_plan = false;
      formikRef.current.values.floor_plan = "";
      clearForm();
      if (isFormSubmitted) {
        history.push("/hotel-floor/list");
      }
    }
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.floor_create))
  }, [gotSuccess]);

  useEffect(() => {
    if (floorDetails) {
      onLanguageSelect({
        language_id:
          defaultSelectedLanguage ||
          availableFilteredLanguages[0]?.language?.id,
      });
      formikRef.current.setFieldValue(
        "number_of_rooms",
        floorDetails?.number_of_rooms
      );
      formikRef.current.setFieldValue("floor_plan", floorDetails?.floor_plan);
      formikRef.current.setFieldValue(
        "is_active",
        floorDetails?.is_active && +floorDetails?.is_active === 1 ? true : false
      );
      setImageFromApi([{ arrId: 1, preview: floorDetails?.floor_plan }]);
    }
  }, [floorDetails]);

  useEffect(() => {
    if (!isForEdit) {
      if (!defaultSelectedLanguage) {
        if (i18n?.language) {
          const langInfo = selectedLangId(i18n?.language?.toLowerCase());
          if (langInfo) {
            setDefaultLanguage(langInfo?.language?.id);
          } else {
            setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
          }
        } else {
          // setting up initially 0th index as lang id
          setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
        }
      }
      // setting up the default language for new floor
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, []);

  useEffect(() => {
    if (i18n?.language) {
      const langInfo = selectedLangId(i18n?.language?.toLowerCase());
      
      if (langInfo) {
        
        setDefaultLanguage(langInfo?.language?.id);
      } else {
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      }
    } else {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [i18n?.language,availableLanguagesFromSelector]);

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  i18n.on("languageChanged", (selectedLang) => {
    if (availableLanguagesFromSelector) {
      const langInfo = selectedLangId(selectedLang.toLowerCase());
      if (langInfo) {
        onLanguageSelect({ language_id: langInfo?.language?.id });
      }
    }
  });

  const updateFloor = (payload) => {
    dispatch(actions.editFloor(payload));
  };

  const createNewFloor = (formPayload) => {
    dispatch(actions.addNewFloor(formPayload));
    setFormSubmitted(true);
  };

  const clearForm = () => {
    formikRef.current?.resetForm();
  };

  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage?.language_id);
    const changedFloorName = findExistingLanguage(
      floorDetails?.h_floor_ts,
      selectedLanguage?.language_id
    );
    if (formikRef && formikRef?.current) {
      if (changedFloorName && changedFloorName?.language_id) {
        formikRef.current.setFieldValue("name", changedFloorName?.name);
      } else {
        formikRef.current.setFieldValue(
          "name",
          floorDetails && floorDetails?.h_floor_ts?.length
            ? floorDetails?.h_floor_ts[0]?.name
            : ""
        );
      }
    }
  };

  const findExistingLanguage = (languageBLock, selectedLangId) => {
    return (languageBLock || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };

  const validateFileUpload = () => {
    if (!fileUploaded?.length) {
      formikRef.current.touched.floor_plan = true;
      formikRef.current.values.floor_plan = "";
      // formikRef.current.errors.floor_plan = ;
      setFileUploaded([]);
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...formInitialValue }}
        innerRef={formikRef}
        validationSchema={formSchema}
        onSubmit={(values) => {
          values.is_active =
            values.is_active === true || values.is_active === 1 ? 1 : 0;
          if (!isForEdit) {
            if (fileUploaded?.length) {
              values.floor_plan = values?.floor_plan
                ? values?.floor_plan
                : fileUploaded[0];
              setFileUploaded([fileUploaded]);
              values.hotel_id = hotelId;
              values.language_id =
                defaultSelectedLanguage ||
                availableFilteredLanguages[0]?.language?.id;
              setImageFromApi([{ ...values?.floor_plan }]);
              createNewFloor(values);
              setPath("update");
            } else {
              formikRef.current.touched.floor_plan = true;
              formikRef.current.values.floor_plan = "";
              // formikRef.current.errors.floor_plan = ;
              setFileUploaded([]);
            }
          } else {
            const payload = {
              name: values.name,
              floorId: floorId,
              number_of_rooms: values.number_of_rooms,
              hotel_id: hotelId,
              language_id: defaultSelectedLanguage,
              is_active: values?.is_active,
            };
            if (fileUploaded?.length) {
              const floorURL =
                fileUploaded && fileUploaded.length
                  ? fileUploaded[0].preview || null
                  : null;
              if (floorURL !== floorDetails?.floor_plan) {
                payload.floor_plan = fileUploaded[0];
              }
              updateFloor(payload);
            } else {
              formikRef.current.touched.floor_plan = true;
              formikRef.current.values.floor_plan = "";
              setFileUploaded([]);
            }
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          submitForm,
          setFieldValue,
        }) => (
          <>
            {!isLoading ? (
              <>
                <BootstrapForm>
                  <Row>
                    <Col md={6}>
                      <div className="header">
                        {t("Modules.Floor list (+ add new).Floor information")}
                      </div>
                    </Col>
                    {isForEdit && (
                      <Col md={6} className="alignContentCenter">
                        {/* <Languages
                        onChange={onLanguageSelect}
                        defaultValue={defaultSelectedLanguage}
                        valueAsKey={true}
                        extraClasses="small-dropdown"
                      /> */}
                      </Col>
                    )}
                  </Row>
                  <Row style={{fontSize:'13px'}}>
                    
                      <BootstrapForm.Label className={"sub-header-text mb-0"} style={{fontSize:'13px'}}>
                        {t("Modules.Floor list (+ add new).Activate floor?")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-right-common">
                              {t(
                                "Modules.Floor list (+ add new).Activate floor - Description"
                              )}
                            </Tooltip>
                          }
                        >
                          <QuestionIcon className="pointer" />
                        </OverlayTrigger>
                      </BootstrapForm.Label>
                      
                      <div>
                        <span className="switch-lable-off sub-header-text">
                          {t("Modules.Floor list (+ add new).Off")}
                        </span>
                        <Switch
                          onChange={(value) => {
                            setFieldValue("is_active", value);
                          }}
                          name="is_active"
                          checked={values?.is_active}
                        />
                        <span className="switch-lable sub-header-text">
                          {t("Modules.Floor list (+ add new).On")}
                        </span>
                      </div>
                 
                  </Row>
                  <Row className="mt-3">
                    <Col md={3}>
                      <BootstrapForm.Group>
                        <BootstrapForm.Label className={"sub-header-text"}>
                          {t("Modules.Floor list (+ add new).Floor")}
                          <span style={{ color: "red" }}>*</span>
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          type="text"
                          name="name"
                          min={0}
                          value={values?.name?.trimStart()}
                          // onChange={(e) => {
                          //   const re = REGEX.NEGATIVE_REGEX;
                          //   const value = e.target.value;
                          //   if (value === "" || value > 0 || re.test(value)) {
                          //     setFieldValue(
                          //       "name",
                          //       value === "" ? "" : +(value * 1).toString()
                          //     );
                          //   } else {
                          //     setFieldValue("name", 0);
                          //   }
                          // }}
                          onChange={handleChange}
                          isInvalid={touched?.name && errors?.name}
                          // placeholder="7"
                        ></BootstrapForm.Control>
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <BootstrapForm.Group>
                        <BootstrapForm.Label className={"sub-header-text"}>
                          {t(
                            "Modules.Floor list (+ add new).Total number of rooms on this floor"
                          )}
                          <span style={{ color: "red" }}>*</span>
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          type="number"
                          min={0}
                          name="number_of_rooms"
                          value={values?.number_of_rooms}
                          onChange={(e) => {
                            const re = REGEX.NEGATIVE_REGEX;
                            const value = e.target.value;
                            if (value === "" || value > 0 || re.test(value)) {
                              setFieldValue(
                                "number_of_rooms",
                                value === "" ? "" : +(value * 1).toString()
                              );
                            } else {
                              setFieldValue("number_of_rooms", 0);
                            }
                          }}
                          isInvalid={
                            touched?.number_of_rooms && errors?.number_of_rooms
                          }
                        ></BootstrapForm.Control>
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="header">
                        {t("Modules.Floor Lists.Floor plan")}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="sub-header-text">
                        {t(
                          "Modules.Floor list (+ add new).Floor plan description"
                        )}
                      </div>
                      <div
                        className={`${
                          touched.floor_plan &&
                          !values.floor_plan &&
                          !fileUploaded?.length
                            ? "form-control is-invalid"
                            : ""
                        } mt-3`}
                      >
                        <DragDrop
                          maximumFile={1}
                          multiUpload={false}
                          handleChange={setFileUploaded}
                          values={imageFromApi}
                          path={path}
                          acceptingImageTypes="image/jpg, image/jpeg, image/png, application/pdf"
                        ></DragDrop>
                        
                      </div>
                      
                      
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {!isForEdit ? (
                      <Col md={2} sm={6} xs={6}>
                        {allowAccess?<Button
                          // allowing to submit if lang is primary/base lang
                          disabled={
                            defaultSelectedLanguage !==
                            availableFilteredLanguages[0]?.language?.id
                          }
                          onClick={() => {
                            submitForm();
                          }}
                          variant="primary"
                        >
                          {t("Modules.Floor Lists.Add a new floor plan")}
                        </Button>:<></>}
                      </Col>
                    ) : (
                      <Col md={2} sm={6} xs={6}>
                        {allowAccess?<Button
                          // allowing to submit if lang is primary/base lang
                          disabled={
                            defaultSelectedLanguage !==
                            availableFilteredLanguages[0]?.language?.id
                          }
                          onClick={() => {
                            // validating file upload
                            validateFileUpload();
                            submitForm();
                          }}
                          variant="primary"
                        >
                           {t("Modules.Floor list (edit).Save button")}
                        </Button>:<></>}
                      </Col>
                    )}
                    <Col md={2} sm={6} xs={6}>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          history.push("/hotel-floor/list");
                        }}
                      >
                        {t("Common Translations.Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </BootstrapForm>
              </>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};
