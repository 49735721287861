import axios from 'axios';
//import { getToken } from './auth.service';
import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import { getTokens } from './auth.service';
const storageService = new StorageService();

const commonInstance = axios.create({
    baseURL: `${process.env.REACT_APP_ASSETS_API_URL}`,
});


// // attaching auth header
// commonInstance.interceptors.request.use(function (config) {
//     const authToken = getToken();
//     config.headers.Authorization = 'Bearer '.concat(authToken);
//     return config;
// });

commonInstance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers['Authorization'] = authToken.AccessToken;
  config.headers['Refresh-Token'] = authToken.RefreshToken;
  return config;
});

commonInstance.interceptors.response.use(
    (response) => {
      if(response.headers){
        if(response.headers.isaccesstokenrefreshed === '1'){
          storageService.setItem(STORAGE_KEYS.AccessToken, response.headers.accesstoken);
        }
      }
      return response
    },
    (error) => {
      throw error
    }
);
export default commonInstance;