import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import Breadcrumbs from "./../Common/Breadcrumbs";
import { Tabs } from "./Tabs";

const TabsLayout = ({ children }) => {
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const location = useLocation();
  const [isPathIncluded, setPath] = useState(null);
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const path = window.location.pathname;
  const isLoading = useSelector((state)=>state.network.isLoading)
  const isLoadingHotel = useSelector((state)=>state.hotelDetails.isLoading)
  const isLoadingRoom = useSelector((state)=>state.hotelRoomAdd.isLoading)
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-configuration", title: ( !isLoadingHotel )?( hotelName ? hotelName : ""):"" },
  ];
  const tempConfig = {
    "/hotel-configuration": "Basic Information",
    "/hotel-floor/list": "Floor List",
    "/hotel-room-categories": "Room Categories",
    "/hotel-room-pricing": "Room Pricing",
    "/hotel-room-list": "Room list",
    "/deals-and-offer": "Deals & Offers",
    "/hotel-translation": "Language settings & translations",
    "/email-settings": "Email Settings"
  };
  if (path && tempConfig[path]) {
    breadcrumbs.push({ path, title: tempConfig[path] });
  }
  useEffect(() => {
    if (hotelName === undefined && hotelId!=0) {
      dispatch(actions.getHotelBasicDetails(hotelId));
    }
    isIncluded();
  });

  const isIncluded = () => {
    const availablePathToShow = children.filter((childInfo) => {
      return location.pathname === childInfo.props.path;
    });
    setPath(availablePathToShow.length ? true : false);
  };

  return (
    <>
      {isPathIncluded && (
        <>
          <div className="breadcrumbs">
            <Row>
              <Col>
                <Breadcrumbs BreadcrumbData={breadcrumbs} />
              </Col>
            </Row>
            {(!isLoadingHotel) && <Row>
              <Col md={6} sm={6} xs={6}>
                <h3>{hotelName}</h3>
              </Col>
            </Row>}

            <Row>
              <Col md={6} sm={6} xs={6}>
                <div className="address-header">
                  {hotelDetails?.address},{hotelDetails?.address_city},&nbsp;
                  {hotelDetails?.zip_code}
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Tabs />
          </div>
          <main>{children}</main>
        </>
      )}
    </>
  );
};

export default TabsLayout;
