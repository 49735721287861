import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RES_CODE } from "../helper/constants";
import { removeToken } from "../services/auth.service";
import { ToastService } from "../services/toast.service";
import actions from "../store/actions/actions";

export const APIResponseHandling = (props) => {
  const toastService = new ToastService();
  const apiResponseListing = useSelector((state) => state.network.data);
  const dispatch = useDispatch();
  useEffect(() => {
    handleAPIResponse();
  }, [apiResponseListing]);

  // handling all responses
  const handleAPIResponse = () => {
    if (apiResponseListing && apiResponseListing?.message === "Network Error") {
      internalServerErr();
    }

    switch (+apiResponseListing?.status) {
      case +RES_CODE.UNAUTHORIZED: {
        if (
          apiResponseListing.headers.isrefreshtokenexpired === "1" ||
          apiResponseListing.headers.isrefreshtokenexpired === "undefined"
        ) {
          // toastService.onFailure(apiResponseListing?.data?.msg);
          removeToken();
          dispatch(actions.removeToken());
          break;
        } else {
          // toastService.onFailure(apiResponseListing?.data?.msg);
        }
      }
      case +RES_CODE.FORBIDDEN: {
        toastService.onFailure(apiResponseListing?.data?.msg);
        break;
      }
      case +RES_CODE.BAD_REQ: {
        toastService.onWarning(apiResponseListing?.data?.msg);
        break;
      }
      case +RES_CODE.INTERNAL_SERVER: {
        // original msg ===> apiResponseListing?.data?.msg
        internalServerErr();
        break;
      }
      case +RES_CODE.SUCCESS: {
        toastService.onSuccess(apiResponseListing?.data?.msg);
        break;
      }
      default: {
        break;
      }
    }
  };

  const internalServerErr = () => {
    toastService.onFailure("something went wrong, try again later");
  };

  return <></>;
};
