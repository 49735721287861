export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";




export const getRole = (hotelGroupId) => {
  return {
    type: GET_ROLE,
    hotelGroupId
  };
};

