import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  languages:false,
  isLanguages:false
};

export const LanguageSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_HOTEL_LANG_SETTINGS_LIST: {
      return {
        ...state,
        result: false,
        success: false,
      };
    }
    case actions.FETCH_HOTEL_LANG_SETTINGS_LIST_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        msg: action?.result?.msg,
        success: true,
      };
    }
    case actions.FETCH_HOTEL_LANG_SETTINGS_LIST_FAILED: {
      return {
        ...state,
        success: false,
      };
    }
    case actions.EDIT_HOTEL_LANG_SETTINGS: {
      return {
        ...state,
        success: false,
      };
    }
    case actions.EDIT_HOTEL_LANG_SETTINGS_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case actions.EDIT_HOTEL_LANG_SETTINGS_FAILED: {
      return {
        ...state,
        success: false,
      };
    }

    case actions.GET_LANGUAGE_SUCCESS: {
      state.languages = action?.result.data;
      state.isLanguages = true
      return {
        ...state,
        msg: action?.result?.msg,
        success: true,
      };
    }
    case actions.GET_LANGUAGE_FAILED: {
      state.isLanguages = false
      return {
        ...state,
        success: false,
      };
    }
    default:
      return state;
  }
};
