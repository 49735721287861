import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  isRoomAssigned: false,
  isUpdate: false
  
};

export const HotelFloorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_FLOOR_LIST: {
      return {
        ...state,
        result: false,
        success: false,
        isRoomAssigned: false,
        isUpdate: false
      };
    }
    case actions.FETCH_FLOOR_LIST_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        isRoomAssigned: false,
        msg: action?.result?.msg,
        success: true,
        isUpdate: false
      };
    }
    case actions.FETCH_FLOOR_LIST_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.FLOOR_NAME_LIST_SUCCESS: {
      state.floorNameList = action?.data?.data;
      return {
        ...state,
        msg: action?.result?.msg,
        success: true,
        isRoomAssigned: false,
        isUpdate: false
      };
    }
    case actions.FLOOR_NAME_LIST_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    //Todo
    case actions.EDIT_FLOOR_ASSIGNMENT_SUCCESS: {
      state.updatedFloor = action?.data?.data;
      return {
        ...state,
        msg: action?.result?.msg,
        success: true,
        isUpdate: true
      };
    }
    case actions.EDIT_FLOOR_ASSIGNMENT_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }

    case actions.ADD_NEW_FLOOR: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.ADD_NEW_FLOOR_SUCCESS: {
      return {
        ...state,
        msg: action?.result?.msg,
        success: true,
        isUpdate: false
      };
    }
    case actions.ADD_NEW_FLOOR_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.EDIT_FLOOR: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.EDIT_FLOOR_SUCCESS: {
      return {
        ...state,
        success: true,
        isUpdate: true
      };
    }
    case actions.EDIT_FLOOR_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.GET_FLOOR_DETAIL: {
      return {
        ...state,
        result: action?.data?.data,
        isRoomAssigned: false,
        msg: action?.result?.msg,
        success: false,
        isUpdate: false
      };
    }
    case actions.GET_FLOOR_DETAIL_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        isRoomAssigned: false,
        success: true,
        isUpdate: false
      };
    }
    case actions.GET_FLOOR_DETAIL_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false
      };
    }
    case actions.ADD_ASSIGN_ROOM_SUCCESS: {
      return {
        ...state,
        msg: action?.result?.msg,
        success: true,
        isRoomAssigned: true,
        isUpdate: false
      };
    }
    case actions.ADD_ASSIGN_ROOM_FAILED: {
      return {
        ...state,
        success: false,
        isRoomAssigned: false,
        isUpdate: false
      };
    }
    case actions.CLEAR_FLOOR_STATE: {
      return initialState

    }
    default:
      return state;
  }
};
