import axios from 'axios';
import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import { getTokens } from './auth.service';

const storageService = new StorageService();
const authInstance = axios.create({
    baseURL: `${process.env.REACT_APP_AUTH_URL}`,
});

// authInstance.interceptors.request.use(function (config) {
//     const authToken = getToken();
//     config.headers.Authorization = 'Bearer '.concat(authToken);
//     return config;
// });

//applying refresh token

authInstance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers['Authorization'] = authToken.AccessToken;
  config.headers['Refresh-Token'] = authToken.RefreshToken;
  return config;
});
authInstance.interceptors.response.use(
    (response) => {
      if(response.headers){
        if(response.headers.isaccesstokenrefreshed === '1'){
          storageService.setItem(STORAGE_KEYS.AccessToken, response.headers.accesstoken);
        }
      }
      return response
    },
    (error) => {
      throw error;
    }
);


export default authInstance;