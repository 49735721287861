import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import HotelConfigure from "../Common/icons/hotelconfigure.svg";
import { StorageService } from "./../../services/storage.service";
import Breadcrumbs from "./../Common/Breadcrumbs";
import PaginationComponent from './../Common/PaginationComponent';
import Spinner from "./../Common/Spinner";

const HotelManagement = (props) => {
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const [allowAccess, setAllowAccess] = useState(true);
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotel Management" },
  ];
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const id = +storageService.getItem("hotelGroupId");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const hotelByHotelGrouplist = useSelector(
    (state) => state.hotelmanagement.hotels
  );
  const total = useSelector(
    (state) => state.hotelmanagement?.totalCount
  );
    
  useEffect(()=>{
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchFloorList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
    dispatch(actions.setSearchUserList(""))
  },[])

  useEffect(() => {
    dispatch(actions.getHotelsFromHotelGroup(id, pageNo));
    setAllowAccess(true)
    
  }, []);

  useEffect(() => {
    setTotalCount(total)
  }, [hotelByHotelGrouplist]);

  const startHotelConfiguration = (hotelId) => {
    storageService.setItem("hotelId", hotelId);
    props.history.push(`/hotel-configuration`);
  };
  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    dispatch(actions.getHotelsFromHotelGroup(id, pageNo));
  }
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>Hotels</h3>
          </Col>
        </Row>
      </div>
      <div className="content mt-3">
        {!isLoading ? (<Row>
          {hotelByHotelGrouplist.map((value, id) => (
            <Col md={6} key={`hotel-list-${id}`}>
              <Card className="hotel-card" onClick={() => {
                if (value?.is_configured === 1) {
                  startHotelConfiguration(value?.id);
                }
              }}>
                <Card.Body>
                  {value?.picture? (
                     <Card.Img src={value?.picture} />
                  ) : (
                   
                    <Card.Img src={HotelConfigure} />
                  )}
                  <Card.Title>{value?.hotels_ts[0]?.name}</Card.Title>
                  <div>{value?.address},{value?.address_city},&nbsp;{value?.zip_code},&nbsp;{value?.country.name}</div>
                  {/* <Card.Text>With supporting text below as a natural lead-in to additional content.</Card.Text> */}
                  {value?.is_configured === 0 ? (
                    <>
                    {allowAccess?<Button
                      className="mt-2"
                      variant="primary"
                      onClick={() => {
                        startHotelConfiguration(value?.id);
                      }}
                    >
                      Start Configuration
                    </Button>:<></>}
                    </>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>) : (
          <Spinner />
        )}
        <Row>
          <Col>
            <PaginationComponent pageNo={pageNo} totalCount={Math.ceil(totalCount / 10)} onChange={(number) => { updatePagination(number) }} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default HotelManagement;
