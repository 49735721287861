import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../Common/Breadcrumbs";
import ConfirmWindow from "../Common/ConfirmWindow";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import RoomPricingForm from "./RoomSeasonPricingForm";
export const AddRoomPricing = () => {
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const hotelId = +storageService.getItem("hotelId");
  const defaultError = useSelector((state)=>state?.hotelSeasonPricing?.error)
  const [showModal, setShowModal] = useState(false)
  const roomCategoryList = useSelector(
    (state) => state.roomCategories.roomCategories
  );
  const [breadcrumbs, setBreadCrumbs] = useState();
  const { t } = useTranslation();

  useEffect(()=>{
    
    if(defaultError?.data?.is_default){
      setShowModal(true)
    }
  },[defaultError])

  useEffect(() => {
    dispatch(actions.getRoomCategories(hotelId));
  }, []);

  useEffect(()=>{
    return(()=>{
      dispatch(actions.resetRoomCategories())
    })
  },[])

  useEffect(() => {
    setBreadCrumbs([
      { path: "/hotel-management", title: "Hotels" },
      { path: "/hotel-configuration", title: hotelDetails?.name },
      { path: "/hotel-room-pricing", title: "Room pricing" },
      { path: "/hotel-room-pricing/create", title: " Add a new season" },
    ]);
  }, [hotelDetails]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            {breadcrumbs && <Breadcrumbs BreadcrumbData={breadcrumbs} />}
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.Room pricing (+ add new).Add new season")}</h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <RoomPricingForm
          isForEdit={false}
          roomCategoryList={roomCategoryList}
          hotelId={hotelId}
        />
      </div>
      <ConfirmWindow
        show={showModal}
        onHide={()=>setShowModal(false)}
        isoverride={()=>setShowModal(false)}
        isnotoverride={()=>setShowModal(false)}
        header={""}
        yesButtonLable={null}
        noButtonLable={"Close"}
        body={defaultError?.msg}
      ></ConfirmWindow>
    </React.Fragment>
  );
};
