import { combineReducers } from "redux";
import { authrReducer } from "./authr.reducer";
import { bookingReducer } from "./bookingReducer";
import { CommonReducer } from "./common.reducer";
import { constantsReducer } from "./constants.reducers";
import { HotelFloorReducer } from "./floor.reducers";
import { HotelDetailsReducer } from "./hotelDetails.reducers";
import { hotelmanagementReducer } from "./hotelmanagement.reducers";
import { HotelRoomReducer } from "./hotelRoom.reducers";
import { LanguageSettingsReducer } from "./LanguageSettings.reducers";
import { setLangReducer } from "./localLang.reducer";
import { loginReducer } from "./login.reducers";
import { networkReducer } from "./network.reducers";
import { OffersReducer } from "./offers.reducers";
import { HotelBookingReducer } from "./report.reducer";
import { RoomCategoriesReducer } from "./roomCategories.reducers";
import { HotelSeasonPricingReducer } from "./seasonPricing.reducers";
import { ServiceReducer } from "./service.reducers";
import { HotelTranslationReducer } from "./translation.reducers";
import { userManagementReducer } from "./userManagement.reducer";

export const rootReducer = combineReducers({
  constants: constantsReducer,
  hotelmanagement: hotelmanagementReducer,
  login: loginReducer,
  network: networkReducer,
  hotelDetails: HotelDetailsReducer,
  floor: HotelFloorReducer,
  roomCategories: RoomCategoriesReducer,
  hotelLanguageSettings: LanguageSettingsReducer,
  hotelRoomAdd: HotelRoomReducer,
  extraservices: ServiceReducer,
  spacialOffres: OffersReducer,
  hotelSeasonPricing: HotelSeasonPricingReducer,
  translationTab: HotelTranslationReducer,
  user:userManagementReducer,
  bookingReports: HotelBookingReducer,
  authr:authrReducer,
  localLang: setLangReducer,
  common:CommonReducer,
  booking:bookingReducer
});
