import React, { useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LANGUAGE } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { Utils } from "./../../utils/utils";

export const Languages = ({
  onChange,
  extraClasses = "",
  valueAsKey = false,
  defaultValue = null,
}) => {
  const { i18n } = useTranslation();
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const dispatch = useDispatch()
  const defaultSelected = defaultValue ? defaultValue : availableFilteredLanguages[0]?.languages?.id;
  useEffect(() => {
    // setting up the default language for static content
    i18n.changeLanguage(LANGUAGE.english);
  }, []);

  useEffect(() => {
    // defaultSelected = defaultValue;
  }, [defaultValue]);
  const onValueChange = (languageId) => {
    const selectedLanguage = availableFilteredLanguages.find((languageInfo) => {
      return +languageInfo?.language?.id === +languageId;
    });
    onChange(selectedLanguage);
    dispatch(actions.setLangAction(+languageId))
  };

  return availableFilteredLanguages?.length ? (
    <Form.Group>
      <Dropdown className ="webkit-auto form-control"
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        // className={extraClasses}
        as="select"
        defaultValue={defaultValue}
        required
      >
        {availableFilteredLanguages?.map((languageInfo, index) => {
          return (
            <option
              key={index}
              value={+languageInfo?.language?.id}
            >
              {valueAsKey
                ? languageInfo?.language?.key?.toUpperCase()
                : languageInfo?.language?.code}
            </option>
          );
        })}
      </Dropdown>
    </Form.Group>
  ) : (
    <></>
  );
};
