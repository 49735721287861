import { getToken as getTokenFunction } from "./../../services/auth.service";
export const LOGIN = "LOGIN";
export const LOGIN_SUCESS = "LOGIN_SUCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAILED = "FETCH_TOKEN_FAILED";

export const FETCH_SESSION_TOKEN_SUCCESS = "FETCH_SESSION_TOKEN_SUCCESS";
export const FETCH_SESSION_TOKEN_FAILED = "FETCH_SESSION_TOKEN_FAILED";

export const SET_TOKEN = "SET_TOKEN";
export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";
export const GET_TOKEN = "GET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCESS = "LOGOUT_SUCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCESS = "FORGOT_PASSWORD_SUCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCESS = "RESET_PASSWORD_SUCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const CONFIRM_PASSWORD_SUCESS = "CONFIRM_PASSWORD_SUCESS";
export const CONFIRM_PASSWORD_FAILED = "CONFIRM_PASSWORD_FAILED";

export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_SUCESS = "SET_PASSWORD_SUCESS";
export const SET_PASSWORD_SUCESS_FAILED = "SET_PASSWORD_SUCESS_FAILED";

export const SET_EMAIL = "SET_EMAIL";
export const SET_EMAIL_FAILED = "SET_EMAIL_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCESS = "CHANGE_PASSWORD_SUCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const DEACTIVATE_USER_SUCESS = "DEACTIVATE_USER_SUCESS";
export const DEACTIVATE_USER_FAILED = "DEACTIVATE_USER_FAILED";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCESS = "ACTIVATE_USER_SUCESS";
export const ACTIVATE_USER_FAILED = "ACTIVATE_USER_FAILED";

export const CLEAR_TOKEN = "CLEAR_TOKEN";

export const login = (credential) => {
  return {
    type: LOGIN,
    credential,
  };
};

export const logOut = (accessToken) => {
  return {
    type: LOGOUT,
    accessToken,
  };
};

export const forgotPassword = (emailId) => {
  return {
    type: FORGOT_PASSWORD,
    emailId,
  };
};

export const confirmPassword = (payload) => {
  return {
    type: CONFIRM_PASSWORD,
    payload,
  };
};

export const setToken = (token = getTokenFunction()) => {
  return {
    type: SET_TOKEN,
    token,
  };
};

export const setSessionToken = (token = getTokenFunction()) => {
  return {
    type: SET_SESSION_TOKEN,
    token,
  };
};

export const getToken = () => {
  return {
    type: GET_TOKEN,
  };
};

export const removeToken = () => {
  return {
    type: FETCH_TOKEN_FAILED,
  };
};

export const resetPassword = (emailId) => {
  return {
    type: RESET_PASSWORD,
    emailId,
  };
};

export const setPassword = (payload) => {
  return {
    type: SET_PASSWORD,
    payload,
  };
};

export const changePassword = (payload) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
  };
};

export const deactivateUser = (payload) => {
  return {
    type: DEACTIVATE_USER,
    payload,
  };
};

export const activateUser = (payload) => {
  return {
    type: ACTIVATE_USER,
    payload,
  };
};

export const clearToken = () => {
  return {
    type: CLEAR_TOKEN,
  };
};