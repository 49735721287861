import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../Common/icons/close.svg";

const SearchComponents = (props) => {
  const [searchValue, setSearchValue] = useState( props?.commonSearchTerm ||"");
  const [isEnterPressed, setEnterPressed] = useState(false);

  const setSearch = (filterValue = searchValue) => {
    props.search(filterValue);
  };

  return (
      <Row>
        <Col md={5} sm={8} xs={12}>
          <Form.Group>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue}
                onKeyUp={(event) => {
                  if (+event?.keyCode === 13) {
                    event.preventDefault();
                    setEnterPressed(true);
                    setSearch();
                  }
                }}
                onChange={(event) => {
                  event.preventDefault();
                  setSearchValue(event?.target?.value);
                  if (!event?.target?.value && isEnterPressed) {
                    setEnterPressed(false);
                    setSearch("");
                  }
                }}
              ></Form.Control>
              {searchValue && (
                <InputGroup.Text
                  className="pointer inputGroupClose"
                  onClick={() => {
                    if (isEnterPressed) {
                      setEnterPressed(false);
                      setSearchValue("");
                      setSearch("");
                    } else {
                      setSearchValue("");
                      setSearch("");
                    }
                  }}
                >
                  <CloseIcon />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={3} />
        <Col md={4} className="alignRight">
          <Form.Group>
            {props.addBtn ? (
              <Link to={props.path}>
                <Button>{props.buttonLabel}</Button>
              </Link>
            ) : (
              ""
            )}
          </Form.Group>
        </Col>
      </Row>
  );
};
export default SearchComponents;
