import React from "react";
import { Col, Form as bootstrapForm, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import roomHighlight from "../Common/overlayImages/roomHighlight.jpg";
export const HighLightRoom = (props)=>{
    const {t} = useTranslation()
    return (
        <React.Fragment>
            
            <div className="header">{t("Modules.Rooms List.Highlight the room")}</div>
            &nbsp;
            <div className="sub-header-text">
                {t("Modules.Rooms List.Highlight the room Description")}
                <OverlayTrigger
                key="right"
                placement="bottom"
              
                overlay={
                  <Tooltip id="tooltip-right">
                    <Row>
                      <Col md={3} >
                        {t("Modules.Rooms List.Highlight the room - Question mark")}
                      </Col>
                      <Col>
                          <img src={roomHighlight} className={'tooltip-image'} alt={'greeting-overview'} ></img>
                      </Col>
                    </Row>
                    
                  </Tooltip>
                }
              >
                <QuestionIcon  className="pointer" />
              </OverlayTrigger>
          
         
            </div>
            <br></br>
            <Row>
                <Col md ={6}>
                    <bootstrapForm.Check
                        className="sub-header-text"
                        type="checkbox"
                        label={t("Modules.Rooms List.Hotel Manager choice")}
                        name="is_manager_choice"
                        checked = {props.values?.is_manager_choice}
                        onChange = {(event)=>{props.setFieldValue("is_manager_choice", event.target.checked)}}
                    >

                    </bootstrapForm.Check>
                    
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default HighLightRoom
