import { Col, Modal, Row } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
const ModalCopmponents = (props) => {
  return (
    <Modal
      {...props}
      size={props?.size ? props?.size : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={props?.className}
    >
      <div className="mt-3">
        <Row>
          <Col md={2}></Col>
          <Col md={8} className="alignCenter">
            {" "}
            <h5> {props.header} </h5>{" "}
          </Col>
          <Col md={2} className="pl-3">
            <span onClick={props.onHide} className="pointer">
              <CloseIcon />
            </span>
          </Col>
        </Row>
      </div>
      <Modal.Body>{props.body}</Modal.Body>
    </Modal>
  );
};
export default ModalCopmponents;
