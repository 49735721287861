import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { generateToolTip } from "../../helper/constants";
import { ReactComponent as SortUpSVG } from "./icons/sorting_up_arrow.svg";
const BootstrapTable = ({
  tableMapper = [],
  sortColumns = [],
  currentlySorted = [],
  onSortClick,
  tableExtraActions = [],
  sortingColumnName = "",
  headerDescription = {},
  component = "",
  isLoading = false,
}) => {
  const removeHeaders = ["id"];
  const { t } = useTranslation();

  const setupSortingHeaders = (headerInfo, genToolTip) => {
    return (
      <>
        <span
          onClick={(event) => {
            onSortClick(event, headerInfo);
          }}
          className="pointer noWrap"
        >
          {headerInfo}
          
        </span>
        <span className="pointer noWrap">&nbsp;{genToolTip}</span>
        {headerInfo === sortingColumnName && (
          <span
            className="pointer noWrap"
            onClick={() => {
              onSortClick({ target: { innerText: headerInfo } }, headerInfo);
            }}
          >
            {currentlySorted !== "" &&
              (currentlySorted[headerInfo] === true ? (
                <span className="p-2">
                  <SortUpSVG className="transition-arrow" />
                </span>
              ) : (
                <span className="p-2">
                  <SortUpSVG className="transform-arrow transition-arrow" />
                </span>
              ))}
          </span>
        )}
      </>
    );
  };

  const setupHeaders = (headerInfo) => {
    let genToolTip = generateToolTip(
      headerInfo in headerDescription ? `${headerDescription[headerInfo]}` : ""
    );

    return sortColumns.includes(headerInfo) ? (
      <> {setupSortingHeaders(headerInfo, genToolTip)} </>
    ) : (
      <span>
        {headerInfo}&nbsp;{genToolTip}
      </span>
    );
  };

  const tableBodyContent = () => {
    return (
      <>
        {tableMapper.map((bodyContent, index) => (
          <tr key={`table-bodies-${index}`}>
            {Object.keys(bodyContent).map(
              (bodyDetail, colIndex) =>
                !removeHeaders.includes(bodyDetail) && (
                  <td key={`table-body-${colIndex}`} className="table-wrap">
                    {bodyContent[bodyDetail]}
                  </td>
                )
            )}
            {tableExtraActions.map((action, index) => (
              <td
                onClick={() => {
                  action.onClick(bodyContent?.id);
                }}
                key={`table-actions-${index}`}
              >
                {action?.icon}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  const loadTable = () => (
    <>
      <Table responsive striped borderless>
        <thead>
          <tr>
            {Object.keys(tableMapper[0]).map(
              (headerInfo, index) =>
                !removeHeaders.includes(headerInfo) && (
                  <th key={`table-header-${index}`}>
                    {setupHeaders(headerInfo)}
                  </th>
                )
            )}
            <th>
              {tableExtraActions && tableExtraActions.length ? "ACTIONS" : ""}
            </th>
          </tr>
        </thead>
        {/* TODO: add spinner */}
        {/* <tbody>{isLoading ? <Spinner /> : tableBodyContent()}</tbody> */}
        <tbody>{tableBodyContent()}</tbody>
      </Table>
    </>
  );

  const noDataAvailable = () => (
    <>
      <div className="container content">
        {" "}
        {!isLoading && "No Records to show"}
      </div>
    </>
  );

  return tableMapper && tableMapper.length ? loadTable() : noDataAvailable();
};

export default BootstrapTable;
