import actions from "./../actions/actions"

const initialState = {
    // current page no
    page: 1,
    //pageNoForOffer
    limit: 10,
    success: false,
    isLoading: false,
    created: false,
    updated: false,
    sortBy: "ASC",
    sortColumnName: false,
    // for showing up and down arrow
    roomColumnName: "ROOM NAME",
    roomSortColumnName: false,
    roomSortBy: "ASC",
    sortForRoom:{"ROOM NAME": false},
    roomListPageNo:1,
    roomSearch:"",

    floorColumnName: "FLOOR",
    floorSortColumnName: false,
    floorSortBy: "ASC",
    sortForFloor:{"FLOOR": false},
    floorListPageNo:1,
    floorSearch:"",

    seasonPriceColumnName:"SEASON NAME",
    seasonPriceSortColumnName: false,
    seasonPriceSortBy: "ASC",
    sortForSeasonPricing:{"SEASON NAME": false},
    seasonPricingListPageNo:1,
    seasonSearch:"",

    dealColumnName:"DEAL NAME",
    dealSortColumnName: false,
    dealSortBy: "ASC",
    sortForDeals:{"DEAL NAME": false},
    dealsListPageNo:1,
    dealSearch:"",

    offerColumnName:"OFFER NAME",
    offerSortBy: "ASC",
    offerSortColumnName: false,
    sortForOffer:{"OFFER NAME":false},
    offerPageNo:1,
    offerSearch:"",

    userColumnName:"USERNAME",
    userSortColumnName: false,
    userSortBy: "ASC",
    sortForUser:{"USERNAME":false},
    userListPageNo:1,
    userSearch:"",


    // its to maintain value for Desc and ASC.
    //false is ASC and DESC is true
    sort: { "ROOM NAME": false,"FLOOR": false,"SEASON NAME": false,"DEAL NAME": false,"USERNAME":false},
    
}

export const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        //To Save Page no in reducer
        case actions.SET_PAGE_NO: {
            return {
                ...state,
                page: action?.page,
            }
        }
        //Set sort and sortBy value in state
        case actions.SET_SORT_BY: {
            return {
                ...state,
                roomColumnName: action.roomSortColumnName,
                floorColumnName: action.sortColumnName,
                seasonPriceColumnName:action.sortColumnName,
                dealColumnName:action.sortColumnName,
                sortColumnName: action.sortName,
                sortBy: action.sortBy,
                sort: {
                    ...state.sort, [action.sortColumnName]: action.sort
                }
            }
        }
        //Set sort and sortBy value for offer in state
        case actions.SET_OFFER_SORT: {
            return {
                ...state,
                offerColumnName: action.sortColumnName,
                offerSortColumnName: action.sortName,
                offerSortBy: action.sortBy,
                sortForOffer: {
                    ...state.sortForOffer, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_ROOM_LIST_SORT: {
            return {
                ...state,
                roomColumnName: action.roomSortColumnName,
                roomSortColumnName: action.sortName,
                roomSortBy: action.sortBy,
                sortForRoom: {
                    ...state.sortForRoom, [action.roomSortColumnName]: action.sort
                }
            }
        }
        case actions.SET_ROOM_LIST_PAGE_NO: {
            return {
                ...state,
                roomListPageNo: action?.page,
            }
        }
        case actions.SET_FLOOR_SORT: {
            return {
                ...state,
                floorColumnName: action.sortColumnName,
                floorSortColumnName: action.sortName,
                floorSortBy: action.sortBy,
                sortForFloor: {
                    ...state.sortForFloor, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_FLOOR_LIST_PAGE_NO: {
            return {
                ...state,
                floorListPageNo: action?.page,
            }
        }
        case actions.SET_SEASON_PRICE_SORT: {
            return {
                ...state,
                seasonPriceColumnName: action.sortColumnName,
                seasonPriceSortColumnName: action.sortName,
                seasonPriceSortBy: action.sortBy,
                sortForSeasonPricing: {
                    ...state.sortForSeasonPricing, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_SEASON_PRICE_LIST_PAGE_NO: {
            return {
                ...state,
                seasonPricingListPageNo: action?.page,
            }
        }
        case actions.SET_DEALS_SORT: {
            return {
                ...state,
                dealColumnName: action.sortColumnName,
                dealSortColumnName: action.sortName,
                dealSortBy: action.sortBy,
                sortForDeals: {
                    ...state.sortForDeals, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_DEALS_LIST_PAGE_NO: {
            return {
                ...state,
                dealsListPageNo: action?.page,
            }
        }

        case actions.SET_USER_SORT: {
            return {
                ...state,
                userColumnName: action.sortColumnName,
                userSortColumnName: action.sortName,
                userSortBy: action.sortBy,
                sortForUser: {
                    ...state.sortForUser, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_USER_LIST_PAGE_NO: {
            return {
                ...state,
                userListPageNo: action?.page,
            }
        }
        case actions.SET_OFFER_PAGE_NO: {
            return {
                ...state,
                offerPageNo: action?.page,
            }
        }

        case actions.RESET_SORT_BY: {
            return initialState
        }

        case actions.SET_SEARCH_HOTEL_ROOM_LIST:{
            return{
                ...state,
                roomSearch: action?.payload
            }
        }

        case actions.SET_SEARCH_SEASON:{
            return{
                ...state,
                seasonSearch: action?.payload ||""
            }
        }

        case actions.SET_SEARCH_FLOOR_LIST:{
            return{
                ...state,
                floorSearch: action?.payload ||""
            }
        }

        case actions.SET_SEARCH_DEAL_LIST:{
            return{
                ...state,
                dealSearch: action?.payload ||""
            }
        }

        case actions.SET_SEARCH_OFFER_LIST:{
            return{
                ...state,
                offerSearch: action?.payload ||""
            }
        }

        case actions.SET_SEARCH_USER_LIST:{
            return{
                ...state,
                userSearch: action?.payload ||""
            }
        }

        default: {
            return state

        }
    }
}