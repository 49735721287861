import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const FloorRoomToolTip = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.name}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(props)}
    >
      {props.children}
    </OverlayTrigger>
  );
};
