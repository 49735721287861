
import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../actions/actions";
import ExtraService from "./../../services/ExtraService/index";
const extraServe = new ExtraService();

const getBookingStatus =  async (payload) =>{
    try{
        const response = await extraServe.getBookingStatus(payload)
        return response
    }
    catch (err) {
        throw err;
      }
}

function* dispatchBookingStatus(action) {
    try {
        const response = yield call(getBookingStatus,action.payload)
        if (response.data.success) {
            yield put({
                type: actions.API_SUCCESS,
                data: response,
              });
            yield put({
                type: actions.GET_TEST_BOOKING_SUCCESS,
                result: response.data.data.records,
            })
        } else {
            yield put({
                type: actions.GET_TEST_BOOKING_FAILED,
                msg: response?.data?.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_TEST_BOOKING_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.GET_TEST_BOOKING_FAILED,
            data: error?.response
          })
    }
}


export function* listengetBookingStatus() {
    yield takeLatest(actions.GET_TEST_BOOKING, dispatchBookingStatus)
  }