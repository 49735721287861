import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../actions/actions";
import HotelService from "./../../services/Hotel/index";

const hotelService = new HotelService();
const getHotelDetails = async (hotelId) => {
  try {
    const response = await hotelService.getHotelBasicDetails(hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

const getSearchedHotelDetails = async (hotelId, searchKey,defaultSelectedLanguage) => {
  try {
    const response = await hotelService.getSearchedTags(hotelId, searchKey,defaultSelectedLanguage);

    return response;
  } catch (err) {
    throw err;
  }
};

const removeHotelTag = async (payload) => {
  try {
    const response = await hotelService.removeHotelTag(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateHotelDetail = async (payload, hotelId) => {
  try {
    const response = await hotelService.updateHotelDetail(payload, hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

const addHotelTags = async (payload) => {
  try {
    const response = await hotelService.addHotelTag(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getHotelConfiguration = async (id) => {
  try {
  const response = await hotelService.getHotelConfigurationDetails(id)
  return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchHotelDetails(action) {
  try {
    const response = yield call(getHotelDetails, action.id);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTELS_BASIC_DETAILS_SUCCESS,
        msg: response.data.msg,
        result: response.data,
      });
      yield put({
        type: actions.FETCH_HOTEL_TAGS_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data?.tags,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_BASIC_DETAILS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetHotelTags(action) {
  try {
    // calling same API to get update tags only
    const response = yield call(getHotelDetails, action.id);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTEL_TAGS_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data?.tags,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTEL_TAGS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchGetSearchedHotelTags(action) {
  try {
    // calling same API to get update tags only
    const response = yield call(
      getSearchedHotelDetails,
      action.id,
      action.searchKey,
      action.defaultSelectedLanguage
    );
    if (response.data.success) {
      yield put({
        type: actions.FETCH_SEARCHED_HOTEL_TAGS_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.FETCH_SEARCHED_HOTEL_TAGS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchRemoveHotelTags(action) {
  try {
    const response = yield call(removeHotelTag, action?.payload);
    if (response.data.success) {
      yield put({
        type: actions.REMOVE_HOTEL_TAGS_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      // getting hotel details for tag
      yield call(dispatchGetHotelTags, { id: action.payload.hotel_id });
    } else {
      yield put({
        type: actions.REMOVE_HOTEL_TAGS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchUpdateHotelDetails(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    yield put({ type: actions.INIT_UPDATE_HOTEL_DETAILS });
    const response = yield call(
      updateHotelDetail,
      action?.payload,
      action?.hotelId
    );
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTELS_BASIC_DETAILS_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.FETCH_HOTELS_CONFIGURATION,
        id: action?.hotelId
      })
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      // getting hotel details
      yield call(dispatchHotelDetails, { id: action?.hotelId });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_BASIC_DETAILS_FAILED,
        msg: response.data.msg,
      });
      yield put({ type: actions.API_WARNING, msg: response.data });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
    yield put({
      type: actions.UPDATE_HOTEL_DETAILS_FAILED,
    });
  }
}

function* dispatchAddHotelTags(action) {
  try {
    const response = yield call(addHotelTags, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.ADD_HOTEL_TAGS_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
      // getting hotel details for tag
      yield call(dispatchGetHotelTags, { id: action.payload.hotel_id });
    } else {
      yield put({
        type: actions.API_WARNING,
        msg: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchHotelConfiguration(action) {
  try {
    const response = yield call(getHotelConfiguration, action.id);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTELS_CONFIGURATION_SUCCESS,
        result:response.data.data
      });
      yield put({
        type: actions.API_SUCCESS,
        // data: response,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_CONFIGURATION_FAILED,
        msg: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_HOTELS_CONFIGURATION_FAILED,
      data: error || error?.response,
    });
  }
}

// getting hotel basic details
export function* fetchHotelDetail() {
  yield takeLatest(actions.FETCH_HOTELS_BASIC_DETAILS, dispatchHotelDetails);
}

// getting hotel configuration details
export function* fetchHotelConfiguration() {
  yield takeLatest(actions.FETCH_HOTELS_CONFIGURATION, dispatchHotelConfiguration);
}


// getting tags by hotel id
export function* fetchHotelTags() {
  yield takeLatest(actions.FETCH_HOTEL_TAGS, dispatchGetHotelTags);
}

// adding hotel tags
export function* fetchAddHotelTags() {
  yield takeLatest(actions.ADD_HOTEL_TAGS, dispatchAddHotelTags);
}

// remove hotel tags
export function* fetchRemoveHotelTag() {
  yield takeLatest(actions.REMOVE_HOTEL_TAGS, dispatchRemoveHotelTags);
}

// update hotel details
export function* fetchUpdateHotelDetails() {
  yield takeLatest(actions.UPDATE_HOTEL_DETAILS, dispatchUpdateHotelDetails);
}

export function* fetchSearchedHotelTags() {
  yield takeLatest(
    actions.FETCH_SEARCHED_HOTEL_TAGS,
    dispatchGetSearchedHotelTags
  );
}
