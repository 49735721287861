import actions from "./../actions/actions";
import { takeLatest, call, put } from "redux-saga/effects";
import TranslationService from "./../../services/Hotel/translation.service";

const translationService = new TranslationService();
const getTranslationListing = async (payload) => {
  try {
    const response = await translationService.getTranslationForTab(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const saveTranslation = async (payload, hotelId) => {
  try {
    const response = await translationService.saveTranslation(payload, hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchSaveTranslation(action) {
  try {
    const { hotelId, filter, payload, page, limit } = action;
    yield put({ type: actions.API_FETCHING });
    const response = yield call(saveTranslation, payload, hotelId);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.SAVE_TRANSLATION_SUCCESS,
        msg: response.data.msg,
      });
      // fetching latest records after saving
      const filteredPayload = {
        hotelId,
        filter,
      };
      if (page && limit) {
        filteredPayload.page = page;
        filteredPayload.limit = limit;
      }
      yield call(dispatchTranslationTabList, {
        payload: filteredPayload,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.SAVE_TRANSLATION_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SAVE_TRANSLATION_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchTranslationTabList(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getTranslationListing, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_TRANSLATION_TAB_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_TRANSLATION_TAB_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_TRANSLATION_TAB_LIST_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

export function* fetchTranslationTabs() {
  yield takeLatest(
    actions.FETCH_TRANSLATION_TAB_LIST,
    dispatchTranslationTabList
  );
}

export function* fetchSaveTranslation() {
  yield takeLatest(actions.SAVE_TRANSLATION, dispatchSaveTranslation);
}
