export const GET_HOTEL_BOOKING = "GET_HOTEL_BOOKING";
export const GET_HOTEL_BOOKING_SUCCESS = "GET_HOTEL_BOOKING_SUCCESS";
export const GET_HOTEL_BOOKING_FAILED = "GET_HOTEL_BOOKING_FAILED";

export const GET_EXPORT_FILE = "GET_EXPORT_FILE"
export const GET_EXPORT_FILE_SUCCESS = "GET_EXPORT_FILE_SUCCESS"
export const GET_EXPORT_FILE_FAILED = "GET_EXPORT_FILE_FAILED"

export const RESET_REPORT_TAB = "RESET_REPORT_TAB"


export const getHotelBooking = (
  pageNo,
  limit,
  hotelId = null,
  searchKey = "",
  sortBy = "",
  sort = "",
  languageId = null,
  startDate = null,
  endDate = null,
  booking_date = null
) => {
  return {
    type: GET_HOTEL_BOOKING,
    pageNo,
    limit,
    hotelId,
    searchKey,
    sortBy,
    sort,
    languageId,
    startDate,
    endDate,
    booking_date
  };
};

export const getExportedFile = (
  pageNo,
  limit,
  hotelId = null,
  searchKey = "",
  sortBy = "",
  sort = "",
  languageId = null,
  startDate = null,
  endDate = null,
  booking_date = null
) => {
  return {
    type: GET_EXPORT_FILE,
    pageNo,
    limit,
    hotelId,
    searchKey,
    sortBy,
    sort,
    languageId,
    startDate,
    endDate,
    booking_date
  };
};

export const resetReportTab=()=>{
  return{
    type: RESET_REPORT_TAB
  }
}