import { call, put, takeLatest } from "redux-saga/effects";
import SeasonPricingService from "../../services/Hotel/seasonPricing.service";
import actions from "../actions/actions";

const seasonPricingService = new SeasonPricingService();
const columnMapForReducer = {
  "name":"SEASON NAME",
  "start_date":"START DATE",
  "end_date":"END DATE",
  "active":"STATUS"
}
const getHotelSeasonPricingList = async (hotelId) => {
  try {
    const response = await seasonPricingService.getSeasonPricing(hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

const editHotelSeasonPricing = async (payload) => {
  try {
    const response = await seasonPricingService.editHotelSeason(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const CreateHotelSeasonPricing = async (payload) => {
  try {
    const response = await seasonPricingService.createHotelSeason(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getHotelSeasonPricingDetail = async (seasonId) => {
  try {
    const response = await seasonPricingService.getSeasonDetail(seasonId);
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchEditHotelSeasonPricing(action) {
  try {
    
    yield put({ type: actions.API_FETCHING });
    const response = yield call(editHotelSeasonPricing, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.EDIT_HOTEL_SEASON_PRICING_SUCCESS });
      
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.EDIT_HOTEL_SEASON_PRICING_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.EDIT_HOTEL_SEASON_PRICING_FAILED,data: error?.response?.data, msg: error?.message });
    if (Array.isArray(error?.response?.data?.data)){
      
      if(!error?.response?.data?.data?.[0]?.is_default){
        yield put({
          type: actions.API_FAILED,
          data: error?.response || error,
        });
      }else{
        
        yield put({ type: actions.API_SUCCESS});
      }
    }else if(!Array.isArray(error?.response?.data?.data)){

      if(!error?.response?.data?.data?.is_default ){
        
        yield put({
          type: actions.API_FAILED,
          data: error?.response || error,
        });
      }else{
       
        yield put({ type: actions.API_SUCCESS});
      }
    }else{
     
      yield put({ type: actions.API_SUCCESS});
    }
  }
}

function* dispatchCreateHotelSeasonPricing(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(CreateHotelSeasonPricing, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.CREATE_HOTEL_SEASON_PRICING_SUCCESS });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.CREATE_HOTEL_SEASON_PRICING_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.CREATE_HOTEL_SEASON_PRICING_FAILED,
      msg: error?.message,
      data: error?.response?.data
    });
    
    if (!error?.response?.data?.data?.is_default){
      yield put({
        type: actions.API_FAILED,
        data: error?.response || error,
      });
    }else{
      yield put({ type: actions.API_SUCCESS});
    }
  }
}

function* dispatchGetHotelSeasonPricingList(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getHotelSeasonPricingList, action.payload);
    if (response?.data?.success) {
      yield put({
        type: actions.FETCH_HOTEL_SEASON_PRICING_LIST_SUCCESS,
        data: response.data,
      });
      yield put({
        type: actions.SET_SEASON_PRICE_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_SEASON_PRICE_SORT,
        sortBy: action.payload.sortBy?action.payload.sortBy:"ASC",
        sortName: action.payload.sort,
        sortColumnName: columnMapForReducer[action.payload.sort] ? columnMapForReducer[action.payload.sort] : "SEASON NAME",
        sort: action.payload.sortBy === "DESC" ? true : false
      });
      yield put({ type: actions.API_SUCCESS });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_HOTEL_SEASON_PRICING_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_HOTEL_SEASON_PRICING_LIST_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchGetHotelSeasonPricingDetail(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getHotelSeasonPricingDetail, action.seasonId);
    if (response?.data?.success) {
      yield put({
        type: actions.FETCH_HOTEL_SEASON_PRICING_DETAIL_SUCCESS,
        data: response.data,
      });
      yield put({ type: actions.API_SUCCESS });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_HOTEL_SEASON_PRICING_DETAIL_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_HOTEL_SEASON_PRICING_DETAIL_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

export function* fetchHotelSeasonPricing() {
  yield takeLatest(
    actions.FETCH_HOTEL_SEASON_PRICING_LIST,
    dispatchGetHotelSeasonPricingList
  );
}

export function* fetchUpdateHotelSeasonPricing() {
  yield takeLatest(
    actions.EDIT_HOTEL_SEASON_PRICING,
    dispatchEditHotelSeasonPricing
  );
}

export function* fetchCreateHotelSeasonPricing() {
  yield takeLatest(
    actions.CREATE_HOTEL_SEASON_PRICING,
    dispatchCreateHotelSeasonPricing
  );
}

export function* fetchHotelSeasonPricingDetail() {
  yield takeLatest(
    actions.FETCH_HOTEL_SEASON_PRICING_DETAIL,
    dispatchGetHotelSeasonPricingDetail
  );
}
