import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BookingIcon from "../components/Common/DashboardIcon/Booking.svg";
import { ReactComponent as DashboardSVG } from "../components/Common/DashboardIcon/Dashboard.svg";
import HotelIcon from "../components/Common/DashboardIcon/Hotels.svg";
import UserIcon from "../components/Common/DashboardIcon/Users.svg";
import { PRIVATE_ROUTES } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import { Utils } from "../utils/utils";
import actions from "./../store/actions/actions";

const storageService = new StorageService();

const Dashboard = () => {
  const [firstName, setFirstName] = useState(false);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(actions.setSearchHotelRoomList(""))
    dispatch(actions.setSearchFloorList(""))
    dispatch(actions.setSearchSeason(""))
    dispatch(actions.setSearchDealList(""))
    dispatch(actions.setSearchOfferList(""))
    dispatch(actions.setSearchUserList(""))
  },[])

  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  }

  const dashboard = useSelector((state)=>state?.login?.result?.dashboardLinks)

  const urlReturn = ()=>{
    if(dashboard=='undefined'||dashboard==undefined||dashboard==null||dashboard?.length<1){
      return ""
    }
    else{
      return (JSON.parse(dashboard)?.[0]?.dashboard_link)
    }
  }
  useEffect(() => {
    if (!firstName) setFirstName(storageService.getItem("user-first-name"));
  }, []);

  const redirectToDashboard = ()=>{
    if(storageService.getItem("AccessToken")){
      if(dashboard){
        const dashboard_url = urlReturn()
        if(dashboard_url.length>1){
          window.open(dashboard_url);
        }
      }
    }
    else{
      dispatch(actions.clearToken());
    }
  }

  return (
    <React.Fragment>
      {/* <div className="background-setup"/> */}
      <Row className="dashboard-header">
        <Col md={12}>
          <div>
            <div className="welcome_text">Hello {firstName}</div>
            <div className="alignCenterWK">
              {" "}
              Choose a category to start working{" "}
            </div>
          </div>
        </Col>
        <Col md={12}>
          <Row className="alignCenter">
            <Col className="mt-4">
              <Row className="alignCenterWK">
                <Col className="mb-2 noPadding">
                  <Link to="/#">
                    <Card className="dashboard-card">
                      <DashboardSVG className="card-img-top" onClick={redirectToDashboard}/>
                    </Card>
                  </Link>
                </Col>
                {hiddenIconAsPerRole(PRIVATE_ROUTES.Home) ? ( <Col className="mb-2 noPadding">
                  <Link to={PRIVATE_ROUTES.Home}>
                    <Card className="dashboard-card">
                      <Card.Img variant="top" src={HotelIcon} />
                    </Card>
                  </Link>
                </Col>) : (<></>)}

                {hiddenIconAsPerRole(PRIVATE_ROUTES.user_list) ? (<Col className="mb-2 noPadding">
                  <Link to={PRIVATE_ROUTES.user_list}>
                    <Card className="dashboard-card">
                      <Card.Img variant="top" src={UserIcon} />
                    </Card>
                  </Link>
                </Col>) : (<></>)}
                
                <Col className="mb-2 noPadding">
                  <Link to="/bookings">
                    <Card className="dashboard-card">
                      <Card.Img variant="top" src={BookingIcon} />
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
